<template>
    <main>
        <div class="fullscreen-page-cont">
            <div class="fullscreen-cont">
                <div class="page-cont-box">
                    <img class="payment-msg-banner banner-success img-fluid" src="images/payment-failed-img.png"
                        alt="Payment Failed">
                    <h1 class="page-title">Your Payment is Failed</h1>
                    <p class="page-txt">Thank you for your payment. An automated payment receipt<br> will be sent to
                        your registered email.</p>
                    <router-link class="admin-common-btn btn-back-to-home" to="/">Back To Home</router-link>
                </div>
            </div>
        </div>
    </main>
</template>