<template>
    <Loader :active="loading" message="Please wait..." />
    <div class="common-inner-body backlink-page-cont">
        <div class="common-inner-cont">
            <div class="search-page-top">
                <h2 class="top-title">Analyze Backlinks</h2>
                <h2 class="top-subtitle">
                    Enter your domain below to see the number of
                    <strong>backlinks, referring domains, and more</strong>
                </h2>
                <div class="top-form-cont" :class="{ 'disabled-section': true }">

                    <p v-if="true" class="maintenance-message">This section is under maintenance.</p>

    <div class="form-fld-cont">
        <div class="search-input-cont" :class="{ 'is-invalid': domainError }">
            <div class="input-fld-cont domain-select-cont">
                <select v-model="selectedType" class="form-control input-fld select-domain" disabled>
                    <option disabled value="">Domain</option>
                    <option value="domain">Domain</option>
                    <option value="subdomain">Subdomain</option>
                </select>
            </div>
            <div class="input-fld-cont enter-domain">
                <input v-model="domainName" class="form-control input-fld domain-name" type="text"
                    placeholder="Enter a domain or URL" @blur="validateDomain" />
            </div>
        </div>
        <div class="input-fld-cont search-btn-cont">
            <button @click="handleSearch" class="admin-common-btn btn-search" :disabled="loading || isDisabled">
                <span v-if="loading" class="spinner"></span>
                <span v-else>Analyze</span>
            </button>
        </div>
    </div>
    <p v-if="domainError" class="error-message">{{ domainError }}</p>
</div>


                <div class="backlink-page-img-cont">
                    <img class="img-fluid"
                        src="https://serpplus.pluspromotions.co.uk/images/backlink-search-page-bg.png"
                        alt="Photo">
                </div>
            </div>
        </div>
    </div>

    <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT
            Number 438499640</p>
    </div>
</template>

<script setup>
import axiosIns from '@/axios';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { toast } from 'vue3-toastify';

const loading = ref(false);
const selectedType = ref('');
const domainName = ref('');
const domainError = ref('');
const router = useRouter();

const validateDomain = () => {
    const domainPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    if (!domainName.value) {
        domainError.value = 'Domain or URL is required.';
        return false;
    } else if (!domainPattern.test(domainName.value)) {
        domainError.value = 'Please enter a valid domain or URL.';
        return false;
    }
    domainError.value = '';
    return true;
};

const handleSearch = async () => {
    if (validateDomain()) {
        loading.value = true;

        const payload = {
            target: domainName.value,
        };

        try {
            const response = await axiosIns.post(`/backlink/check-backlink`, payload);

            const backlinkId = response.data.data.backlinks_id;
            if (response.data.success) {
                toast.success("Backlink check initiated successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                    duration: 3000
                });

                setTimeout(() => {
                    loading.value = false;
                    router.push(`/backlink-view/${backlinkId}`);
                }, 1000);
            } else {
                throw new Error("Unexpected response from the server.");
            }
        } catch (error) {
            toast.error(`Error: ${error.message}`, {
                position: toast.POSITION.TOP_RIGHT,
                duration: 3000
            });
            loading.value = false;
        }
    }
};

</script>

<style scoped>
.error-message {
    color: red;
    font-size: 14px;
    margin-top: 15px;
    text-align: center;
}

.is-invalid {
    border-color: red !important;
    outline: none;
    transition: border-color 0.3s ease;
}

.admin-common-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.admin-common-btn[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
}

.spinner {
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid white;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}
.disabled-section {
    pointer-events: none;
    opacity: 0.5; /* Makes it look visually disabled */
}
.maintenance-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
