import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/auth/Login.vue";
import Otp from "@/views/auth/Otp.vue";
import Register from "@/views/auth/Register.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import ChangePassword from "@/views/auth/ChangePassword.vue";
import AdminLayout from "@/layouts/AdminLayout.vue";
import Dashboard from "@/views/Dashboard.vue";
import CheckSerp from "@/views/serp/CheckSerp.vue";
import DomainCheck from "@/views/domain/DomainCheck.vue";
import Profile from "@/views/users/Profile.vue";
import UserList from "@/views/users/List.vue";
import RoleList from "@/views/roles/List.vue";
import PermissionList from "@/views/permission/List.vue";
import PurchaseList from "@/views/plansCutomer/List.vue";
import CreditsList from "@/views/creditsList/List.vue";
import PlanList from "@/views/planList/List.vue";
import CustomerList from "@/views/customerList/List.vue";
import ViewCustomer from "@/views/customerList/View.vue";
import FaqCategoryList from "@/views/faq/List.vue";
import FaqQuestionList from "@/views/faq/FaqList.vue";
import SettingList from "@/views/settings/List.vue";
import PaymentDone from "@/views/payment/Done.vue";
import PaymentFailed from "@/views/payment/Failed.vue";
import FaqCustomer from "@/views/faqCustomer/List.vue";
import DomainView from "@/views/domain/DomainView.vue";
import DomainProjectsList from "@/views/domain/DomainProjectsList.vue";
import BackLinkOverView from "@/views/backlinkOverview/checkBacklink.vue";
import BacklinkView from "@/views/backlinkOverview/viewBacklink.vue";
import BacklinkProjectsList from "@/views/backlinkOverview/backlinksProjects.vue";
import EventBus from "@/EventBus";


import serpCreate from "@/views/serp-screens/serpChecker/createSerp.vue";
import viewSerp from "@/views/serp-screens/serpChecker/viewSerp.vue";
import serpProjectCreate from "@/views/serp-screens/serpProject/createProject.vue";
import listSerpProject from "@/views/serp-screens/serpProject/listProjects.vue";
import viewSerpProject from "@/views/serp-screens/serpProject/viewProject.vue";
import PricingPage from "@/views/planList/PricingPage.vue";
import PrivacyPolicy from "@/views/support/PrivacyPolicy.vue";
import EditUserProfile from "@/views/users/EditUserProfile.vue";
import CreditSettings from "@/views/creditsSetting/CreditSettings.vue";


const routes = [
  {
    path: "/",
    component: AdminLayout,
    children: [
      {
        path: "",
        name: "Dashboard",
        component: Dashboard,
      },
      {
        path: "create-serp",
        name: "Create Serp",
        component: serpCreate,
      },
      {
        path: "view-serps",
        name: "View SERP",
        component: viewSerp,
      },
      {
        path: "create-rank-project",
        name: "Create Serp Project",
        component: serpProjectCreate,
      },
      {
        path: "list-serp-project",
        name: "List Serp Project",
        component: listSerpProject,
      },
      {
        path: "view-serp-project/:id",
        name: "View Serp Project",
        component: viewSerpProject,
      },
      {
        path: "domain-check",
        name: "Domain Rank Checker",
        component: DomainCheck,
      },
      {
        path: "domain-projects-list",
        name: "Domain Projects",
        component: DomainProjectsList,
      },
      {
        path: "credit-settings",
        name: "Credits Setting",
        component: CreditSettings,
      },
 
      {
        path: "user-profile",
        name: "Edit Profile",
        component: Profile,
      },
      {
        path: "user-list",
        name: "Manage User",
        component: UserList,
      },
      {
        path: "role-list",
        name: "Manage Roles",
        component: RoleList,
      },
      {
        path: "permission-list",
        name: "Manage Permissions",
        component: PermissionList,
      },
      {
        path: "purchase-list",
        name: "Purchase History",
        component: PurchaseList,
      },
      {
        path: "credits-list",
        name: "Credits History",
        component: CreditsList,
      },
      {
        path: "plan-list",
        name: "Manage Plans",
        component: PlanList,
      },
      {
        path: "customer-list",
        name: "Manage Customers",
        component: CustomerList,
      },
      {
        path: "view-customer/:id",
        name: "Customer View",
        component: ViewCustomer,
      },
      {
        path: "faq-category-list",
        name: "Manage Category",
        component: FaqCategoryList,
      },
      {
        path: "faq-questions-list",
        name: "Manage FAQ Questions",
        component: FaqQuestionList,
      },
      {
        path: "settings-list",
        name: "Settings",
        component: SettingList,
      },
      {
        path: "faq-customer",
        name: "Questions",
        component: FaqCustomer,
      },
      {
        path: "domain-view/:id",
        name: "Domain View",
        component: DomainView,
      },
      {
        path: "backlink-overview",
        name: "Backlink Overview",
        component: BackLinkOverView,
      },
      {
        path: "backlink-view/:id",
        name: "Backlink View",
        component: BacklinkView,
      },
      {
        path: "backlinks-projects-list",
        name: "Backlinks Projects",
        component: BacklinkProjectsList,
      },
      {
        path: "pricing-page",
        name: "Subscription",
        component: PricingPage,
      },
      {
        path: "privacy-policy",
        name: "Privacy Policy",
        component: PrivacyPolicy,
      },
      {
        path: "edit-user-profile/:id",
        name: "Edit User Profile",
        component: EditUserProfile,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/otp-verify",
    name: "otpverify",
    component: Otp,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/forgot-password",
    name: "forgotpassword",
    component: ForgotPassword,
  },
  {
    path: "/changepassword/:token",
    name: "changepassword",
    component: ChangePassword,
  },
  {
    path: "/payment-done",
    name: "paymentDone",
    component: PaymentDone,
  },
  {
    path: "/payment-failed",
    name: "paymentFailed",
    component: PaymentFailed,
  },
];



const router = createRouter({
  history: createWebHistory(),
  routes,
});
const publicRoutes = [
  "login",
  "register",
  "forgotpassword",
  "changepassword",
  "otpverify",
];

router.beforeEach((to, from, next) => {
  const accessToken = localStorage.getItem("accessToken");
  EventBus.$emit("loadCredits", true);

  if (!accessToken && !publicRoutes.includes(to.name)) {
    next("/login");
  } else if (accessToken && publicRoutes.includes(to.name)) {
    next("/");
  } else {
    EventBus.$emit("loadCredits", true);

    next();
  }
});

export default router;

router.afterEach(() => {
  const nav = document.getElementById("nav-bar"),
    toggle = document.getElementById("header-toggle"),
    bodypd = document.getElementById("body-pd"),
    headerpd = document.getElementById("header");
  const toggleIconCont = document.getElementById("header-toggle");

  if (!nav.classList.contains("show")) {
    nav.classList.add("show");
    toggle.classList.add("bx-x");
    bodypd.classList.add("body-pd");
    headerpd.classList.add("body-pd");
  }

  // Check if navigation is closed
  const isNavClosed = !nav.classList.contains("show");

  // Add or remove 'bx-x' class based on the navigation state
  toggleIconCont.classList.toggle("bx-x", isNavClosed);
});
