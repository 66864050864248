<template>

    <div class="common-inner-body faq-customer-page-cont">
        <div class="common-inner-cont">

            <div class="header-faq-search-cont">
                <h2 class="top-title">Hello, How can we help?</h2>
                <p class="bottom-text">Stuck on something or getting started? Our friendly team is here to help.</p>
                <div class="top-faq-search">
                    <input class="faq-top-search-input" placeholder="Search Question" v-model="searchQuery">
                </div>
            </div>

            <div class="quick-find-faq-cont px-0 px-xl-100 mb-50">
                <h2 class="common-section-title">Quick Find</h2>
                <div class="quick-find-box-row row justify-content-center custom-row-10 row-gap-20">
                    <div class="quick-find-box-col col-12 col-md-12 col-lg-6 col-xxl-3 custom-col-10">
                        <div class="quick-find-box border-one border-radius15 text-center p-20 h-100">
                            <img class="icon-quick-find img-fluid mb-10" src="https://serpplus.pluspromotions.co.uk/images/icon-quick-find-faq1.svg" alt="Quick Find Box Icon">
                            <h6 class="title-quick-find text-dark-new fw-semibold mb-10">Is there a free trial available?</h6>
                            <p class="text-quick-find text-light-new mb-0">You get 50 free credits daily to try SERP Plus.</p>
                        </div>
                    </div>
                    <div class="quick-find-box-col col-12 col-md-12 col-lg-6 col-xxl-3 custom-col-10">
                        <div class="quick-find-box border-one border-radius15 text-center p-20 h-100">
                            <img class="icon-quick-find img-fluid mb-10" src="https://serpplus.pluspromotions.co.uk/images/icon-quick-find-faq2.svg" alt="Quick Find Box Icon">
                            <h6 class="title-quick-find text-dark-new fw-semibold mb-10">Can I change my plan later?</h6>
                            <p class="text-quick-find text-light-new mb-0">Yes, you can upgrade your plan later for more benefits.</p>
                        </div>
                    </div>
                    <div class="quick-find-box-col col-12 col-md-12 col-lg-6 col-xxl-3 custom-col-10">
                        <div class="quick-find-box border-one border-radius15 text-center p-20 h-100">
                            <img class="icon-quick-find img-fluid mb-10" src="https://serpplus.pluspromotions.co.uk/images/icon-quick-find-faq3.svg" alt="Quick Find Box Icon">
                            <h6 class="title-quick-find text-dark-new fw-semibold mb-10">What happens if i finish my all credits</h6>
                            <p class="text-quick-find text-light-new mb-0">If you run out of credits, you can top up more credits.</p>
                        </div>
                    </div>
                    <div class="quick-find-box-col col-12 col-md-12 col-lg-6 col-xxl-3 custom-col-10">
                        <div class="quick-find-box border-one border-radius15 text-center p-20 h-100">
                            <img class="icon-quick-find img-fluid mb-10" src="https://serpplus.pluspromotions.co.uk/images/icon-quick-find-faq4.svg" alt="Quick Find Box Icon">
                            <h6 class="title-quick-find text-dark-new fw-semibold mb-10">How do I change my account email?</h6>
                            <p class="text-quick-find text-light-new mb-0">Go to account settings to find the change email option.</p>
                        </div>
                    </div>
                    <div class="quick-find-box-col col-12 col-md-12 col-lg-6 col-xxl-3 custom-col-10">
                        <div class="quick-find-box border-one border-radius15 text-center p-20 h-100">
                            <img class="icon-quick-find img-fluid mb-10" src="https://serpplus.pluspromotions.co.uk/images/icon-quick-find-faq5.svg" alt="Quick Find Box Icon">
                            <h6 class="title-quick-find text-dark-new fw-semibold mb-10">How to reset my password</h6>
                            <p class="text-quick-find text-light-new mb-0">Go to account settings to find the reset password option.</p>
                        </div>
                    </div>
                    <div class="quick-find-box-col col-12 col-md-12 col-lg-6 col-xxl-3 custom-col-10">
                        <div class="quick-find-box border-one border-radius15 text-center p-20 h-100">
                            <img class="icon-quick-find img-fluid mb-10" src="https://serpplus.pluspromotions.co.uk/images/icon-quick-find-faq6.svg" alt="Quick Find Box Icon">
                            <h6 class="title-quick-find text-dark-new fw-semibold mb-10">What happens if i forgot my login</h6>
                            <p class="text-quick-find text-light-new mb-0">You can email us to recover your account.</p>
                        </div>
                    </div>
                    <div class="quick-find-box-col col-12 col-md-12 col-lg-6 col-xxl-3 custom-col-10">
                        <div class="quick-find-box border-one border-radius15 text-center p-20 h-100">
                            <img class="icon-quick-find img-fluid mb-10" src="https://serpplus.pluspromotions.co.uk/images/icon-quick-find-faq7.svg" alt="Quick Find Box Icon">
                            <h6 class="title-quick-find text-dark-new fw-semibold mb-10">How does support work?</h6>
                            <p class="text-quick-find text-light-new mb-0">Use our FAQ for help, or email us for more support.</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="user-faq-cont px-0 px-xl-100">
                <h2 class="common-section-title">General FAQs</h2>
                <div class="common-nav-tab-cont user-faq-tab-cont">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation" v-for="(category, index) in categories"
                            :key="category.id">
                            <button class="nav-link" :class="{ 'active': activeCategory === index }"
                                @click="activeCategory = index; activeQuestion = 0"
                                :id="'question-' + category.id + '-tab'" data-bs-toggle="tab"
                                :data-bs-target="'#question-' + category.id" type="button" role="tab"
                                :aria-controls="'question-' + category.id" :aria-selected="activeCategory === index">{{ category.name }}</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade" :class="{ 'show active': activeCategory === index }"
                            v-for="(category, index) in categories" :key="category.id" :id="'question-' + category.id"
                            role="tabpanel" :aria-labelledby="'question-' + category.id + '-tab'">
                            <div class="top-question-cont d-flex align-items-center mb-30">
                                <img class="q-icon" :src="icons[index]" alt="Icon User">
                                <span class="t-cont">
                                    <h2 class="title-q">{{ category.name }}</h2>
                                </span>
                            </div>
                            <div class="user-faq-div">
                                <div class="common-accordian accordion" id="accordionExample">
                                    <div class="accordion-item" v-for="(question, qIndex) in category.questions"
                                        :key="question.id">
                                        <h2 class="accordion-header">
                                            <button class="accordion-button" type="button"
                                                @click="activeQuestion = qIndex"
                                                :aria-expanded="activeQuestion === qIndex"
                                                :aria-controls="'collapse-' + category.id + '-' + qIndex"
                                                v-html="question.highlighted || question.question">
                                            </button>
                                        </h2>
                                        <div :id="'collapse-' + category.id + '-' + qIndex"
                                            class="accordion-collapse collapse"
                                            :class="{ 'show': activeQuestion === qIndex }"
                                            data-bs-parent="#accordionExample">
                                            <div class="accordion-body">{{ question.answer }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>

        </div>
    </div>  

  
    <copyRight></copyRight>

</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import copyRight from '../copyRight';
import axiosIns from '@/axios';

const categories = ref([]);
const searchQuery = ref('');
const icons = ref([
    'https://serpplus.pluspromotions.co.uk/images/icon-1.png',
    'https://serpplus.pluspromotions.co.uk/images/icon-2.png',
    'https://serpplus.pluspromotions.co.uk/images/icon-3.png',
    'https://serpplus.pluspromotions.co.uk/images/icon-4.png',
    'https://serpplus.pluspromotions.co.uk/images/icon-5.png',
    'https://serpplus.pluspromotions.co.uk/images/icon-6.png',
    'https://serpplus.pluspromotions.co.uk/images/icon-7.png'
])
const activeCategory = ref(0);
const activeQuestion = ref(0);
watch(searchQuery, async (newVal, oldVal) => {
    if (newVal !== oldVal) {
        const filteredCategories = categories.value.filter(category => {
            return category.questions.some(question => 
                question.question.toLowerCase().includes(newVal.toLowerCase())
            );
        });

        if (searchQuery.value === '') {
            try {
                const response = await axiosIns.get('category-faq');
                categories.value = response.data;
                activeCategory.value = 0;
                activeQuestion.value = 0;
            } catch (error) {
                console.error('Error fetching categories:', error.message);
            }
        } else {
            // Filter and highlight matched questions
            const filteredCategoriesWithMatchedQuestions = filteredCategories.map(category => {
                return {
                    ...category,
                    questions: category.questions.map(question => {
                        return {
                            ...question,
                            highlighted: question.question.replace(
                                new RegExp(`(${newVal})`, "gi"),
                                `<mark>$1</mark>`
                            )
                        };
                    })
                };
            });

            if (filteredCategoriesWithMatchedQuestions.length > 0) {
                categories.value = filteredCategoriesWithMatchedQuestions;
                activeCategory.value = 0;
                activeQuestion.value = 0;
            }
        }
    }
});



onMounted(async () => {
    try {
        const data = await axiosIns.get('category-faq');
        categories.value = data.data;
    } catch (error) {
        console.error('Error:', error.message);
    }

});


</script>