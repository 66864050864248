<template>
  <div class="backlink-projects-page-cont">
    <div class="backlink-table-cont">
      <div class="backlink-table-box">
        
        <div class="box-top box-top-with-filter-new justify-content-between">
            <div class="table-top-left-info d-flex flex-wrap align-items-center row-gap-3">
                <router-link to="/backlink-overview" class="btn btn-light admin-common-btn me-4">
                    <img class="img-fluid"
                        src="https://serpplus.pluspromotions.co.uk/images/icon-check-serp.svg"
                        alt="Check Serp Icon" />
                        Analyze Backlinks
                </router-link>
            </div>
            <div class="table-top-bar">
                <div class="top-left">
                    <div class="form-group search-group">
                      <input class="table-search-input" placeholder="Search Here" v-model="searchQuery" @input="getBacklinkProjects" />
                    </div>
                </div>
                <!-- <div class="top-right">
                    <div class="form-group btn-group">
                        <button class="btn btn-light filter-btn search-btn">Search</button>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="box-body">
          <div class="data-table-cont">
            <div class="table-responsive table-container table-normal-view table-backlink"  @scroll="handleScroll">
              <table
                id="example3"
                class="data-table table table-competitor-view dataTable no-footer"
              >
                <thead>
                  <tr>
                    <th class="no-wrap" style="width: 50% !important">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Domain</span>
                          <img
                            class="img-fluid th-icon"
                            src="https://serpplus.pluspromotions.co.uk/images/icon-question-circle.svg"
                            alt="Question Icon"
                            title="Domain"
                          />
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Date Created</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-center">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Open Project</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-center">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Remove</span>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(project, index) in allData"
                    :key="index"
                  >
                    <td class="align-left no-wrap">
                      <!-- <input class="table-checkbox checkbox-new form-control" type="checkbox"> -->
                      {{ project.target }}
                    </td>
                    <td class="align-left">
                      {{ new Date(project.created_at).toLocaleDateString() }}
                    </td>
                    <td class="align-center">
                      <p class="text-highlight">
                        <RouterLink
                          :to="`/backlink-view/${project.id}`"
                          class="text-primary"
                        >
                          Open
                          <img
                            class="img-fluid"
                            src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg"
                            alt="Open Link Icon"
                            title="Open"
                          />
                        </RouterLink>
                      </p>
                    </td>
                    <td class="align-center">
                      <a
                        class="table-action-btn-new bg-signal-red-light btn-remove"
                        href="#"
                        @click.prevent="deleteBacklinkProject(project.id)"
                        title="Remove"
                      >
                        <img
                          class="img-fluid"
                          src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg"
                          alt="Remove Icon"
                        />
                      </a>
                    </td>
                  </tr>
                  <tr v-if="isLoading">
                    <td colspan="4" class="text-center">
                      <p style="font-size: 1.2em">⏳ Loading...</p>
                    </td>
                  </tr>
                  <tr v-if="!isLoading && allData.length === 0">
                    <td colspan="4" class="text-center">
                      <p style="font-size: 1.2em">🧐 No Data Available</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
         
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Copyright Section -->
  <div class="copyright-cont">
    <p class="copyright-text">
      &copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number
      13871077, VAT Number 438499640
    </p>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import axiosIns from "@/axios";
import { RouterLink } from "vue-router";
import { toast } from "vue3-toastify";

const searchQuery = ref("");
const currentPage = ref(1);
const lastPage = ref(1);
const isLoading = ref(false);

const allData = ref([]);

const handleScroll = (event) => {
  const container = event.target;

  if (container.scrollHeight > container.clientHeight) {
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight
    ) {
      loadMore();
    }
  }
};

const loadMore = () => {
  if (currentPage.value >= lastPage.value) {
    console.log("No more data to load.");
    return;
  }

  setTimeout(() => {
    currentPage.value++;
    getBacklinkProjects();
  }, 1000);
};

const getBacklinkProjects = async () => {
  isLoading.value = true;

  try {
    const response = await axiosIns.get('/backlink/get-backlink-projects', {
      params: {
        page: currentPage.value, 
        per_page: 10, 
      },
    });

  
    if (currentPage.value === 1) {
      allData.value = response.data.data;
    } else {
      allData.value.push(...response.data.data); 
    }

    lastPage.value = response.data.pagination.last_page;
    currentPage.value = response.data.pagination.current_page;

    isLoading.value = false;
  } catch (error) {
    console.error('Error fetching backlink projects:', error);
    isLoading.value = false;
  }
};


const deleteBacklinkProject = async (id) => {
  const confirmDelete = confirm(
    "Are you sure you want to delete this project?"
  );

  if (!confirmDelete) {
    return;
  }

  try {
    await axiosIns.delete(`/backlink/delete-backlink-project/${Number(id)}`);
    toast.success("Project deleted successfully");
  } catch (error) {
    console.error("Error deleting project:", error);
    toast.error("Failed to delete project");
  }
};

onMounted(async () => {
  await getBacklinkProjects();
});
</script>


<style scoped>


.table-container {
  max-height: 1500px;
  overflow-y: auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1;
}</style>