<template>
    <div class="common-inner-body create-serp-page-cont">
        <div class="common-inner-cont">

            <div class="privacy-policy-top-banner">
                <h2 class="top-title">Serp Plus Terms of use</h2>
                <p class="bottom-text mx-auto">Welcome to Serp Plus! We are committed to protecting your privacy and ensuring that your personal information is handled securely and responsibly. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our website and services.</p>
                <div class="btn-cont d-flex flex-wrap justify-content-center align-items-center col-gap-20 row-gap-10">
                    <a class="admin-common-btn btn-dark-blue" href="#">Last Updated: January 2025</a>
                    <!-- <a class="admin-common-btn btn-dark-blue" href="#">Last Updated: January 2025</a> -->
                </div>
            </div>

            <div class="privacy-policy-body-cont">
                <div class="row">
                    <div class="col-12 col-xl-8">
                        <div class="privacy-policy-box-cont mb-30">
                            <div class="privacy-policy-box">
                                <h4 class="privacy-policy-title">Serp Plus Terms of use</h4>
                                <div class="privacy-policy-text">
                                    <h4 class="title-h4">We may collect the following types of information:</h4>
                                    <ul>
                                        <li><strong>Personal Information:</strong> Name, email address, contact details, and payment information when you register or make a purchase.</li>
                                        <li><strong>Usage Data:</strong> IP address, browser type, device information, and interactions with our platform.</li>
                                        <li><strong>Cookies and Tracking Technologies:</strong> We use cookies to improve user experience, track preferences, and analyze site performance.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="privacy-policy-box">
                                <h4 class="privacy-policy-title">How We Use Your Information</h4>
                                <div class="privacy-policy-text">
                                    <h4 class="title-h4">We use the collected information for the following purposes:</h4>
                                    <ul>
                                        <li>To provide and improve our SERP checking and domain-related services.</li>
                                        <li>To personalize your experience and enhance our website functionality.</li>
                                        <li>To process transactions and send important account-related notifications.</li>
                                        <li>To analyze trends, monitor website usage, and enhance security.</li>
                                        <li>To comply with legal obligations and prevent fraudulent activities.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="privacy-policy-box">
                                <h4 class="privacy-policy-title">Sharing Your Information</h4>
                                <div class="privacy-policy-text">
                                    <h4 class="title-h4">We do not sell or rent your personal data. However, we may share your information with:</h4>
                                    <ul>
                                        <li>Service providers who assist in our operations (e.g., payment processors, hosting services).</li>
                                        <li>Legal authorities when required to comply with laws or protect our rights.</li>
                                        <li>Third-party analytics tools to help us improve our services.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="privacy-policy-box">
                                <h4 class="privacy-policy-title">Data Security</h4>
                                <div class="privacy-policy-text">
                                    <p>We implement strict security measures to protect your data from unauthorized access, alteration, or disclosure. However, no online platform can guarantee 100% security, and we encourage users to take precautions when sharing personal information.</p>
                                </div>
                            </div>
                            <div class="privacy-policy-box">
                                <h4 class="privacy-policy-title">Your Rights and Choices</h4>
                                <div class="privacy-policy-text">
                                    <h4 class="title-h4">You have the right to:</h4>
                                    <ul>
                                        <li>Access, update, or delete your personal information.</li>
                                        <li>Opt out of marketing communications.</li>
                                        <li>Disable cookies through your browser settings.</li>
                                        <li>Request a copy of the data we hold about you.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="privacy-policy-box">
                                <h4 class="privacy-policy-title">Third-Party Links</h4>
                                <div class="privacy-policy-text">
                                    <p>Our website may contain links to third-party websites. We are not responsible for their privacy practices, and we encourage you to review their policies before providing any information.</p>
                                </div>
                            </div>
                            <div class="privacy-policy-box">
                                <h4 class="privacy-policy-title">Changes to This Policy</h4>
                                <div class="privacy-policy-text">
                                    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and significant updates may be communicated via email or website notifications.</p>
                                </div>
                            </div>
                            <div class="privacy-policy-box">
                                <h4 class="privacy-policy-title">Contact Us</h4>
                                <div class="privacy-policy-text">
                                    <p>If you have any questions or concerns about this Privacy Policy, <br>please contact us for More Support.</p>
                                </div>
                            </div>                            
                        </div>
                        <a class="admin-common-btn fs-16" href="javascript:void(0);"><img class="img-fluid mr-10" src="https://serpplus.pluspromotions.co.uk/images/icon-email-white.svg" alt="Icon Email" /> Contact Us</a>
                    </div>
                    <div class="col-12 col-xl-4 text-center mt-5 mt-xl-0">
                        <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/privacy-policy-right-img.png" alt="Nav Toggle Icon" />
                    </div>
                </div>
            </div>

        </div>
    </div>

    <copyRight></copyRight>

</template>

<script>
import CopyRight from '../copyRight.vue';

export default {
    components: {
        CopyRight
    },
};
</script>