<template>

  <loader :active="loading" message="Please wait..." />

  <div class="common-inner-body manage-permissions-page-cont">
    <div class="common-inner-cont">
      <div class="common-box">

          <div class="box-top box-top-with-filter-new justify-content-between">
              <div class="table-top-left-info d-flex flex-wrap align-items-center row-gap-3">
                  <!-- <a class="admin-common-btn" @click="addPermission" data-bs-toggle="modal" data-bs-target="#modal-add-role">Add Permission</a> -->
                  <a class="btn btn-light admin-common-btn me-4" @click="addPermission" data-bs-toggle="modal" data-bs-target="#modal-add-role">
                      <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-plus.svg" alt="Icon Add Role" /> Add Permission
                  </a>
              </div>
              <div class="table-top-bar">
                  <!-- <div class="top-left">
                      <div class="form-group search-group">
                          <input class="table-search-input" placeholder="Search Here" v-model="searchQuery" />
                      </div>
                  </div>
                  <div class="top-right">
                      <div class="form-group btn-group">
                          <button class="btn btn-light filter-btn search-btn">Search</button>
                      </div>
                  </div> -->
              </div>
          </div>

          <div class="module-section mb-20" v-for="(permissions, moduleName) in permissionsGroupedByModule" :key="moduleName">
            <h3 class="module-title fs-20 fw-semibold mb-20">{{ moduleName.replace(/_/g, ' ') }}</h3>
            <div class="manage-permission-table-cont" v-if="permissions.length > 0">
              <div class="data-table-cont">
                <div class="table-responsive table-container table-view-serp table-manage-permission">
                  <table id="example" class="data-table table table-border-none mb-0">
                    <thead>
                      <tr>
                        <th class="text-start width40">Name</th>
                        <th class="text-start width40">Description</th>
                        <th class="width10">Edit Permission</th>
                        <th class="width10">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="permission in permissions" :key="permission.id">
                        <td class="text-start">{{ permission.name }}</td>
                        <td class="text-start">{{ permission.description.replace(/_/g, ' ') }}</td>
                        <td>
                          <div class="action-icon-cont d-flex justify-content-center">
                            <a class="table-action-btn-new bg-yellow-light-two icon-edit" data-bs-toggle="modal"
                              data-bs-target="#modal-add-role" @click="editPermission(permission)">
                              <img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-edit-faq-cat.svg" alt="Edit">
                            </a>
                          </div>
                        </td>
                        <td>
                          <div class="action-icon-cont d-flex justify-content-center">
                            <a class="table-action-btn-new bg-signal-red-light btn-remove" @click="deletePermission(permission)">
                              <img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" />
                            </a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="manage-permission-table-cont no-permissions" v-else>
              No permissions available.
            </div>
          </div>

      </div>
    </div>
  </div>

  <!-- MODAL - ADD USER - START -->
  <div id="modal-add-role" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-490 modal-dialog-centered">
      <div class="modal-content">
        <form action="">
          <div class="modal-header">
            <h5 class="modal-title flex-column">
              <span class="mod-title">{{ isUpdateBtn ? 'Edit Permission' : 'Add Permission' }}</span>
              <p class="modal-sub-title">{{ isUpdateBtn ? 'Update' : 'Add' }} Your Permission Information</p>
            </h5>
            <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <img src="https://serpplus.pluspromotions.co.uk/images/icon-modal-close.svg" alt="Close">
            </a>
          </div>
          <div class="modal-body">
            <div class="modal-form-cont check-serp-form-cont">
              <div class="form-group">
                <label>Name:</label>
                <input class="form-control" type="text" placeholder="Enter Permission Name" v-model="permission.name"
                  required>
              </div>
            </div>
            <div class="modal-form-cont check-serp-form-cont">
              <div class="form-group">
                <label>Description:</label>
                <textarea class="form-control" placeholder="Enter Permission Description"
                  v-model="permission.description" required></textarea>
              </div>
            </div>
            <p class="fs-16 text-light-new mt-5px mb-25">Please click save before close the window</p>
          </div>
          <div class="modal-footer col-gap-20 row-gap-10">
            <Button class="admin-common-btn btn-light-red btn-reset" type="reset">Reset</Button>
            <Button class="admin-common-btn btn btn-primary btn-add-role" type="button" data-bs-dismiss="modal"
              @click="addOrUpdatePermission">
              {{ isUpdateBtn ? 'Save Changes' : 'Save' }}
            </Button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- MODAL - ADD USER - END -->

  <CopyRight></copyRight>

</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useAbility } from '@casl/vue';
import CopyRight from '../copyRight.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axiosIns from '@/axios';
import loader from '../Loader.vue';

const permissions = ref([]);
const isUpdateBtn = ref(false);
const permission = ref({});
const loading = ref(false)
async function fetchPermissions() {
  loading.value = true
  try {
    const response = await axiosIns.get('/permissions');
    loading.value = false
    permissions.value = response.data.data;
  } catch (error) {
    console.error('Error fetching permissions:', error);
  }
}

const permissionsGroupedByModule = computed(() => {
  return permissions.value.reduce((grouped, permission) => {
    const module = permission.description;
    if (!grouped[module]) {
      grouped[module] = [];
    }
    grouped[module].push(permission);
    return grouped;
  }, {});
});


const addPermission = () => {
  permission.value = {}
  isUpdateBtn.value = false
}

const permissionId = ref(null)

const editPermission = async (data) => {
  isUpdateBtn.value = true;
  permissionId.value = data.id;
  permission.value.name = data.name
  permission.value.description = data.description

}

const createPermission = async () => {

  try {
    await axiosIns.post('permissions', { name: permission.value.name, description: permission.value.description });

    toast.success('Permission Created', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000
    });

    setTimeout(async () => {
      await fetchPermissions();
    }, 1000);
  } catch (error) {
    console.error('Error creating permission:', error.message);
  }
};

const updatePermission = async () => {
  try {
    await axiosIns.put(`/permissions/${permissionId.value}`, { name: permission.value.name, description: permission.value.description });

    toast.success('Permission Updated', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000
    });
    setTimeout(async () => {
      await fetchPermissions();
    }, 1000);
  } catch (error) {
    console.error('Error updating permission:', error.message);
  }
};



const deletetPermission = async (permission) => {
  try {
    const confirmed = confirm(`Are you sure you want to delete Permission?`);
    if (confirmed) {
      await axiosIns.delete(`/permissions/${permission.id}`);
      permissions.value = permissions.value.filter((u) => u.id !== permission.id);
      toast.success(`Permission deleted!`, {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000
      });
    }
  } catch (error) {
    console.error('Error Deleting Permission:', error);
    toast.error('Failed to delete permission. Please try again later.', {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000
    });
  }
};

const addOrUpdatePermission = () => {
  if (isUpdateBtn.value) {
    updatePermission();
  } else {
    createPermission();
  }
};

onMounted(async () => {
  try {
    loading.value = true
    const data = await axiosIns.get('permissions');
    permissions.value = data.data.data;
    loading.value = false
  } catch (error) {
    console.error('Error:', error.message);
  }

});


</script>
<style scoped>
.permissions-container {
  padding: 20px;
  max-width: 100%;
}

.module-section {
  /* margin-bottom: 40px; */
}

.module-title {
  /* font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #333; */
}

.permissions-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.permissions-table th,
.permissions-table td {
  padding: 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.permissions-table th {
  background-color: #f8f9fa;
  color: #6c757d;
  font-weight: 600;
  text-transform: uppercase;
}

.permissions-table tbody tr:hover {
  background-color: #f1f1f1;
}

.permissions-table td {
  font-size: 1rem;
  color: #333;
}

.action-buttons {
  display: flex;
  gap: 12px;
}

.action-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
}

.action-icon:hover {
  background-color: #f8f9fa;
}

.icon-img {
  width: 20px;
  height: 20px;
}

.icon-edit {
  color: #007bff;
}

.icon-edit:hover {
  background-color: #e9f5ff;
}

.icon-delete {
  color: #dc3545;
}

.icon-delete:hover {
  background-color: #f8d7da;
}

.module-section .no-permissions {
  text-align: center;
  color: #999;
  font-size: 1rem;
  margin-top: 20px;
}

</style>
