<template>
    <div v-if="active" class="overlay">
        <!-- From Uiverse.io by mrpumps31232 --> 
        <div class="loading-wave">
            <div class="loading-bar"></div>
            <div class="loading-bar"></div>
            <div class="loading-bar"></div>
            <div class="loading-bar"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Loader',
    props: {
        active: Boolean,
        text: String
    }
}
</script>

<style scoped>
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    /* Ensure it's on top of everything */
}

/* From Uiverse.io by mrpumps31232 */ 
.loading-wave {
    width: 60%; /* Use a percentage for width */
    max-width: 300px; /* Limit max width for large screens */
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.loading-bar {
    width: 10%; /* Use percentage for width */
    max-width: 20px; /* Limit maximum width */
    height: 10px;
    margin: 0 2%; /* Use percentage for margin */
    background-color: #45C9F4;
    border-radius: 5px;
    animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
    animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
    animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
    animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
    0% {
        height: 10px;
    }

    50% {
        height: 50px;
    }

    100% {
        height: 10px;
    }
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .loading-wave {
        height: 80px; /* Adjust height for smaller screens */
    }

    .loading-bar {
        height: 8px; /* Slightly smaller bars on mobile */
    }
}
</style>
