<template>

    <div class="profile-body-cont">



        <div class="admin-body flex-unset">
            <div class="admin-body-box">
                <div class="profile-form-row row">
    <div class="form-col col-xs-12 col-md-6 col-lg-3">
        <div class="form-group">
            <label>Name</label>
            <input class="form-control" v-model="user.name" type="text" placeholder="John Doe">
        </div>
    </div>
    <div class="form-col col-xs-12 col-md-6 col-lg-3">
        <div class="form-group">
            <label>Email</label>
            <input class="form-control" type="email" v-model="user.email" placeholder="john.doe@example.com">
        </div>
    </div>
    <div class="form-col col-xs-12 col-md-6 col-lg-3">
        <div class="form-group">
            <label>Contact</label>
            <input class="form-control" type="text" v-model="user.contact" placeholder="+1234567890">
        </div>
    </div>
    <div class="form-col col-xs-12 col-md-6 col-lg-3">
        <div class="form-group">
            <label>Country</label>
            <select class="form-control" v-model="user.country">
                <option>Select a Country</option>
                <option v-for="country in regionList" :key="country.country_iso_code"
                        :value="country.location_code">
                    {{ country.location_name }}
                </option>
            </select>
        </div>
    </div>
</div>

                <div class="form-col col-xs-12 col-md-12 col-lg-4">
                    <div class="form-group two-btn-cont mb-0">
                        <button class="admin-common-btn btn-bordered btn height-50 btn-cancel"
                            type="button">Cancel</button>
                        <button class="admin-common-btn btn btn-primary height-50 btn-save" type="submit"
                            :loading="isLoading" @click="updateUser">Save</button>
                        <button class="admin-common-btn btn btn-secondary height-50" type="button"
                            data-bs-toggle="modal" data-bs-target="#modal-change-password">Change Password</button>
                    </div>
                </div>
            </div>
        </div>



    </div>

    <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT
            Number 438499640</p>
    </div>

    <!-- MODAL - CHANGE PASSWORD - START -->
    <div id="modal-change-password" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-490 modal-dialog-centered">
            <div class="modal-content">
                <form @submit.prevent="updateUser">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <span class="mod-title">Change Password</span>
                            <!-- <div class="info-cont tooltip-trigger">
                                <img class="info-icon" src="images/icon-info-circle.svg" alt="Info">
                                <div class="custom-tooltip">
                                    <div class="tooltip-cont">
                                        <img class="icon" src="images/icon-info-circle-white.svg" alt="Info">
                                        <span class="text">Change Password</span>
                                    </div>
                                </div>
                            </div> -->
                        </h5>
                        <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img src="images/icon-popup-close.svg" alt="Close">
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="modal-form-cont check-serp-form-cont">
                            <div class="form-group">
                                <label>Old Password</label>
                                <input v-model="oldPassword" class="form-control" type="password"
                                    placeholder="Enter Old Password" required>
                                <span class="eye-icon-cont">
                                    <img class="icon-eye" src="images/icon-eye.svg" alt="Icon Eye">
                                </span>
                            </div>
                            <div class="form-group">
                                <label>New Password</label>
                                <input v-model="newPassword" class="form-control" type="password"
                                    placeholder="Enter New Password" required>
                                <span class="eye-icon-cont">
                                    <img class="icon-eye" src="images/icon-eye.svg" alt="Icon Eye">
                                </span>
                            </div>
                            <div class="form-group">
                                <label>Confirm Password</label>
                                <input v-model="confirmPassword" class="form-control" type="password"
                                    placeholder="Confirm New Password" required>
                                <span class="eye-icon-cont">
                                    <img class="icon-eye" src="images/icon-eye.svg" alt="Icon Eye">
                                </span>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="admin-common-btn btn-bordered btn btn-cancel" type="button"
                            data-bs-dismiss="modal" aria-label="Close">Cancel</button>
                        <button class="admin-common-btn btn btn-primary btn-save" data-bs-dismiss="modal"
                            type="submit">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- MODAL - CHANGE PASSWORD - END -->

</template>

<script setup>
import { ref, onMounted } from 'vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import CountryService from '@/service/CountryService';
import axiosIns from '@/axios';

const countryService = new CountryService();
const user = ref({});
const isLoading = ref(false);
const errorMsg = ref(null);
const regionList = ref(null);
const oldPassword = ref(null);
const newPassword = ref(null);
const confirmPassword = ref(null);

const updateUser = async () => {
    if (newPassword.value !== null && confirmPassword.value !== null && newPassword.value !== confirmPassword.value) {
        alert("New password and confirm password do not match.");
        isLoading.value = false;
        return false;
    }

    isLoading.value = true;
    const payload = {
        name: user.value.name,
        email: user.value.email,
        country: user.value.country,
        contact: user.value.contact,
        oldPassword: oldPassword.value,
        password: newPassword.value
    };

    try {
        await axiosIns.put(`/profile/update/${user.value.id}`, payload);
        toast.success('Profile Updated', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000 // 3000 milliseconds timeout
        });
        isLoading.value = false;

    } catch (error) {
        let errorMessage = 'An error occurred while updating the user.';

        if (error.response && error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
            errorMsg.value = errorMessage;
        }
        toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000 // 3000 milliseconds timeout
        });


        isLoading.value = false;
    }
};

const fetchUserData = async () => {
    try {
        const response = await axiosIns.get('/user/show');
        const userData = response.data.userData;

        user.value = {
            id: userData.id,
            name: userData.name,
            email: userData.email,
            country: userData.country,
            contact: userData.contact,
        };

    } catch (error) {
        console.error('Error fetching user data:', error);
    }
};

onMounted(async () => {
    await fetchUserData();
    countryService.getCountries().then((data) => {
        regionList.value = data;
    });
});
</script>