<template>
    <div class="common-inner-body">
        <div class="common-inner-cont">



            <div class="dashboard-container" v-if="userRole == 'Superadmin'">

                <div class="dashboard-quick-ststistics mb-30">

                    <h2 class="dashboard-title d-flex flex-wrap align-items-center col-gap-10"><img class="img-fluid"
                            src="https://serpplus.pluspromotions.co.uk/images/icon-quick-ststistics.svg" alt="Icon Quick Ststistics">
                        Quick Statistics</h2>

                    <div class="dashboard-cards-container">

                        <div class="dashboard-card bg-one">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-dashboard-card1.svg"
                                        alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Total Revenue</div>
                                <div class="dashboard-card-value">{{ dashboardData?.totalRevenue?.totalRevenue }} {{
                                    dashboardData?.totalRevenue?.currency }}</div>
                            </div>
                        </div>

                        <div class="dashboard-card bg-two">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-dashboard-card2.svg"
                                        alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Total Number of Users</div>
                                <div class="dashboard-card-value">{{ dashboardData?.totalUsers }}</div>
                            </div>
                        </div>

                        <div class="dashboard-card bg-three">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-dashboard-card3.svg"
                                        alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Number of Trial Users</div>
                                <div class="dashboard-card-value">{{ dashboardData?.trialUsers }}</div>
                            </div>
                        </div>

                        <div class="dashboard-card bg-four">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-dashboard-card4.svg"
                                        alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Number of Paid Users</div>
                                <div class="dashboard-card-value">{{ dashboardData?.paidUsers }}</div>
                            </div>
                        </div>

                        <div class="dashboard-card bg-five">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-dashboard-card5.svg"
                                        alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Number of Active Users</div>
                                <div class="dashboard-card-value">{{ dashboardData?.activeUsers }}</div>
                            </div>
                        </div>

                        <div class="dashboard-card bg-six">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-dashboard-card6.svg"
                                        alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Inactive Users</div>
                                <div class="dashboard-card-value">{{ dashboardData?.inactiveUsers }}</div>
                            </div>
                        </div>

                       

                        <div class="dashboard-card bg-eight">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-dashboard-card8.svg"
                                        alt="Icon Dashboard Card">
                                </div>
                                <div class="dashboard-card-title">Number of SERPS</div>
                                <div class="dashboard-card-value"> {{ dashboardData?.today?.todaySerps }} <span
                                        class="info">Today</span> {{ dashboardData?.total?.totalSerpsCount }} <span
                                        class="info">Total</span></div>
                            </div>
                        </div>

                    </div>

                </div>

                <div class="dashboard-credits-use">
                    <h2 class="dashboard-title d-flex flex-wrap align-items-center col-gap-10"><img class="img-fluid"
                            src="https://serpplus.pluspromotions.co.uk/images/icon-credit-use.svg" alt="Icon Credits Use"> Credits Use
                    </h2>

                    <!-- Chart with Radio Buttons -->
                    <div class="chart-container ">
                        <div class="filter-container">
                            <!-- <div class="filter-label">Select Filter:</div> -->
                            <div class="filter-label">Daily Credits Usage By Customers</div>
                            <div class="form-check-group-inline">
                                <div class="form-group mb-0">
                                    <div class="custom-form-check-new form-check-inline">
                                        <input type="radio" class="form-check-input" v-model="selectedFilter" form-check-input value="weekly"
                                            @change="fetchDashboardData" />
                                        <label class="form-check-label" for="checkboxWeekly">Weekly</label>
                                    </div>
                                </div>
                                <div class="form-group mb-0">
                                    <div class="custom-form-check-new form-check-inline">
                                        <input type="radio" class="form-check-input" v-model="selectedFilter"
                                            value="monthly" @change="fetchDashboardData" />
                                        <label class="form-check-label" for="checkboxMonthly">Monthly</label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <apexchart type="area" height="350" :options="chartOptions" :series="chartSeries" />
                    </div>

                </div>

            </div>

            <div class="dashboard-container" v-else>

                <div class="dashboard-quick-ststistics mb-30">

                    <h2 class="dashboard-title d-flex flex-wrap align-items-center col-gap-10">
                        Quick Statistics
                    </h2>

                    <div class="dashboard-cards-container">

                        <div class="dashboard-card bg-one">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    🔢
                                </div>
                                <div class="dashboard-card-title">Left Credits</div>
                                <div class="dashboard-card-value">{{ dashboardData?.userData?.leftCredits }}</div>
                            </div>
                        </div>

                      

                        <div class="dashboard-card bg-three">
                            <div class="card-content">
                                <div class="dashboard-card-icon">
                                    🏆
                                </div>
                                <div class="dashboard-card-title">User Plan</div>
                                <div class="dashboard-card-value">
                                    {{ planName || 'Free Plan' }}<br>
                                    <span v-if="planName && remainingDays !== null">
                                        Remaining Days: {{ remainingDays > 0 ? remainingDays : 'Expired' }}
                                    </span>
                                    <span v-else>
                                        No active subscription.
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>


        </div>
    </div>



    <copyRight></copyRight>

</template>

<script>
import axiosIns from '@/axios';
import VueApexCharts from 'vue3-apexcharts';
import CopyRight from '../views/copyRight.vue';
import EventBus from "@/EventBus";

export default {
    components: {
        apexchart: VueApexCharts,
        CopyRight
    },
    data() {
        return {
            dashboardData: null,
            userRole: null,
            selectedFilter: 'weekly',
            planName: null,
            reminingDays: null,
            chartOptions: {
                chart: {
                    id: 'credits-chart',
                    type: 'area', // Area chart
                    zoom: { enabled: false },
                    toolbar: { show: false },
                },
                title: {
                    // text: 'Daily Credits Usage By Customers',
                    align: 'center',
                    style: {
                        fontSize: '18px',
                        fontWeight: '600',
                        color: '#333',
                    },
                },
                xaxis: {
                    categories: [],
                    labels: {
                        style: {
                            colors: '#666',
                            fontSize: '12px',
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: {
                            colors: '#666',
                            fontSize: '12px',
                        },
                    },
                },
                grid: {
                    borderColor: '#e4e7ed',
                    row: {
                        colors: ['#fff', '#f9f9f9'],
                        opacity: 0.5,
                    },
                },
                stroke: {
                    curve: 'smooth',
                    width: 2,
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        type: 'vertical',
                        shadeIntensity: 0,
                        gradientToColors: ['rgba(104, 210, 245, 0)'],
                        inverseColors: false,
                        opacityFrom: 0.9,
                        opacityTo: 0.0,
                        stops: [0, 100],
                        colorStops: [
                            { offset: 0, color: '#68D2F5', opacity: 1 },
                            { offset: 100, color: 'rgba(104, 210, 245, 0)', opacity: 0 },
                        ],
                    },
                },
                colors: ['#68D2F5'], // Line color
                tooltip: {
                    theme: 'light',
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'right',
                },
            },
            chartSeries: [],
        };

    },
    mounted() {
        EventBus.$emit("loadCredits", true);
        const userData = JSON.parse(localStorage.getItem('userData'));
        this.userRole = userData?.role[0];
        this.fetchDashboardData();
        this.loadUser();
    },
   
    methods: {
               async loadUser() {
            try {
                const data = await axiosIns.get('/user/show');
                const userPlan = data.data.plan;

                if (userPlan && userPlan.subscription) {
                    this.planName = userPlan.subscription.name;

                    const expiryDate = new Date(userPlan.subscription.expiry_date);
                    const currentDate = new Date();

                    const timeDifference = expiryDate - currentDate;
                    const remainingDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

                    this.remainingDays = remainingDays;
                } else {
                    console.log('No active subscription or plan found.');
                }
            } catch (error) {
                console.error('Failed to load user data:', error);
            }
        },
        async fetchDashboardData() {
            try {
                const response = await axiosIns.get('/dashboard', {
                    params: {
                        filter: this.selectedFilter, 
                    },
                });
                this.dashboardData = response.data;
                this.updateChartData();
            } catch (error) {
                this.error = error.message;
            } finally {
                this.loading = false;
            }
        },
        updateChartData() {
            const selectedData = this.selectedFilter === 'weekly'
                ? this.dashboardData?.dailyCredits ?? []
                : this.dashboardData?.dailyCredits ?? [];
            this.chartOptions.xaxis.categories = selectedData.map(item => item.date);
            this.chartSeries = [
                {
                    name: 'Credits',
                    data: selectedData.map(item => item.count),
                },
            ];
        },
    },
    watch: {
        selectedFilter(newValue) {
            this.updateChartData();
        },
    },
};

</script>

<style scoped>
</style>