<template>

    <loader :active="loading" message="Please wait..." />

    <div class="common-inner-body manage-user-page-cont">
        <div class="common-inner-cont">
            <div class="common-box">

                <div class="box-top box-top-with-filter-new justify-content-between">
                    <div class="table-top-left-info d-flex flex-wrap align-items-center row-gap-3">
                        <a class="btn btn-light admin-common-btn me-4" @click="addUser" data-bs-toggle="modal" data-bs-target="#modal-add-user"><img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-add-user.svg" alt="Check Serp Icon" /> Add User</a>
                    </div>
                    <div class="table-top-bar">
                        <div class="top-left">
                            <div class="form-group search-group">
                                <input class="table-search-input" placeholder="Search Here" v-model="searchQuery" />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="data-table-cont">
                    <div class="table-responsive table-container table-view-serp table-manage-user" @scroll="handleScroll">
                        <table id="example" class="data-table table table-border-none mb-0">
                            <thead class="sticky-top">
                                <tr>
                                    <th class="no-wrap">ID</th>
                                    <th class="no-wrap text-start">User Name</th>
                                    <th class="no-wrap text-start">User Role</th>
                                    <th class="no-wrap">Current Plan</th>
                                    <th class="no-wrap text-start">Credits left</th>
                                    <th class="no-wrap">Open Profile</th>
                                    <th class="no-wrap">Edit Info</th>
                                    <th class="no-wrap">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="user in users" :key="user.id">
                                    <td class="no-wrap">{{ user.id }}</td>
                                    <td class="no-wrap text-start">{{ user.name }}</td>
                                    <td class="no-wrap text-start">{{ user.type }}</td>
                                    <td class="no-wrap">
                                        <!-- {{ customer.plan_name }} -->
                                        Trial
                                    </td>
                                    <td class="no-wrap text-start">
                                        <img class="icon-img" src="https://serpplus.pluspromotions.co.uk/images/icon-credit-coin.svg" alt="Icon Credit"> {{ user.credits }} Credits
                                    </td>
                                    <td class="no-wrap">
                                        <router-link class="text-primary">
                                            Open <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg" alt="Open Link Icon" title="Open" />
                                        </router-link>
                                    </td>
                                    <td class="no-wrap">
                                        <div class="action-icon-cont" style="display: flex; justify-content: center;">
                                            <a class="table-action-btn-new bg-yellow-light-two icon-edit" @click="editUser(user)" data-bs-toggle="modal" data-bs-target="#modal-add-user">
                                                <img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-edit-user-manege.svg" alt="Edit">
                                            </a>
                                        </div>
                                    </td>
                                    <td class="no-wrap">
                                        <div class="action-icon-cont" style="display: flex; justify-content: center;">
                                            <a class="table-action-btn-new bg-signal-red-light btn-remove" @click="deleteUser(user)">
                                                <img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- MODAL - ADD USER - START -->
    <div id="modal-add-user" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-924 modal-dialog-centered">
            <div class="modal-content">
                <form action="">
                    <div class="modal-header">
                        <h5 class="modal-title flex-column">
                            <span class="mod-title">User Information</span>
                            <p class="modal-sub-title">Update Your User Information</p>
                            <!-- <div class="info-cont tooltip-trigger">
                                <img class="info-icon" src="images/icon-info-circle.svg" alt="Info">
                                <div class="custom-tooltip">
                                    <div class="tooltip-cont">
                                        <img class="icon" src="images/icon-info-circle-white.svg" alt="Info">
                                        <span class="text">User Information</span>
                                    </div>
                                </div>
                            </div> -->
                        </h5>
                        <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img src="https://serpplus.pluspromotions.co.uk/images/icon-modal-close.svg" alt="Close">
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="modal-form-cont check-serp-form-cont">
                            <div class="row">
                                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>First Name</label>
                                        <input class="form-control" type="text" placeholder="Enter First Name"
                                            v-model="user.firstname" required>
                                    </div>
                                </div>
                                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Last Name</label>
                                        <input class="form-control" type="text" placeholder="Enter Last Name"
                                            v-model="user.lastname" required>
                                    </div>
                                </div>
                                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input class="form-control" type="email" placeholder="Enter Email"
                                            v-model="user.email" required>
                                    </div>
                                </div>
                                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Password</label>
                                        <input class="form-control" type="email" value="password" disabled>

                                    </div>
                                </div>
                                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Contact</label>
                                        <input class="form-control" type="text" v-model="user.contact"
                                            placeholder="Enter Contact" required>
                                    </div>
                                </div>
                                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Languages</label>
                                        <select class="form-control" v-model="user.language">
                                            <option>Select Language</option>
                                            <option v-for="language in languages" :key="language.code"
                                                :value="language.code">
                                                {{ language.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Country</label>
                                        <select class="form-control" v-model="user.country">
                                            <option>Select a Country</option>
                                            <option v-for="country in regionList" :key="country.location_code"
                                                :value="country.location_code">
                                                {{ country.location_name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Role</label>
                                        <select class="form-control" v-model="user.role">
                                            <option>Select a Country</option>
                                            <option v-for="role in roles" :key="role.id" :value="role.id">
                                                {{ role.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Change Trial To Paid</label>
                                        <select class="form-control" v-model="user.type">
                                            <option value="Free">Trial</option>
                                            <option value="Paid">Paid</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-col col-xs-12 col-md-6 col-lg-6">
                                    <div class="form-group">
                                        <label>Credits</label>
                                        <input class="form-control" v-model.number="user.credits" type="text"
                                            placeholder="Enter Credits" required>

                                    </div>
                                </div>
                            </div>
                            <p class="fs-16 text-light-new mt-5px mb-25">Please click save before close the window</p>
                        </div>
                    </div>
                    <div class="modal-footer col-gap-20 row-gap-10">
                        <!-- <Button class="admin-common-btn btn-bordered btn btn-reset" type="reset">Reset</Button> -->
                        <Button class="admin-common-btn btn-light-red btn-reset" type="reset">Reset</Button>
                        <Button class="admin-common-btn btn btn-primary btn-save" type="button" data-bs-dismiss="modal"
                            @click="addOrUpdateUser">
                            {{ isUpdateBtn ? 'Save Changes' : 'Save' }}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- MODAL - ADD USER - END -->

    <!-- <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT
            Number 438499640</p>
    </div> -->

    <CopyRight></copyRight>

</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useAbility } from '@casl/vue';
import CopyRight from '../copyRight.vue';
import axiosIns from '@/axios';
import CountryService from '@/service/CountryService';
const countryService = new CountryService();
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import loader from '../Loader.vue';

const users = ref([]);
const regionList = ref([])
const user = ref({});
const roles = ref([]);
const isUpdateBtn = ref(false);
const searchQuery = ref('');
const loading = ref(false)


const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Spanish' },
    { code: 'fr', name: 'French' },
    { code: 'de', name: 'German' },
    { code: 'ja', name: 'Japanese' },
    { code: 'ko', name: 'Korean' },
    { code: 'zh', name: 'Chinese' }
];

async function fetchUsers() {
    loading.value = true
    try {
        const response = await axiosIns.get('/users');
        loading.value = false
        users.value = response.data.map((user) => ({ ...user, type: user.type === 1 ? 'Free' : 'Paid' }));
    } catch (error) {
        console.error('Error fetching users:', error);
        return [];
    }
}

const addUser = () => {
    user.value = {}
    isUpdateBtn.value = false
}

const convertRolesToUppercase = (roles) => {
    return roles.map(role => ({ ...role, name: role.name.toUpperCase() }));
};
const userId = ref(null)
const editUser = (data) => {
    isUpdateBtn.value = true;
    userId.value = data.id;
    if (data.name !== null) {
        const [firstName, lastName] = data.name.split(' ');
        user.value.firstname = firstName;
        user.value.lastname = lastName;
    } else {
        user.value.firstname = '';
        user.value.lastname = '';
    }

    user.value.email = data.email;
    user.value.contact = data.contact;
    user.value.language = data.language;
    user.value.country = data.country;
    user.value.role = data.roles[0].id;
    user.value.type = data?.type;
    user.value.credits = data.credits;

}


const createUser = async () => {
    try {
        await axiosIns.post('users/create', user.value);

        toast.success('User Created', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });

        setTimeout(async () => {
            await fetchUsers();
        }, 1000);
    } catch (error) {
        console.error('Error creating user:', error.message);
    }
};

const updateUser = async () => {

    const payload = {
        name: (user.value.firstname ? user.value.firstname : '') + (user.value.lastname ? user.value.lastname : ''),
        email: user.value.email,
        password: user.value.password,
        country: user.value.country,
        contact: user.value.contact,
        language: user.value.language,
        role: user.value.role,
        type: user.value.type == 'Free' ? 1 : 2,
        credits: user.value.credits,
    };

    try {
        await axiosIns.put(`/profile/update/${userId.value}`, payload);
        toast.success('User Updated', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
        setTimeout(async () => {
            await fetchUsers();
        }, 1000);
    } catch (error) {
        console.error('Error updating user:', error.message);
    }
};

const addOrUpdateUser = () => {
    if (isUpdateBtn.value) {
        updateUser();
    } else {
        createUser();
    }
};

const deleteUser = async (user) => {
    console.log(user);
    try {
        const confirmed = confirm(`Are you sure you want to delete ${user.name}?`);
        if (confirmed) {
            await axiosIns.delete(`/user/${user.id}`);
            users.value = users.value.filter((u) => u.id !== user.id);
            toast.success(`User ${user.first_name} ${user.name} deleted!`, {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000
            });
        }
    } catch (error) {
        console.error('Error Deleting User:', error);
        toast.error('Failed to delete user. Please try again later.', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
    }
};

onMounted(async () => {
    countryService.getCountries().then((data) => {
        regionList.value = data;
    });

    try {
        const data = await axiosIns.get('roles');
        roles.value = convertRolesToUppercase(data.data.data);
    } catch (error) {
        console.error('Error:', error.message);
    }
    await fetchUsers();

});

watch(searchQuery, (newVal, oldVal) => {
    if (newVal !== oldVal) {
        fetchUsers()
    }
});
</script>