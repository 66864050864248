<template>
    <loader :active="loading" message="Please wait..." />

    <div class="common-page-cont purchase-history-page-cont">
        <div class="common-box">
            <div class="box-top box-top-with-filter-new search-credit-history">
               
            </div>
            <div class="box-body">
                <div class="data-table-cont">
                    <div class="table-responsive table-normal-view table-purchase-history">
                        <table id="example" class="data-table table no-border dataTable no-footer">
                            <thead class="sticky-top">
                                <tr>
                                    <th class="no-wrap">Plan Name</th>
                                    <th class="no-wrap">Credits</th>
                                    <th class="no-wrap">Price</th>
                                    <th class="no-wrap">Purchase Date</th>
                                    <th class="no-wrap">Validity</th>
                                    <th class="no-wrap text-center">Auto Recharge</th>
                                    <th class="no-wrap text-center">Is Cancelled</th>
                                </tr>
                            </thead>
                            <tbody>
  <!-- Show message when purchaseHistory is empty -->
  <tr v-if="purchaseHistory.length === 0 && loading == false">
  <td colspan="8" class="text-center empty-message">
    💃 <strong>No purchases yet!</strong> Please place your order first to see your purchase history.  
    <br />
    <router-link to="/pricing-page" class="btn-gpn animated-link">
      Upgrade Now
    </router-link>
  </td>
</tr>


  <!-- Render rows when purchaseHistory is not empty -->
  <tr v-for="(purchase, index) in purchaseHistory" :key="index">
    <td class="no-wrap text-start" :class="{ 'highlighted-row': purchase.highlighted }">
      {{ purchase.name || 'N/A' }}
      {{ purchase.name !== 'Top Up' && purchase.interval ? ' | ' + purchase.interval : '' }}
    </td>
    <td class="no-wrap text-start" :class="{ 'highlighted-row': purchase.highlighted }">
      <div class="d-flex justify-content-start align-items-center col-gap-5">
        <img class="credits-icon" src="https://serpplus.pluspromotions.co.uk/images/icon-credits.svg" height="20" alt="Credit Icon">
        <span class="credits-text">{{ purchase.credits || 'N/A' }}</span>
      </div>
    </td>
   
    <td class="no-wrap text-start" :class="{ 'highlighted-row': purchase.highlighted }">
      <div class="d-flex justify-content-start align-items-center col-gap-5">
        <img class="projects-icon" src="https://serpplus.pluspromotions.co.uk/images/icon-euro.svg" height="20" alt="Projects Icon">
        <span class="projects-text">{{ purchase.price ? '$' + purchase.price : 'N/A' }}</span>
      </div>
    </td>
    <td class="no-wrap text-start" :class="{ 'highlighted-row': purchase.highlighted }">{{ purchase.purchase_date || 'N/A' }}</td>
    <td class="no-wrap text-start" :class="{ 'highlighted-row': purchase.highlighted }">{{ purchase.expire_date || 'N/A' }}</td>
    <td class="no-wrap" :class="{ 'highlighted-row': purchase.highlighted }">
      <div class="status-cont d-flex justify-content-center align-items-center col-gap-5 active">
        <span class="text">Coming Soon</span>
      </div>
    </td>
    <td class="no-wrap" :class="{ 'highlighted-row': purchase.highlighted }">
      <div class="d-flex justify-content-center align-items-center col-gap-5 active">
        <span class="text">
          {{ purchase.is_cancelled === 1 ? '✅ Cancelled' : '❌ Not Cancelled' || '❓ N/A' }}
        </span>
      </div>
    </td>
  </tr>
</tbody>

                        </table>
                        <div class="notes-section mt-4" v-if="purchaseHistory.length > 0">
                        <div class="note" style="border-left: 4px solid #8ad8ff; padding: 10px 15px; margin-bottom: 10px; background-color: #f0faff; border-radius: 5px;">
                            <span class="note-icon" style="color: #8ad8ff; font-size: 18px;">&#128309;</span> 
                            <strong>Blue Line:</strong> The <strong>blue line</strong> indicates your current active subscription. It will remain visible until your plan expires. If you cancel your plan but still have remaining days, the subscription will stay active for the remaining time.
                        </div>
                        <div class="note" style="border-left: 4px solid #ff7b7b; padding: 10px 15px; background-color: #fff5f5; border-radius: 5px;">
                            <span class="note-icon" style="color: #ff7b7b; font-size: 18px;">&#9888;</span> 
                            <strong>Important:</strong> If you cancel any subscription, it will also cancel your past purchases. However, this will not affect your future purchases. Thank you, <strong>Team Serp Plus</strong>.
                        </div>
                    </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <copyRight></copyRight>

    <!-- MODAL - ADD Credits - START -->
    <div id="modal-add-credits" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-490 modal-dialog-centered">
            <div class="modal-content">
                <form action="">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <span class="mod-title">Add Credits</span>

                        </h5>
                        <!-- <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button> -->
                        <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img src="images/icon-popup-close.svg" alt="Close">
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="modal-form-cont check-serp-form-cont">
                            <div class="form-group">
                                <label for="creditAmount">Amount</label>
                                <select class="form-control" id="creditAmount" v-model="selectedAmount"
                                    @change="updateSelectedCredits">
                                    <option v-for="(option, index) in creditOptions" :key="index" :value="option.value">
                                        {{ option.label }}
                                    </option>
                                </select>
                                <div class="selected-credits" v-if="selectedCredits">{{ selectedCredits }}</div>

                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="admin-common-btn btn btn-primary btn-next" @click="buyNow()" type="button"
                            data-bs-dismiss="modal">
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- MODAL - ADD Credits - END -->


</template>

<script>
import 'vue3-toastify/dist/index.css';
import axiosIns from '@/axios';
import Loader from '../Loader.vue';
import copyRight from '../copyRight.vue';
import 'vue3-toastify/dist/index.css';

export default {
    components: {
        Loader,
        copyRight
    },
    data() {
        return {
            purchaseHistory: [],
            creditOptions: [],
            selectedCredits: '',
            selectedAmount: '',
            userData: [],
            plan: [],
            userType: '',
            loading: false,
        };
    },
    beforeMount() {
        this.loadPurchaseHistory();
        this.loadAmmount();
        const data = localStorage.getItem('userData');
        this.userData = JSON.parse(data);
    },
    methods: {
        async loadAmmount() {
            try {
                const response = await axiosIns.get('/plans/get-amount');
                this.creditOptions = response.data.data;

                this.creditOptions.sort((a, b) => a.value - b.value);

            } catch (error) {
                console.error('Error generating payment intent:', error);
            }
        },
        async fetchCustomerPlan() {
            this.loading = true;
            try {
                const response = await axiosIns.get('/plans/list-customer-plans');
                const customerPlan = response.data.regular_subscriptions.concat(response.data.top_up_subscriptions);
                this.loading = false;

                // Sort plans by purchase date in descending order
                customerPlan.sort((a, b) => {
                    const dateA = new Date(a.purchase_date);
                    const dateB = new Date(b.purchase_date);
                    return dateB - dateA;
                });

                return customerPlan;
            } catch (error) {
                console.error('Error fetching plans:', error);
                return [];
            }
        },

        async generatePaymentIntent() {
            this.loading = true;
            try {
                const response = await axiosIns.post('/get-checkout-url', { credits: this.selectedAmount * 100, title: this.creditsShow });
                const checkoutUrl = response.data.url;
                this.loading = false;
                window.open(checkoutUrl, '_blank');
            } catch (error) {
                console.error('Error generating payment intent:', error);
            }
        },

        buyNow() {
            if (!this.selectedAmount) {
                alert('Please select an amount.');
                return;
            }
            this.generatePaymentIntent();
        },
        isExpired(expireDate) {
            if (!expireDate) return true; // Treat as expired if expireDate is not provided
            const now = new Date();
            const expire = new Date(expireDate);
            return now > expire;
        },
        async loadPurchaseHistory() {
            this.loading = true

            if (JSON.parse(localStorage.getItem('userData'))?.type === 2) {
                this.purchaseHistory = await this.fetchCustomerPlan();

                const matchedEntries = this.purchaseHistory.filter(purchase => purchase.name === JSON.parse(localStorage.getItem('plan'))?.name);

                let latestValidEntry;
                let latestValidIndex = -1;

                matchedEntries.forEach((entry, index) => {
                    if (!this.isExpired(entry.expire_date) && (!latestValidEntry || entry.created_at > latestValidEntry.created_at)) {
                        latestValidEntry = entry;
                        latestValidIndex = index;
                    }
                });

                // Set highlighted to true for the latest valid entry
                if (latestValidEntry) {
                    matchedEntries[latestValidIndex].highlighted = true;
                }

                console.log(latestValidEntry);
            } else {
                this.purchaseHistory = await this.fetchCustomerPlan();
            }
            //EventBus.$emit('refreshPage', true);

        },

        async cancelPlan(sub_id) {
            if (!confirm("Are you sure you want to cancel this subscription?")) {
                return;
            }
            this.loading = true;
            try {
                const data = await axiosIns.post(`/plans/cancel-subscription/${sub_id}`);
                localStorage.setItem('userData', JSON.stringify(data.data.userData));
                this.loading = false;
                toast.success("Subscription cancelled successfully. Please check your email for further instructions.", {
                    position: toast.POSITION.TOP_RIGHT,
                    duration: 6000
                });
                location.reload();
            } catch (error) {
                console.error('Error cancelling subscription:', error);
                clearInterval(intervalId); // Clear interval in case of error
                toast.error("An error occurred while cancelling the subscription. Please try again later.");
            }
        },
        async updateSelectedCredits(event) {
            const selectedValue = event.target.value;
            const selectedOption = this.creditOptions.find(option => option.value === parseInt(selectedValue));
            this.selectedCredits = selectedOption ? `Included ${selectedOption.credits} credits` : '';
        },
    },

};


</script>

<style scoped>
.selected-credits {
    padding: 10px;
    border-radius: 5px;
    background-color: #f0f0f0;
    margin-top: 10px;
    display: inline-block;
    font-size: 14px;
}

.form-check-input {
    background-color: #007bff !important;
    /* Change to your desired color for unchecked state */
}

/* Style for checked switch */
.form-check-input:checked {
    background-color: #007bff !important;
    /* Change to your desired color for checked state */
}

.highlighted-row {
    background-color: #8ad8ff !important;
    /* Set the background color for the highlighted row */
}

.btn[disabled] {
    display: none;
    /* Hide disabled buttons */
}

.manage-plan {
    cursor: pointer;
    /* Show pointer cursor on hover */
    text-decoration: none;
    /* Remove default underline */
}

.manage-plan:hover {
    text-decoration: underline;
    /* Underline text on hover */
}

.empty-message {
  font-size: 1.2em;
  color: #2E3771;
  font-weight: bold;
  padding: 20px 0;
}

.btn-gpn {
  display: inline-block;
  margin-top: 15px;
  padding: 10px 20px;
  font-size: 1em;
  color: white;
  background-color: #2E3771;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.btn-gpn:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(46, 55, 113, 0.4);
}

.animated-link {
  animation: pulse 1.5s infinite;
}



</style>