<template>

    <loader :active="loading" message="Please wait..." />



    <div class="common-inner-body manage-roles-page-cont">
        <div class="common-inner-cont">
            <div class="common-box">

                <div class="box-top box-top-with-filter-new justify-content-between">
                    <div class="table-top-left-info d-flex flex-wrap align-items-center row-gap-3">
                        <a class="btn btn-light admin-common-btn me-4" @click="addRole" data-bs-toggle="modal" data-bs-target="#modal-add-role">
                            <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-plus.svg" alt="Icon Add Role" /> Add Role
                        </a>
                    </div>
                    <div class="table-top-bar">
                        <!-- <div class="top-left">
                            <div class="form-group search-group">
                                <input class="table-search-input" placeholder="Search Here" v-model="searchQuery" />
                            </div>
                        </div> -->
                        <!-- <div class="top-right">
                            <div class="form-group btn-group">
                                <button class="btn btn-light filter-btn search-btn">Search</button>
                            </div>
                        </div> -->
                    </div>
                </div>

                <div class="data-table-cont">
                    <!-- <div class="table-responsive table-container table-view-serp table-manage-user" @scroll="handleScroll"> -->
                    <div class="table-responsive table-container table-view-serp table-manage-role">
                        <table id="example" class="data-table table table-border-none mb-0">
                            <thead class="sticky-top">
                                <tr>
                                    <th class="no-wrap text-start">Name</th>
                                    <th class="no-wrap width10">Edit Info</th>
                                    <th class="no-wrap width10">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(role, index) in roles" :key="index">
                                    <td class="no-wrap text-start">{{ role.name }}</td>
                                    <td class="no-wrap">
                                        <div class="action-icon-cont d-flex justify-content-center">
                                            <a class="table-action-btn-new bg-yellow-light-two icon-edit" @click="editRole(role)" data-bs-toggle="modal" data-bs-target="#modal-edit-role">
                                                <img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-edit-faq-cat.svg" alt="Edit">
                                            </a>
                                        </div>
                                    </td>
                                    <td class="no-wrap">
                                        <div class="action-icon-cont d-flex justify-content-center">
                                            <a class="table-action-btn-new bg-signal-red-light btn-remove" @click="deleteRole(role)">
                                                <img class="icon-img img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg" alt="Remove Icon" />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <!-- MODAL - ADD USER - START -->
    <div id="modal-add-role" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-490 modal-dialog-centered">
            <div class="modal-content">
                <form action="">
                    <div class="modal-header">
                        <h5 class="modal-title flex-column">
                            <span class="mod-title">Add Role</span>
                            <p class="modal-sub-title">Update Your Information</p>
                            <!-- <div class="info-cont tooltip-trigger">
                                <img class="info-icon" src="https://serpplus.pluspromotions.co.uk/images/icon-info-circle.svg" alt="Info">
                                <div class="custom-tooltip">
                                    <div class="tooltip-cont">
                                        <img class="icon" src="https://serpplus.pluspromotions.co.uk/images/icon-info-circle-white.svg" alt="Info">
                                        <span class="text">Add Role</span>
                                    </div>
                                </div>
                            </div> -->
                        </h5>
                        <!-- <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button> -->
                        <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img src="https://serpplus.pluspromotions.co.uk/images/icon-modal-close.svg" alt="Close">
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="modal-form-cont check-serp-form-cont">
                            <div class="form-group">
                                <label>Role</label>
                                <input class="form-control" type="text" placeholder="Enter Role Name"
                                    v-model="role.name" required>
                                <!-- <span class="required-alert">Enter First Name is required.</span> -->
                            </div>
                            <p class="fs-16 text-light-new mt-5px mb-25">Please click save before close the window</p>
                        </div>
                    </div>
                    <div class="modal-footer col-gap-20 row-gap-10">
                        <Button class="admin-common-btn btn-light-red btn-reset" type="reset">Reset</Button>
                        <button class="admin-common-btn btn btn-primary btn-add-role" type="button" data-bs-dismiss="modal"
                            @click="createRole">
                            Add Role
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- MODAL - ADD USER - END -->

    <div id="modal-edit-role" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-1256 modal-dialog-centered">
            <div class="modal-content">
                <form action="">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <span class="mod-title">Edit Role</span>

                        </h5>
                        <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <img src="https://serpplus.pluspromotions.co.uk/images/icon-popup-close.svg" alt="Close">
                        </a>
                    </div>
                    <div class="modal-body">
                        <div class="modal-form-cont check-serp-form-cont">
                            <div class="row">
                                <div class="form-col col-xs-12 col-md-6 col-lg-4">
                                    <div class="form-group">
                                        <label>Role</label>
                                        <input class="form-control" type="text" v-model="role.name">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-col col-xs-12 col-sm-6 col-md-3 col-lg-3"
                                    v-for="section in permissionSections" :key="section[0].description">
                                    <div class="form-group custom-check-group">
                                        <label>
                                            {{ section[0].description }}
                                        </label>
                                        <div class="form-check custom-form-check" v-for="permission in section"
                                            :key="permission.id">
                                            <input id="check-serp-module1" class="form-check-input" type="checkbox"
                                                :id="permission.id" :value="permission.id" v-model="permission.checked">
                                            <label class="form-check-label" :for="permission.id"
                                                style="font-size: small;">{{ permission.name }}</label>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <Button class="admin-common-btn btn-bordered btn btn-cancel" type="button"
                            data-bs-dismiss="modal" aria-label="Close">Cancel</Button>
                        <Button class="admin-common-btn btn btn-primary btn-update" data-bs-dismiss="modal"
                            @click="updateRole">Update</Button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- <div class="copyright-cont">
        <p class="copyright-text">&copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number 13871077, VAT Number 438499640</p>
    </div> -->

    <CopyRight></copyRight>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useAbility } from '@casl/vue';
import CopyRight from '../copyRight.vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import axiosIns from '@/axios';
import loader from '../Loader.vue';


const loading = ref(false)
const { can } = useAbility();
const roles = ref([]);
const isUpdateBtn = ref(false);
const role = ref({});

async function fetchRoles() {
    loading.value = true
    try {
        const response = await axiosIns.get('/roles');
        loading.value = false
        roles.value = response.data.data;
    } catch (error) {
        console.error('Error fetching roles:', error);
    }
}

const addRole = () => {
    role.value = {}
    isUpdateBtn.value = false
}

const roleId = ref(null)
const availablePermissions = ref([]);
const currentPermissions = ref([]);
const permissionSections = ref([]);

const editRole = async (data) => {
    roleId.value = data.id;
    role.value.name = data.name

    const response = await axiosIns.get('/permissions/get-permissions');
    permissionSections.value = response.data;
    const rolePermissionResponse = await axiosIns.get('/role-permissions/' + roleId.value);
    const rolePermissionList = rolePermissionResponse.data;

    const diffPermission = getDifference(permissionSections.value, rolePermissionList);

    for (const moduleName in diffPermission) {
        if (diffPermission.hasOwnProperty(moduleName)) {
            diffPermission[moduleName].forEach(permission => {
                permission.checked = false;
            });
        }
    }

    for (const moduleName in rolePermissionList) {
        if (rolePermissionList.hasOwnProperty(moduleName)) {
            rolePermissionList[moduleName].forEach(permission => {
                permission.checked = true;
            });
        }
    }

    for (const moduleName in permissionSections.value) {
        if (permissionSections.value.hasOwnProperty(moduleName)) {
            permissionSections.value[moduleName].forEach(permission => {
                const foundDiff = diffPermission[moduleName] && diffPermission[moduleName].some(diffPermission => diffPermission.id === permission.id);
                const foundCurrent = rolePermissionList[moduleName] && rolePermissionList[moduleName].some(currentPermission => currentPermission.id === permission.id);

                if (foundDiff) {
                    permission.checked = false;
                } else if (foundCurrent) {
                    permission.checked = true;
                }
            });
        }
    }


    availablePermissions.value = diffPermission;
    currentPermissions.value = rolePermissionList;

}

function getDifference(array1, array2) {
    const result = {};

    for (const module in array1) {
        if (array2.hasOwnProperty(module)) {
            result[module] = array1[module].filter(obj1 =>
                !array2[module].some(obj2 => obj1.id === obj2.id)
            ).concat(
                array2[module].filter(obj2 =>
                    !array1[module].some(obj1 => obj2.id === obj1.id)
                )
            );
        } else {
            result[module] = array1[module];
        }
    }

    for (const module in array2) {
        if (!array1.hasOwnProperty(module)) {
            result[module] = array2[module];
        }
    }

    return result;
}

const updateRolePermissions = async (permissions) => {

    try {
        await axiosIns.put('/role-permissions', { permissions: JSON.stringify(permissions), role_id: roleId.value })
    } catch (error) {
        console.error('Error:', error.message);
    }

}

const createRole = async () => {

    try {
        await axiosIns.post('roles', { name: role.value.name });

        toast.success('Role Created', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });

        setTimeout(async () => {
            await fetchRoles();
        }, 1000);
    } catch (error) {
        console.error('Error creating role:', error.message);
    }
};

const updateRole = async () => {
    try {
        await axiosIns.put(`/roles/${roleId.value}`, { name: role.value.name });
        let checkedPermissionIds = [];

        for (const moduleName in permissionSections.value) {
            if (permissionSections.value.hasOwnProperty(moduleName)) {
                permissionSections.value[moduleName].forEach(permission => {
                    if (permission.checked) {
                        checkedPermissionIds.push(permission.id);
                    }
                });
            }
        }

        updateRolePermissions(checkedPermissionIds);

        toast.success('Role Updated', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
        setTimeout(async () => {
            await fetchRoles();
        }, 1000);
    } catch (error) {
        console.error('Error updating role:', error.message);
    }
};



const deleteRole = async (role) => {
    try {
        const confirmed = confirm(`Are you sure you want to delete Role?`);
        if (confirmed) {
            await axiosIns.delete(`/roles/${role.id}`);
            roles.value = roles.value.filter((u) => u.id !== role.id);
            toast.success(`Role deleted!`, {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000
            });
        }
    } catch (error) {
        console.error('Error Deleting Role:', error);
        toast.error('Failed to delete role. Please try again later.', {
            position: toast.POSITION.TOP_RIGHT,
            duration: 6000
        });
    }
};

onMounted(async () => {
    try {
        loading.value = true
        const data = await axiosIns.get('roles');
        loading.value = false
        roles.value = data.data.data;
    } catch (error) {
        console.error('Error:', error.message);
    }

});
</script>

<style scoped></style>