<template>
  <Loader :active="loading" message="Please wait..." />
  <div class="common-inner-body ranking-keyword-page-cont">
    <div class="common-inner-cont">
      <div class="ranking-keyword-page-top">
        <div class="top-title-buttons">
          <h2 class="top-title">
            <img class="icon-title" src="https://serpplus.pluspromotions.co.uk/images/icon-chart-title.svg"
              alt="Icon Chart" />
            <span class="title-text"><strong>Overview: </strong>{{ domain }}</span>
            <img class="img-fluid icon-tooltip"
              src="https://serpplus.pluspromotions.co.uk/images/icon-question-circle.svg" alt="Question Icon"
              title="Domain" />
          </h2>
          <div class="right-btn-cont">
            <a class="btn-save" 
   :class="{ 'disabled': isTrial }" 
   :disabled="isProjectCreated || isTrial"
   @click="handleSaveClick">
  <img class="btn-icon" src="https://serpplus.pluspromotions.co.uk/images/icon-save-new.svg" alt="Icon Save" />
  {{ loading ? "Saving..." : isProjectCreated ? "Saved" : "Save" }}
</a>

          </div>
        </div>
        <div class="rank-graph-box-cont">
          <div class="row graph-box-row">
            <div class="col-12 col-md-12 col-lg-6 col-xxl-3 graph-box-col">
              <div class="graph-box box-one">
                <div class="box-left">
                  <h2 class="box-count">{{ counts["organic"]?.count }}</h2>
                  <p class="box-title">Ranking Keyword</p>
                </div>
                <div class="box-right">
                  <div class="right-icon-box">
                    <img class="img-fluid box-icon"
                      src="https://serpplus.pluspromotions.co.uk/images/icon-ranking-keyword.svg"
                      alt="Icon Ranking Keyword" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-6 col-xxl-3 graph-box-col">
              <div class="graph-box box-two">
                <div class="box-left">
                  <h2 class="box-count">
                    {{ counts["organic"]?.estimated_paid_traffic_cost }}
                  </h2>
                  <p class="box-title">Traffic Per month</p>
                </div>
                <div class="box-right">
                  <div class="right-icon-box">
                    <img class="img-fluid box-icon"
                      src="https://serpplus.pluspromotions.co.uk/images/icon-traffic-per-month.svg"
                      alt="Icon Traffic Per month" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-6 col-xxl-3 graph-box-col">
              <div class="graph-box box-three">
                <div class="box-left">
                  <h2 class="box-count">${{ counts["organic"]?.etv }}</h2>
                  <p class="box-title">Organic Traffic</p>
                </div>
                <div class="box-right">
                  <div class="right-icon-box">
                    <img class="img-fluid box-icon"
                      src="https://serpplus.pluspromotions.co.uk/images/icon-organic-traffic.svg"
                      alt="Icon Organic Traffic" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-6 col-xxl-3 graph-box-col">
              <div class="graph-box box-four">
                <div class="box-left">
                  <h2 class="box-count">${{ counts["organic"]?.etv }}</h2>
                  <p class="box-title">Paid Traffic</p>
                </div>
                <div class="box-right">
                  <div class="right-icon-box">
                    <img class="img-fluid box-icon"
                      src="https://serpplus.pluspromotions.co.uk/images/icon-paid-traffic.svg"
                      alt="Icon Paid Traffic" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="common-box organic-vs-paid">
        <div class="box-title">
          <h3 class="text">
            Traffic Distribution by Keywords and Page Range (Organic vs Paid)
          </h3>
          <!-- <div class="box-filter-cont">
            <div class="form-check-group-inline">
              <div class="form-group">
                <div class="custom-form-check-new form-check-inline">
                  <input class="form-check-input check-blue" type="checkbox" checked>
                  <label class="form-check-label">All</label>
                </div>
              </div>
              <div class="form-group">
                <div class="custom-form-check-new form-check-inline">
                  <input class="form-check-input check-green" type="checkbox" checked>
                  <label class="form-check-label">Organic</label>
                </div>
              </div>
              <div class="form-group">
                <div class="custom-form-check-new form-check-inline">
                  <input class="form-check-input check-red" type="checkbox" checked>
                  <label class="form-check-label">Paid</label>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="chart-container">
          <apexchart type="area" height="358px" width="100%" :options="chartOptionsArea" :series="seriesArea" />
        </div>
      </div>

      <div class="common-box number-keyword-by-position m-0 mb-4" style="display: none">
        <div class="row row-nkbp">
          <div class="col-xs-12 col-md-7 col-lg-7 col-nkbp col-left">
            <div class="nkbp-box">
              <div class="box-top">
                <!-- <h3 class="box-title">Organic Keywords Trend 📈</h3> -->
                <h3 class="box-title">
                  Number of keyword by position <span class="number">29</span>
                </h3>
              </div>
              <div class="box-body">
                <div class="data-table-cont">
                  <div class="table-responsive table-normal-view">
                    <table id="example3" class="data-table table table-competitor-view dataTable no-footer">
                      <thead>
                        <tr>
                          <th class="no-wrap">Page Traffic</th>
                          <th class="no-wrap">Pages</th>
                          <th class="no-wrap">Pages’ Total Traffic</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-md-5 col-lg-5 col-nkbp col-right"></div>
        </div>
      </div>

      <div class="common-box keywords-table-cont backlink-table-cont mb-0">
        <div class="keywords-box backlink-table-box">
          <!-- <div class="common-box keywords-table-cont">
            <div class="keywords-box"> -->
          <div class="box-top box-top-with-filter-new justify-content-between">
            <h4 class="box-title">
              Organic Search Positions Analysis
              <img class="img-fluid th-icon"
                src="https://serpplus.pluspromotions.co.uk/images/icon-question-circle-small.svg" alt="Question Icon"
                title="Backlinks Analysis" style="cursor: pointer" />
            </h4>
            <div class="table-top-bar">
              <div class="top-left">
                <div class="form-group search-group">
                  <input v-model="searchQuery" class="table-search-input" @input="searchFunction"
                    placeholder="keywords Search" />
                </div>
              </div>
              <div class="top-right">
                <div class="form-group btn-group d-inline-block">
                  <label class="d-block text-center mb-1">Last date</label>
                  <Datepicker class="btn btn-light filter-btn active" :highlighted="highlighted"
                    :disabled-dates="disabledDates" v-model="selectedDate" @input="dateChange"></Datepicker>
                </div>
                <div class="form-group btn-group d-inline-block">
                  <label class="d-block text-center mb-1">Compare Date</label>
                  <Datepicker class="btn btn-light filter-btn active" :highlighted="highlighted"
                    :disabled-dates="disabledDates" v-model="compareDate" @input="dateCompare"></Datepicker>
                </div>
                <div class="form-group btn-group">
                  <button class="btn btn-light filter-btn export-btn" @click="exportExcel">
                    <img class="btn-icon img-fluid"
                      src="https://serpplus.pluspromotions.co.uk/images/icon-excel-export.svg"
                      alt="Icon Excel Export" />Export
                  </button>
                </div>
                <div class="form-group btn-group d-inline-block">
                  <label class="d-block text-center mb-1">Refresh</label>
                  <button class="btn btn-light filter-btn active" @click="handleRefresh">
                    🔄
                  </button>
                </div>

              </div>
            </div>
          </div>
          <div class="box-body">
            <div class="data-table-cont">
              <div class="table-responsive table-container table-normal-view table-backlink table-backlinks-analysis"
                @scroll="handleScroll">
                <table id="example3" class="data-table table table-competitor-view dataTable no-footer">
                  <thead class="sticky-top">
                    <tr style="cursor: pointer">
                      <th class="no-wrap@@@@ ptb-19" @click="sortBy('keyword')"
                        :class="{ sorted: sortByColumn === 'keyword' }" title="Click to sort by Keywords">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt">Keywords</span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up" :class="{
                                active:
                                  sortDirection === 'asc' &&
                                  sortByColumn === 'keyword',
                              }" @click.stop="sortBy('keyword', 'asc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow" />
                              <img class="icon-arrow arrow-down" :class="{
                                active:
                                  sortDirection === 'desc' &&
                                  sortByColumn === 'keyword',
                              }" @click.stop="sortBy('keyword', 'desc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow" />
                            </span>
                          </div>
                        </div>
                      </th>

                      <th class="no-wrap">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt">Engines</span>
                          </div>
                        </div>
                      </th>
                      <th class="no-wrap">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt">Location</span>
                          </div>
                        </div>
                      </th>
                      <th class="no-wrap" @click="sortBy('position')" :class="{ sorted: sortByColumn === 'position' }"
                        title="Click to sort by Position">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt">Position</span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up" :class="{
                                active:
                                  sortDirection === 'asc' &&
                                  sortByColumn === 'position',
                              }" @click.stop="sortBy('position', 'asc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow" />
                              <img class="icon-arrow arrow-down" :class="{
                                active:
                                  sortDirection === 'desc' &&
                                  sortByColumn === 'position',
                              }" @click.stop="sortBy('position', 'desc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow" />
                            </span>
                          </div>
                        </div>
                      </th>

                      <th class="no-wrap" @click="sortBy('lp')" :class="{ sorted: sortByColumn === 'lp' }"
                        title="Click to sort by LP">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt">LP</span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up" :class="{
                                active:
                                  sortDirection === 'asc' &&
                                  sortByColumn === 'lp',
                              }" @click.stop="sortBy('lp', 'asc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow" />
                              <img class="icon-arrow arrow-down" :class="{
                                active:
                                  sortDirection === 'desc' &&
                                  sortByColumn === 'lp',
                              }" @click.stop="sortBy('lp', 'desc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow" />
                            </span>
                          </div>
                        </div>
                      </th>

                      <th class="no-wrap" @click="sortBy('volume')" :class="{ sorted: sortByColumn === 'volume' }"
                        title="Click to sort by Volume">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt">Volume</span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up" :class="{
                                active:
                                  sortDirection === 'asc' &&
                                  sortByColumn === 'volume',
                              }" @click.stop="sortBy('volume', 'asc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow" />
                              <img class="icon-arrow arrow-down" :class="{
                                active:
                                  sortDirection === 'desc' &&
                                  sortByColumn === 'volume',
                              }" @click.stop="sortBy('volume', 'desc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow" />
                            </span>
                          </div>
                        </div>
                      </th>

                      <th class="no-wrap" @click="sortBy('kd')" :class="{ sorted: sortByColumn === 'kd' }"
                        title="Click to sort by KD">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt">KD</span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up" :class="{
                                active:
                                  sortDirection === 'asc' &&
                                  sortByColumn === 'kd',
                              }" @click.stop="sortBy('kd', 'asc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow" />
                              <img class="icon-arrow arrow-down" :class="{
                                active:
                                  sortDirection === 'desc' &&
                                  sortByColumn === 'kd',
                              }" @click.stop="sortBy('kd', 'desc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow" />
                            </span>
                          </div>
                        </div>
                      </th>

                      <th class="no-wrap" @click="sortBy('cpc')" :class="{ sorted: sortByColumn === 'cpc' }"
                        title="Click to sort by CPC">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt">CPC</span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up" :class="{
                                active:
                                  sortDirection === 'asc' &&
                                  sortByColumn === 'cpc',
                              }" @click.stop="sortBy('cpc', 'asc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow" />
                              <img class="icon-arrow arrow-down" :class="{
                                active:
                                  sortDirection === 'desc' &&
                                  sortByColumn === 'cpc',
                              }" @click.stop="sortBy('cpc', 'desc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow" />
                            </span>
                          </div>
                        </div>
                      </th>

                      <th class="no-wrap" @click="sortBy('last_updated')"
                        :class="{ sorted: sortByColumn === 'last_updated' }" title="Click to sort by Last Updated">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt">Last Updated</span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up" :class="{
                                active:
                                  sortDirection === 'asc' &&
                                  sortByColumn === 'last_updated',
                              }" @click.stop="sortBy('last_updated', 'asc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow" />
                              <img class="icon-arrow arrow-down" :class="{
                                active:
                                  sortDirection === 'desc' &&
                                  sortByColumn === 'last_updated',
                              }" @click.stop="sortBy('last_updated', 'desc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow" />
                            </span>
                          </div>
                        </div>
                      </th>

                      <th class="no-wrap" @click="sortBy('created')" :class="{ sorted: sortByColumn === 'created' }"
                        title="Click to sort by Created">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt">Created</span>
                            <span class="sort-arrow">
                              <img class="icon-arrow arrow-up" :class="{
                                active:
                                  sortDirection === 'asc' &&
                                  sortByColumn === 'created',
                              }" @click.stop="sortBy('created', 'asc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow" />
                              <img class="icon-arrow arrow-down" :class="{
                                active:
                                  sortDirection === 'desc' &&
                                  sortByColumn === 'created',
                              }" @click.stop="sortBy('created', 'desc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow" />
                            </span>
                          </div>
                        </div>
                      </th>

                      <th class="no-wrap" @click="sortBy('page_link')" :class="{ sorted: sortByColumn === 'page_link' }"
                        title="Click to sort by Page Link">
                        <div class="th-short-box">
                          <div class="txt-icon-cont">
                            <span class="th-txt">Page Link</span>
                            <!-- <span class="sort-arrow">
                              <img class="icon-arrow arrow-up"
                                :class="{ 'active': sortDirection === 'asc' && sortByColumn === 'page_link' }"
                                @click.stop="sortBy('page_link', 'asc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-up-arrow.svg"
                                alt="Up Arrow">
                              <img class="icon-arrow arrow-down"
                                :class="{ 'active': sortDirection === 'desc' && sortByColumn === 'page_link' }"
                                @click.stop="sortBy('page_link', 'desc')"
                                src="https://serpplus.pluspromotions.co.uk/images/table-shorting-down-arrow.svg"
                                alt="Down Arrow">
                            </span> -->
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <template v-for="(keyword, index) in visibleKeywords">
                      <tr class="no-wrap@@@@">
                        <td class="align-left">
                          <div class="d-flex">
                            <span v-if="!isTrial" @click.stop="toggleItem(index, keyword)" style="cursor: pointer">
                              <span class="btn-serps no-wrap">SERPs <img class="icon-arrow" src="https://serpplus.pluspromotions.co.uk/images/icon-btn-serps-arrow.svg" alt="Arrow Right" /></span>
                            </span>
                            <div class="tooltip-container" v-else>
                              <Button class="serps-button" @click.stop="toggleItem(index, keyword)">
                                📈 SERPs
                              </Button>
                              <span class="tooltip-text">This action will take one credit</span>
                            </div>
                            <span>{{ keyword.keyword }}</span>
                          </div>
                        </td>
                        <td class="align-center no-wrap">{{ keyword.sf ?? "-" }}</td>
                        <td class="align-center no-wrap">
                          {{ keyword.location_name ?? "-" }}
                        </td>
                        <td class="align-center no-wrap" style="
                            font-size: larger;
                            color: black;
                            font-weight: 500;
                          ">
                          {{ keyword.position ?? "-" }}
                          <span v-if="keyword.position_change" :class="getChangeClass(keyword.position_change)"
                            class="small-text" :title="'Difference: ' +
                              formatChange(keyword.position_change) +
                              ', Date: ' +
                              keyword.date
                              ">
                            {{ formatChange(keyword.position_change) }}
                          </span>
                        </td>
                        <td class="align-center no-wrap" style="
                            font-size: larger;
                            color: black;
                            font-weight: 500;
                          ">
                          {{ keyword.lp ?? "-" }}
                        </td>
                        <td class="align-center no-wrap">
                          {{ keyword.traffic ?? "-" }}
                        </td>
                        <td class="align-center no-wrap">
                          <span v-if="keyword.kd != null" class="kd-value"
                            :style="{ color: getColorForKD(keyword.kd) }">{{ keyword.kd }}</span>

                          <span v-if="keyword.kd_change" :class="getChangeClass(keyword.kd_change)" class="small-text"
                            :title="'Difference: ' +
                              formatChange(keyword.kd_change) +
                              ', Date: ' +
                              keyword.date
                              ">
                            {{ formatChange(keyword.kd_change) }}
                          </span>
                        </td>
                        <td class="align-center no-wrap">
                          <span class="d-flex">
                            <template v-if="
                              keyword.cpc !== undefined &&
                              keyword.cpc !== null &&
                              keyword.cpc > 0
                            ">
                              <img class="me-1" src="https://serpplus.pluspromotions.co.uk/images/icon-dollar.svg"
                                alt="Icon Dollar" />
                            </template>
                            {{
                              keyword.cpc !== undefined && keyword.cpc !== null
                                ? parseFloat(keyword.cpc).toFixed(2)
                                : "-"
                            }}

                          </span>
                        </td>

                        <td class="align-center no-wrap">
                          {{ keyword.last_updated ?? "-" }}
                        </td>
                        <td class="align-center no-wrap">
                          <span class="updated-date">
                            {{ keyword.date }}
                          </span>
                        </td>
                        <td class="align-center no-wrap">
                          <a class="url-a" :href="keyword.url" target="_blank" rel="noopener noreferrer">
                            <span class="url-text">{{ keyword.url }}</span>

                            View
                            <img class="me-1" src="https://serpplus.pluspromotions.co.uk/images/icon-link-table.svg"
                              alt="Icon Link" />
                          </a>
                        </td>
                      </tr>
                      <tr v-if="selectedRow === index">
                        <td class="p-tb-0 p-lr-0" :colspan="11">
                          <div class="common-box detail-table-box" v-if="loadiing">
                            <table class="table table-bordered table-hover table-domain-view-details dataTable">
                              <thead>
                                <tr>
                                  <th class="align-center">Page Rank</th>
                                  <th>Page Title</th>
                                  <th>Domain</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="i in 10" :key="i">
                                  <td class="align-center">
                                    <SkeletonLoader width="30px" height="20px" />
                                  </td>
                                  <td>
                                    <div class="highlight-title">
                                      <SkeletonLoader width="200px" height="20px" />
                                    </div>
                                    <div>
                                      <SkeletonLoader width="150px" height="20px" />
                                    </div>
                                  </td>
                                  <td>
                                    <SkeletonLoader width="100px" height="20px" />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="common-box detail-table-box" v-else>
                            <table class="table table-bordered table-hover table-domain-view-details dataTable">
                              <thead>
                                <tr>
                                  <th class="align-center">Page Rank</th>
                                  <th>Page Title</th>
                                  <th>Domain</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="topTen in keyword.top_ten" :key="topTen.rank_absolute">
                                  <td class="align-center">
                                    <span class="d-flex">
                                      <img class="me-2"
                                        src="https://serpplus.pluspromotions.co.uk/images/icon-rank-table.svg"
                                        alt="Icon Link" />
                                      Rank
                                      {{ topTen.rank_absolute }}
                                    </span>
                                  </td>
                                  <td>
                                    <div class="title">{{ topTen.title }}</div>
                                    <div>
                                      <strong>URL:</strong>
                                      <a :href="topTen.url" target="_blank" rel="noopener noreferrer">{{ topTen.url
                                        }}</a>
                                    </div>
                                  </td>
                                  <td>{{ topTen.domain }}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </td>
                        <div v-if="loading" class="loading-spinner">
                          Loading more items...
                        </div>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="copyright-cont">
    <p class="copyright-text">
      &copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number
      13871077, VAT Number 438499640
    </p>
  </div> -->

  <copyRight></copyRight>

  <!-- MODAL - RUN TIME - START -->
  <div id="modal-run-time" class="common-modal-cont modal fade">
    <div class="modal-dialog modal-458 modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="updateRunTime">
          <div class="modal-header">
            <h5 class="modal-title">
              <span class="mod-title">Run Time</span>
            </h5>
            <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Close Circle">
                  <path id="Vector" fill-rule="evenodd" clip-rule="evenodd"
                    d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM12.6516 8.65165C12.9445 8.35876 12.9445 7.88388 12.6516 7.59099C12.3587 7.2981 11.8839 7.2981 11.591 7.59099L9.99998 9.18198L8.40899 7.59099C8.1161 7.2981 7.64123 7.2981 7.34833 7.59099C7.05544 7.88388 7.05544 8.35876 7.34833 8.65165L8.93932 10.2426L7.34833 11.8336C7.05544 12.1265 7.05544 12.6014 7.34833 12.8943C7.64123 13.1872 8.1161 13.1872 8.40899 12.8943L9.99998 11.3033L11.591 12.8943C11.8839 13.1872 12.3587 13.1872 12.6516 12.8943C12.9445 12.6014 12.9445 12.1265 12.6516 11.8336L11.0606 10.2426L12.6516 8.65165Z"
                    fill="#45C9F4" />
                </g>
              </svg>
            </a>
          </div>
          <div class="modal-body">
            <div class="modal-form-cont run-time-form-cont">
              <div class="form-group">
                <label>Duration</label>
                <select class="form-control" v-model="selectedDuration">
                  <option disabled value="">Select Duration</option>
                  <option value="onceInFifteenDays">
                    Once in Fifteen Days
                  </option>
                  <option value="never">Never</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="p-button p-component admin-common-btn btn-bordered btn btn-cancel" type="button"
              @click="cancel">
              Cancel
            </button>
            <button class="admin-common-btn btn btn-primary btn-update mr-0" @click="updateSchedule"
              data-bs-dismiss="modal">
              Update
            </button>
            <div class="mobile-close-modal">
              <a class="btn-close" aria-label="Close">
                <img src="images/icon-popup-close.svg" alt="Close" />
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <!-- MODAL - RUN TIME - END -->
</template>

<script setup>
import { onMounted, ref, watch, computed, onUnmounted } from "vue";
import copyRight from '../copyRight';
import { useRouter } from "vue-router";
import axiosIns from "../../axios.js";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "bootstrap";
import Loader from "../Loader.vue";
import Datepicker from "vuejs3-datepicker";
import "vue3-loading-skeleton/dist/style.css";
import { SkeletonLoader } from "vue3-loading-skeleton";
import EventBus from "@/EventBus";

const loadiing = ref(false);
const router = useRouter();
const keywords = ref([]);
const showOrganic = ref(true);
const showPaid = ref(false);
const currentPage = ref(1);
const lastPage = ref(1);
const chartData = ref({
  labels: [],
  datasets: [],
});
const isProjectCreated = ref(null);
const loading = ref(false);
const totalEntries = ref(0);

const sortByColumn = ref("");
const sortDirection = ref("asc");

const searchQuery = ref("");
const domain = ref("");



const formatChange = (value) => {
  const sign = value < 0 ? "+" : "";
  return `${sign}${Math.abs(value)}`;
};

const getChangeClass = (value) =>
  value < 0 ? "positive-change" : "negative-change";

const chartOptions = ref({
  animationEnabled: true,
  theme: "light2",
  title: {
    text: "",
  },
  axisY: {
    title: "Percentage",
    includeZero: false,
  },
  legend: {
    cursor: "pointer",
    itemclick: (e) => {
      e.dataSeries.visible = !e.dataSeries.visible;
      e.chart.render();
    },
  },
  data: [],
});

const counts = ref([]);
const projectId = router.currentRoute.value.params.id;
const availableDates = ref([]);

const selectedDate = ref(null);
const compareDate = ref(null);
const selectedDuration = ref("");

const selectedRow = ref(null);

const toggleItem = async (index, keyword) => {
  selectedRow.value = selectedRow.value === index ? null : index;
  loadiing.value = true;

  let payload = {
    keyword: keyword.keyword,
    language_code: "en",
    location_code: keyword.location_code,
  };

  try {
    const response = await axiosIns.post(
      `/domain/post-domain-serp/${projectId}`,
      payload
    );
    EventBus.$emit("loadCredits", true);

    const { keyword: resKeyword, top_ten: resTopTen } = response.data;

    const keywordIndex = filteredKeywords.value.findIndex(
      (kw) => kw.keyword === resKeyword
    );
    if (keywordIndex !== -1) {
      filteredKeywords.value[keywordIndex].top_ten = resTopTen;
    }
    loadiing.value = false;
  } catch (error) {
    toast.error(error.response.data.message, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 6000,
    });
  }
};

const dateChange = () => {
  listDomainCollection(router.currentRoute.value.params.id, currentPage.value);
};

const dateCompare = () => {
  listDomainCollection(router.currentRoute.value.params.id, currentPage.value);
};

const allData = ref([]);

const listDomainCollection = async (id, page) => {
  try {
    loading.value = true;

    let formattedDate = formatDate(selectedDate.value);
    let formattedCompareDate = compareDate.value
      ? formatDate(compareDate.value)
      : null;

    const response = await axiosIns.get(`/domain/view-domain/${id}`, {
      params: {
        page: page,
        created_at: formattedDate,
        compare_date: formattedCompareDate,
      },
    });

    availableDates.value = response.data.available_dates;
    allData.value.push(...response.data.data);
    isProjectCreated.value = response.data.is_project_created;
    domain.value = response.data.domain;
    currentPage.value = Number(response.data.pagination.current_page);
    lastPage.value = Number(response.data.pagination.last_page);
    totalEntries.value = Number(response.data.pagination.total);

    selectedDate.value = new Date(response.data.selected_date);
    selectedDuration.value = response.data.schedule;
    allData.value.forEach((keyword) => {
      // if (keyword.date) {
      //   keyword.date = formatDate(keyword.date);
      // }
      // if (keyword.last_updated) {
      //   keyword.last_updated = formatDate(keyword.last_updated);
      // }
      if (keyword.cpc !== undefined && keyword.cpc !== null) {
        keyword.cpc = keyword.cpc.toFixed(2);
      }
    });

    if (allData.value.length === 0) {
      const latestDateAfterFetch =
        availableDates.value && availableDates.value.length > 0
          ? availableDates.value[0]
          : null;

      if (latestDateAfterFetch && !isNaN(new Date(latestDateAfterFetch))) {
        selectedDate.value = latestDateAfterFetch;
        formattedDate = formatDate(selectedDate.value);

        const retryResponse = await axiosIns.get(`/domain/view-domain/${id}`, {
          params: {
            page: page,
            created_at: formattedDate,
            compare_date: formattedCompareDate,
          },
        });

        allData.value.push(...retryResponse.data.data);
        isProjectCreated.value = retryResponse.data.is_project_created;
        selectedDate.value = new Date(retryResponse.data.selected_date);
        selectedDuration.value = retryResponse.data.schedule;
        currentPage.value = Number(retryResponse.data.pagination.current_page);
        lastPage.value = Number(retryResponse.data.pagination.last_page);
        totalEntries.value = Number(retryResponse.data.pagination.total);

        allData.value.forEach((keyword) => {
          // if (keyword.date) {
          //   keyword.date = formatDate(keyword.date);
          // }
          // if (keyword.last_updated) {
          //   keyword.last_updated = formatDate(keyword.last_updated);
          // }
          if (keyword.cpc !== undefined && keyword.cpc !== null) {
            keyword.cpc = keyword.cpc.toFixed(2);
          }
        });

        const organicData = retryResponse.data.tableData.organic;
        const paidData = retryResponse.data.tableData.paid;

        const organicValues = Object.values(organicData).map((item) => ({
          y: parseFloat(item.pages_percentage),
          label: item.range,
        }));
        const paidValues = Object.values(paidData).map((item) => ({
          y: parseFloat(item.pages_percentage),
          label: item.range,
        }));

        chartOptions.value = {
          ...chartOptions.value,
          data: [
            {
              type: "column",
              name: "Organic",
              showInLegend: true,
              dataPoints: organicValues,
              visible: showOrganic.value,
              color: "#45C9F4",
              cornerRadius: 10,
            },
            {
              type: "column",
              name: "Paid",
              showInLegend: true,
              dataPoints: paidValues,
              visible: showPaid.value,
              color: "#FF0000",
              cornerRadius: 10,
            },
          ],
        };

        const tableBody = document.querySelector("#example3 tbody");
        tableBody.innerHTML = "";

        for (const range in organicData) {
          const rowData = organicData[range];

          if (
            parseFloat(rowData.pages_percentage) <= 0 &&
            parseFloat(rowData.traffic_percentage) <= 0
          ) {
            continue;
          }

          const row = document.createElement("tr");
          row.innerHTML = `
                <td class="no-wrap">${range}</td>
                <td class="no-wrap">
                  <div class="td-data">
                    <div class="data">${rowData.count}</div>
                    <div class="progress-cont">
                      <span class="count">${rowData.pages_percentage}%</span>
                      <div class="progress">
                        <div class="progress-bar" role="progressbar" style="width: ${rowData.pages_percentage}%" aria-valuenow="${rowData.pages_percentage}" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="no-wrap">
                <div class="td-data">
                  <div class="data">${rowData.traffic}</div>
                  <div class="progress-cont">
                    <span class="count">${rowData.traffic_percentage}%</span>
                    <div class="progress">
                      <div class="progress-bar bar-green" role="progressbar" style="width: ${rowData.traffic_percentage}%" aria-valuenow="${rowData.traffic_percentage}" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
              </td>       
            `;
          tableBody.appendChild(row);
        }

        counts.value = retryResponse.data.totalCounts;
        loading.value = false;
        sortBy("position");
        return false;
      }
    }

    const organicData = response.data.tableData.organic;
    const paidData = response.data.tableData.paid;

    const organicValues = Object.values(organicData).map((item) => ({
      y: parseFloat(item.pages_percentage),
      label: item.range,
    }));
    const paidValues = Object.values(paidData).map((item) => ({
      y: parseFloat(item.pages_percentage),
      label: item.range,
    }));

    chartOptions.value = {
      ...chartOptions.value,
      data: [
        {
          type: "column",
          name: "Organic",
          showInLegend: true,
          dataPoints: organicValues,
          visible: showOrganic.value,
          color: "#45C9F4",
        },
        {
          type: "column",
          name: "Paid",
          showInLegend: true,
          dataPoints: paidValues,
          visible: showPaid.value,
          color: "#FF0000",
        },
      ],
    };

    const tableBody = document.querySelector("#example3 tbody");
    tableBody.innerHTML = "";

    for (const range in organicData) {
      const rowData = organicData[range];

      if (
        parseFloat(rowData.pages_percentage) <= 0 &&
        parseFloat(rowData.traffic_percentage) <= 0
      ) {
        continue;
      }

      const row = document.createElement("tr");
      row.innerHTML = `
          <td class="no-wrap">${range}</td>
          <td class="no-wrap">
            <div class="td-data">
              <div class="data">${rowData.count}</div>
              <div class="progress-cont">
                <span class="count">${rowData.pages_percentage}%</span>
                <div class="progress">
                  <div class="progress-bar" role="progressbar" style="width: ${rowData.pages_percentage}%" aria-valuenow="${rowData.pages_percentage}" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </td>
          <td class="no-wrap">
            <div class="td-data">
              <div class="data">${rowData.traffic}</div>
              <div class="progress-cont">
                <span class="count">${rowData.traffic_percentage}%</span>
                <div class="progress">
                  <div class="progress-bar bar-green" role="progressbar" style="width: ${rowData.traffic_percentage}%" aria-valuenow="${rowData.traffic_percentage}" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
          </td>
        `;
      tableBody.appendChild(row);
    }

    counts.value = response.data.totalCounts;
    sortBy('position');

    loading.value = false;
  } catch (error) {
    console.error("Error fetching domain data:", error);
    loading.value = false;
  }
};

const itemsPerPage = 50;

const formatDate = (date) => {
  const dateObj = date ? new Date(date) : new Date();
  return `${dateObj.getFullYear()}-${("0" + (dateObj.getMonth() + 1)).slice(
    -2
  )}-${("0" + dateObj.getDate()).slice(-2)}`;
};

const highlighted = {
  customPredictor(date) {
    const availableDays = availableDates.value.map((dateStr) =>
      new Date(dateStr).getDate()
    );
    const day = date.getDate();
    return availableDays.includes(day);
  },
};
const disabledDates = {
  customPredictor(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return !availableDates.value.some((dateStr) => {
      const [yy, mm, dd] = dateStr.split("-");
      return (
        parseInt(yy) === year && parseInt(mm) === month && parseInt(dd) === day
      );
    });
  },
};

const updateSchedule = async () => {
  try {
    const response = await axiosIns.post(
      `/domain/save-domain-project/${projectId}`,
      {
        schedule: selectedDuration.value,
        isSchedule: true,
      }
    );
    toast.success("Schedule updated successfully", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 6000,
    });
    listDomainCollection(
      router.currentRoute.value.params.id,
      currentPage.value
    );
  } catch (error) {
    console.error("Failed to update Schedule:", error);

    toast.error("Failed to update Schedule. Please try again later.", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 6000,
    });
  }
};

const handleSaveClick = async () => {
  loading.value = true;
  try {
    const response = await axiosIns.post(
      `/domain/save-domain-project/${projectId}`
    );
    loading.value = false;

    if (response.data.success) {
      toast.success("Domain project saved successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000,
      });
      listDomainCollection(
        router.currentRoute.value.params.id,
        currentPage.value
      );
      setTimeout(() => {
        router.push("/domain-projects-list");
      }, 3000);
    } else {
      loading.value = false;
      setTimeout(() => {
        router.push("/domain-projects-list");
      }, 3000);
      toast.error(response.data.message || "Failed to save domain project.", {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000,
      });
    }
  } catch (error) {
    loading.value = false;
    setTimeout(() => {
      router.push("/domain-projects-list");
    }, 3000);
    console.error("Error saving domain project:", error.message);
    toast.error("Failed to save domain project. Please try again later.", {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000,
    });
  }
};

const spin = ref(false);
const handleRefresh = async () => {
  try {
    spin.value = true;
    await axiosIns.post(`/domain/refresh-domain-project/${projectId}`);
    spin.value = false;
    
    toast.success("Your data is refreshed. The page will auto-refresh in 30 seconds.", {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000,
    });

    setTimeout(() => {
      window.location.reload();
    }, 30000);

    listDomainCollection(
      router.currentRoute.value.params.id,
      currentPage.value
    );
  } catch (error) {
    spin.value = false;
    toast.error(error.response?.data?.error || "An error occurred", {
      position: toast.POSITION.TOP_RIGHT,
      duration: 6000,
    });
  }
};


const deleteExistingProject = async (id) => {
  try {
    const response = await axiosIns.delete(
      `/domain/delete-domain-project/${id}`
    );
    return response.data;
  } catch (error) {
    console.error(
      "Error deleting the project:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
const exportExcel = async () => {
  const latestDateAfterFetch =
    availableDates.value && availableDates.value.length > 0
      ? availableDates.value[0]
      : null;

  loading.value = true;
  try {
    const response = await axiosIns.post(
      `/domain/generate-excel/${projectId}`,
      {
        created_at: latestDateAfterFetch,
      }
    );

    loading.value = false;
    toast.success("Excel generated successfully!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 6000,
    });

    const downloadUrl = response.data.file_url;
    window.location.href = downloadUrl;
  } catch (error) {
    loading.value = false;
    console.error(
      "Error generating Excel:",
      error.response ? error.response.data.error : error.message
    );
    toast.error("Failed to generate Excel!", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 6000,
    });
  }
};
const getColorForKD = (kd) => {
  return kd > 50 ? "#EB5757" : "#8AC472";
};

// Series data for organic and paid traffic
const seriesArea = ref([
  {
    name: "Organic Traffic",
    data: [10, 20, 30, 40, 35], // Organic data for page ranges
  },
  {
    name: "Paid Traffic",
    data: [50, 0, 0, 0, 0], // Paid traffic only for 0-10 page range
  },
]);

// Options for the area chart
const chartOptionsArea = ref({
  chart: {
    type: "area",
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
    animations: {
      enabled: true,
      easing: "easeinout",
      speed: 800,
      animateGradually: {
        enabled: true,
        delay: 150,
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350,
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
    width: 2,
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.7,
      opacityTo: 0.9,
      stops: [0, 100],
    },
  },
  colors: ["#28B5E3", "#FF6347"],
  xaxis: {
    categories: ["0-10", "10-20", "20-30", "30-40", "40+"],
    title: {
      text: "Page Range",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#333",
      },
    },
    labels: {
      style: {
        fontSize: "12px",
        colors: "#666",
      },
    },
    axisBorder: {
      show: true,
      color: "#ccc",
    },
    axisTicks: {
      show: true,
      color: "#ccc",
    },
  },
  yaxis: {
    title: {
      text: "Number of Keywords",
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#333",
      },
    },
    labels: {
      style: {
        fontSize: "12px",
        colors: "#666",
      },
    },
    tickAmount: 5,
  },
  tooltip: {
    y: {
      formatter: function (value, { dataPointIndex }) {
        return `${value} keywords in range ${chartOptionsArea.value.xaxis.categories[dataPointIndex]}`;
      },
    },
  },
  legend: {
    position: "top",
    horizontalAlign: "right",
    floating: true,
    background: "#fff",
    borderColor: "#ccc",
    borderWidth: 1,
    offsetY: 10,
  },
});

const isTrial = ref(false);
const sortKeywords = () => {
  if (sortByColumn.value === "") return;

  // Perform sorting on allData (which contains the complete set of data)
  const sortedData = [...allData.value].sort((a, b) => {
    let modifier = sortDirection.value === "desc" ? -1 : 1;
    if (a[sortByColumn.value] < b[sortByColumn.value]) return -1 * modifier;
    if (a[sortByColumn.value] > b[sortByColumn.value]) return 1 * modifier;
    return 0;
  });

  // Update sortedKeywords with the sorted data
  sortedKeywords.value = sortedData;
};


const toggleSortDirection = () => {
  sortDirection.value = sortDirection.value === "asc" ? "desc" : "asc";
};
const sortBy = (column) => {
  if (sortByColumn.value === column) {
    toggleSortDirection();
  } else {
    sortByColumn.value = column;
    sortDirection.value = "asc";
  }
  sortKeywords(); // Call sorting after changing the sort
};

const sortedKeywords = ref([]); // Use a reactive reference to store sorted data


const filteredKeywords = computed(() => {
  return sortedKeywords.value.filter((keyword) =>
    keyword.keyword.toLowerCase().includes(searchQuery.value.toLowerCase())
  );
});






const visibleKeywords = computed(() => {
  return filteredKeywords.value.slice(0, currentPage.value * itemsPerPage);
});

const handleScroll = (event) => {
  const container = event.target;
  if (container.scrollTop + container.clientHeight >= container.scrollHeight) {

    setTimeout(() => {
      sortBy("position");
    }, 2000);
    loadMore();
  }
};

const loadMore = () => {
  if (currentPage.value >= lastPage.value) {
    console.log("No more data to load.");
    return;
  }

  setTimeout(() => {
    currentPage.value++;

    listDomainCollection(
      router.currentRoute.value.params.id,
      currentPage.value
    );
  }, 1000);
};

onMounted(() => {
  EventBus.$emit("loadCredits", true);

  const data = localStorage.getItem("userData");
  isTrial.value = JSON.parse(data)?.type == 1 ? true : false;

  listDomainCollection(router.currentRoute.value.params.id, currentPage.value);
});

onUnmounted(async () => {
  if (isProjectCreated.value == 0) {
    try {
      await deleteExistingProject(projectId);
    } catch (error) {
      console.error(
        "Error deleting the project:",
        error.response ? error.response.data : error.message
      );
    }
  }
});

watch([showOrganic, showPaid], () => {
  if (chartOptions.value.data.length) {
    chartOptions.value.data[0].visible = showOrganic.value;
    chartOptions.value.data[1].visible = showPaid.value;
  }
});
</script>
<style>
.serps-button {
  background-color: #45c9f4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  position: relative;
}

.serps-button:hover {
  background-color: #285a8e;
}

.serps-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(50, 118, 195, 0.5);
}

.serps-button:active {
  background-color: #204070;
  transform: scale(0.95);
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 260px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  /* Position the tooltip above the button */
  left: 100%;
  margin-left: -80px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-container:hover .serps-button {
  background-color: #285a8e;
}

.chart-container {
  margin: 0 auto;
  max-width: 100%;
}

.collapse-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.collapsed-content {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

.small-text {
  font-size: 0.75em;
  margin-left: 5px;
}

.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  vertical-align: middle;
  /* Align the dot vertically with the text */
}

.dot.red {
  background-color: #eb5757;
}

.dot.green {
  background-color: #8ac472;
}

.dot.yellow {
  background-color: yellow;
}

.align-center {
  text-align: center;
  /* Center align content in the cell */
}

.kd-value {
  display: inline-block;
  margin-right: 5px;
  /* Adjust spacing between KD value and dot */
}

.text-green {
  color: #8ac472;
}

.text-red {
  color: #eb5757;
}

.url-a {
  color: var(--Main-Primary);
  position: relative;
  display: inline-block;
  text-decoration: none;
}

.url-text {
  font-size: 12px;
  position: absolute;
  top: -1.5em;
  /* Adjust as needed */
  /* left: 50%; */
  right: 0;
  /* transform: translateX(-50%); */
  background-color: rgba(255, 255, 255, 0.9);
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.keyword-distribution-title {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #45c9f4;
  /* Adjust color as needed */
}

.negative-change {
  color: #eb5757;
}

.positive-change {
  color: #8ac472;
}

.url-a:hover .url-text {
  opacity: 1;
}

.sort-arrow {
  margin-left: 5px;
}

.sorted {
  background-color: #f0f0f0;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.button-container {
  position: relative;
  display: inline-block;
}

.tooltip {
  visibility: visible;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0.9;
  width: 120px;
  pointer-events: none;
}

.vuejs3-datepicker__calendar .cell.highlighted {
  background: #45c9f4;
  color: #fff;
}

.vuejs3-datepicker__calendar-topbar {
  background-color: #45c9f4;
  border-radius: 4px 4px 0 0;
  color: #fff;
  padding: 25px;
  text-align: center;
}

.vuejs3-datepicker__calendar .cell.selected {
  background: lightslategrey;
  color: #fff;
}

.small-text {
  font-size: smaller;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes loading {
  0% {
    opacity: 0.8;
  }

  100% {
    opacity: 0.4;
  }
}

.label {
  font-weight: bold;
  margin-right: 5px;
  color: #333;
  /* You can customize the color */
}

.highlight-title {
  font-weight: bold;
  color: #3276c3;
  /* Adjust the color as needed */
  background-color: #f0f8ff;
  /* Light background color for highlighting */
  padding: 5px;
  border-radius: 3px;
}

.table-container {
  max-height: 1500px;
  /* Set a height for the scrollable container */
  overflow-y: auto;
}

.loading-spinner {
  text-align: center;
  padding: 10px;
  font-size: 16px;
}
</style>
