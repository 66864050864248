<template>
  <Loader :active="loading" message="Please wait..." />
  <div class="common-inner-body drc-page-cont">
    <div class="common-inner-cont">
      
      <!-- Conditional Upgrade Message -->
      <div v-if=" userType === 1" class="upgrade-message-container">
        <h2 class="upgrade-title">🚀 Unlock Premium Features!</h2>
    <p class="upgrade-text">Get exclusive tools, faster results, and a seamless experience. Upgrade now! 🔥</p>
    <div class="upgrade-btn-cont">
        <router-link to='/pricing-page' class="upgrade-link">Upgrade & Elevate!</router-link>
    </div>
</div>


      <div class="search-page-top">
        <h2 class="top-title">Check Your Domain Rank</h2>
        <h2 class="top-subtitle">
          Enter your domain below to discover the latest numbers for
          <strong>Organic & Paid Traffic, Serps and more</strong>
        </h2>

      

        <div class="top-form-cont">
          <div class="form-fld-cont domain-check-search flex-column row-gap-20">
            <div class="top-flds d-flex flex-wrap justify-content-center col-gap-20 row-gap-20 max-w-100">
              <div class="search-fld-cont domain-cont" :class="{ 'is-invalid': validationErrors.target }">
                <div class="input-fld-cont">
                  <input v-model="form.target" class="form-control domain" type="text" placeholder="Domain"
                    @input="validateTarget" />
                </div>
              </div>
              <div class="search-fld-cont keyword-cont">
                <div class="input-fld-cont">
                  <input v-model="form.limit" class="form-control keyword" type="text"
                    placeholder="Keyword Limit (e.g., 50)" @input="validateLimit" />
                </div>
              </div>
            </div>
            <div class="bottom-flds d-flex flex-wrap justify-content-center col-gap-20 row-gap-20 max-w-100">
              <div class="search-fld-cont country-cont" :class="{ 'is-invalid': validationErrors.country }">
                <div class="input-fld-cont">
                  <select class="form-control country" v-model="form.country" @change="onCountryChange">
                    <option disabled selected>Country</option>
                    <option v-for="country in regionList" :key="country.country_iso_code" :value="country">
                      {{ country.location_name }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="search-fld-cont location-cont" :class="{ 'is-invalid': validationErrors.location }">
                <div class="input-fld-cont position-relative">
                  <input class="form-control location" v-model="searchQuery" @input="onChange" type="text"
                    placeholder="Enter State (e.g., Florida) and City (e.g., Miami)..." />

                  <!-- Autocomplete Results -->
                  <ul v-show="showResults" class="autocomplete-results">
                    <li v-for="(result, index) in searchResults" :key="index" class="autocomplete-result"
                      @click="selectResult(result)">
                      {{ result.location_name }}
                    </li>
                    <li v-if="
                      searchResults?.length === 0 && searchQuery.trim() !== ''
                    " class="autocomplete-no-results">
                      No results found
                    </li>
                  </ul>
                </div>
              </div>

              <div class="search-fld-cont btn-cont">
                <button @click="checkNow" class="admin-common-btn btn-search"
                  :disabled="loading || !isFormValid ">
                  <span v-if="loading" class="spinner"></span>
                  <span v-else>Check</span>
                </button>
              </div>
            </div>
          </div>

          <p v-if="loading || !isFormValid " style="color: red !important;" class="text-muted mt-2">
                                Please fill above all the inputs to enable the button.
                            </p>
                            
          <!-- Validation Error Messages -->
          <div class="validation-messages">
            <p v-if="validationErrors.target" class="error-message">
              {{ validationErrors.target }}
            </p>
            <p v-if="validationErrors.limit" class="error-message">
              {{ validationErrors.limit }}
            </p>
            <p v-if="validationErrors.country" class="error-message">
              {{ validationErrors.country }}
            </p>
          </div>
        </div>
        <div class="search-page-img-cont">
          <img class="img-fluid"
            src="https://serpplus.pluspromotions.co.uk/images/domain-search-page-bg.png"
            alt="Photo" />
        </div>
      </div>
    </div>
  </div>

  <div class="copyright-cont">
    <p class="copyright-text">
      &copy; 2024 Plus Promotions UK LTD | Registered in UK, Company Number
      13871077, VAT Number 438499640
    </p>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, reactive, watch } from "vue";
import CountryService from "@/service/CountryService";
import axiosIns from "@/axios";
import Loader from "../Loader.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useRouter } from "vue-router";
import EventBus from "@/EventBus";

const loading = ref(false);
const showUpgradeMessage = ref(false);

const form = reactive({
  target: "",
  language: "en",
  load_rank_absolute: true,
  limit: "",
  country: "",
  state_city: "",
});

const countryService = new CountryService();
const searchQuery = ref("");
const showResults = ref(false);
const regionList = ref(null);
const cityStateData = ref([]);
const pageSize = 20;
const currentPage = ref(1);
const router = useRouter();

const userType = ref(null);

const validationErrors = reactive({
  target: "",
  limit: "",
  country: "",
});

const isFormValid = computed(() => {
  return (
    !validationErrors.target &&
    !validationErrors.limit &&
    !validationErrors.country &&
    form.target &&
    form.limit &&
    form.country
  );
});

const onChange = () => {
  showResults.value = true;
};

function validateTarget() {
  if (!form.target) {
    validationErrors.target = "Domain is required.";
  } else if (
    !/^(?!www\.)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/.test(
      form.target
    )
  ) {
    validationErrors.target =
      "Please enter a valid domain (e.g., example.com). 'www' is not allowed.";
  } else {
    validationErrors.target = "";
  }
}

function validateLimit() {
  if (!form.limit) {
    validationErrors.limit = "Keyword limit is required.";
  } else if (isNaN(form.limit)) {
    validationErrors.limit = "Keyword limit must be a number.";
  } else if (userType.value === 1 && form.limit > 50) {
    validationErrors.limit = "Limit for your account type is 50.";
  } else {
    validationErrors.limit = "";
  }
}

const onCountryChange = async () => {
  currentPage.value = 1;
  await fetchCityStateData();
};

const checkNow = async () => {
  validateTarget();
  validateLimit();

  if (isFormValid.value) {
    loading.value = true;
    const payload = {
      target: form.target,
      language: "en",
      load_rank_absolute: true,
      limit: form.limit,
      country: form.country ? Number(form.country.location_code) : null,
      state_city: form.state_city ? form.state_city.location_code : null,
      location_name: form.state_city ? form.state_city.location_name : null,
      option_location: form.country ? form.country.location_name : null,
      location_code: form.country ? form.country.location_code : null,
    };

    try {
      const response = await axiosIns.post("/domain/check-domain", payload);
      loading.value = false;
      EventBus.$emit("loadCredits", true);
      if (response.data.data && response.data.data.domain_project_id) {
        router.push(`domain-view/${response.data.data.domain_project_id}`);
      }
      if (response && response.data && response.data.message) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          duration: 6000,
        });
      }
    } catch (error) {
      loading.value = false;
      const errorMessage =
        error.response?.data?.message ||
        "Failed to fetch data. Please try again later.";
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000,
      });
    }
  }
};

const fetchCityStateData = async () => {
  try {
    const response = await countryService.getCityAndState(
      form.country.country_iso_code,
      currentPage.value,
      pageSize
    );
    cityStateData.value =
      currentPage.value === 1
        ? response
        : [...cityStateData.value, ...response];
  } catch (error) {
    console.error("Error fetching city data:", error);
  }
};

const searchResults = computed(() => {
  return searchQuery.value.trim()
    ? filteredLocations.value.filter((location) =>
      location.location_name
        .toLowerCase()
        .includes(searchQuery.value.trim().toLowerCase())
    )
    : [];
});

const filteredLocations = computed(() => {
  return searchQuery.value
    ? cityStateData.value.filter((location) =>
      location.location_name
        .toLowerCase()
        .includes(searchQuery.value.toLowerCase())
    )
    : cityStateData.value;
});

onMounted(() => {
  const data = localStorage.getItem("userData");
  if (data) {
    const parsedData = JSON.parse(data);

    userType.value = parsedData.type ?? 1;

   
    
  }

  countryService.getCountries().then((data) => {
    regionList.value = data.sort((a, b) =>
      a.location_name.localeCompare(b.location_name)
    );
  });
});

function selectResult(result) {
  searchQuery.value = result.location_name;
  showResults.value = false;
  form.state_city = result;
}

watch(
  () => form.limit,
  (newValue) => {
    if (userType.value === 1 && newValue > 50) {
      form.limit = 50;
    }
  }
);

</script>

<style scoped>
.validation-messages .error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.upgrade-message-container {
  /* display: flex;
  justify-content: center;
  padding: 0.5rem;
  margin-top: 1rem; */
}

.message-text {
  /* font-size: 0.95rem;
  color: #555; */
}

.upgrade-link {
  /* text-decoration: none;
  color: #45C9F4; */
}
</style>
