<template>
  <loader :active="loading" message="Please wait..." />

  <aside id="nav-bar" class="admin-sidebar l-navbar show">
    <div class="sidebar-box">
      <div class="brand-logo-cont">
        <a href="/" class="brand-logo">
          <img class="logo img-fluid" src="https://serpplus.pluspromotions.co.uk/images/logo.png" alt="SerpPlus" />
          <img class="logo-icon img-fluid" src="https://serpplus.pluspromotions.co.uk/images/logo-icon.png"
            alt="SerpPlus" />
        </a>
      </div>

      <nav class="admin-left-nav flex-shrink-0">
        <ul class="nav-ul">

          <li v-if="can('VIEW_AUTHORIZATION_MODULE')" :class="{ active: isRouteActive('/') }">
            <router-link to="/" >
              <img class="nav-icon" src="https://serpplus.pluspromotions.co.uk/images/admindashboard.png"
                alt="Icon Nav">
              <span class="nav-text">Admin Dashboard</span>
            </router-link>
          </li>
         
          <li :class="{ active: isRouteGroupActive(['/create-serp', '/view-serps']) }"
            v-if="can('VIEW_SERP_CHECKER_MODULE')">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" :data-bs-target="'#serp-management'"
              :aria-expanded="isRouteGroupActive(['/create-serp', '/view-serps']) ? 'true' : 'false'">
              <img class="nav-icon" src="https://serpplus.pluspromotions.co.uk/images/icon-sidebar2.svg"
                alt="Icon SERP Management">
              <span class="nav-text">Serp Checker</span>
            </div>
            <div id="serp-management" class="nav-sub-cat collapse"
              :class="{ show: isRouteGroupActive(['/create-serp', '/view-serps']) }">
              <ul class="btn-toggle-nav">
                <li v-if="can('CREATE_SERP_CHECK')" :class="{ active: isRouteActive('/create-serp') }">
                  <a href="/create-serp" title="Check Serp">
                    <span class="nav-text">Check Serp</span>
                  </a>
                </li>
                <li v-if="can('VIEW_SERP_LIST')" :class="{ active: isRouteActive('/view-serps') }">
                  <a href="/view-serps" title="Serp Listing">
                    <span class="nav-text">Serp Listing</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li :class="{ active: isRouteGroupActive(['/create-rank-project', '/list-serp-project']) }"
            v-if="can('VIEW_SERP_PROJECTS_MODULE')">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" :data-bs-target="'#serp-projects'"
              :aria-expanded="isRouteGroupActive(['/create-rank-project', '/list-serp-project']) ? 'true' : 'false'">
              <img class="nav-icon" src="https://serpplus.pluspromotions.co.uk/images/icon-sidebar7.svg"
                alt="Icon Serp Projects">
              <span class="nav-text">Serp Projects</span>
            </div>
            <div id="serp-projects" class="nav-sub-cat collapse"
              :class="{ show: isRouteGroupActive(['/create-rank-project', '/list-serp-project']) }">
              <ul class="btn-toggle-nav">
                <li v-if="can('CREATE_SERP_PROJECT')" :class="{ active: isRouteActive('/create-rank-project') }">
                  <a href="/create-rank-project" title="New Project">
                    <span class="nav-text">New Project</span>
                  </a>
                </li>
                <li v-if="can('VIEW_SERP_PROJECT_LIST')" :class="{ active: isRouteActive('/list-serp-project') }">
                  <a href="/list-serp-project" title="Projects Listing">
                    <span class="nav-text">Projects Listing</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li :class="{ active: isRouteGroupActive(['/domain-check', '/domain-projects-list']) }"
            v-if="can('VIEW_DOMAIN_CHECKER_MODULE')">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" :data-bs-target="'#domain-checker'"
              :aria-expanded="isRouteGroupActive(['/domain-check', '/domain-projects-list']) ? 'true' : 'false'">
              <img class="nav-icon" src="https://serpplus.pluspromotions.co.uk/images/icon-sidebar3.svg"
                alt="Icon Domain Checker">
              <span class="nav-text">Domain Checker</span>
            </div>
            <div id="domain-checker" class="nav-sub-cat collapse"
              :class="{ show: isRouteGroupActive(['/domain-check', '/domain-projects-list']) }">
              <ul class="btn-toggle-nav">
                <li v-if="can('VIEW_DOMAIN_RANKING')" :class="{ active: isRouteActive('/domain-check') }"
                  title="Check Domain Ranking">
                  <a href="/domain-check">
                    <span class="nav-text">Domain Ranking</span>
                  </a>
                </li>
                <li v-if="can('VIEW_DOMAIN_PROJECTS')" :class="{ active: isRouteActive('/domain-projects-list') }"
                  title="Domain Projects">
                  <a href="/domain-projects-list">
                    <span class="nav-text">Domain Projects</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <!-- <li
            :class="{ active: isRouteGroupActive(['/backlink-overview', '/backlinks-projects-list']), disabled: true }"
            v-if="can('VIEW_BACKLINK_MODULE')">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#backlink-module"
              aria-expanded="false" :class="{ disabled: isComingSoon }"
              @click.prevent="isComingSoon && event.stopPropagation()">

              <img class="nav-icon" src="https://serpplus.pluspromotions.co.uk/images/icon-sidebar4.svg"
                alt="Icon Backlink Module">
              <span class="nav-text">Backlink Module</span>

            </div>

            <div id="backlink-module" class="nav-sub-cat collapse show" :class="{ disabled: false }">
              <ul class="btn-toggle-nav">
                <li v-if="can('VIEW_BACKLINK_OVERVIEW')" :class="{ active: isRouteActive('/backlink-overview') }"
                  title="Backlink Overview">
                  <router-link to="/backlink-overview">
                    <span class="nav-text">Backlink Overview</span>
                  </router-link>
                </li>
                <li v-if="can('VIEW_BACKLINK_PROJECTS')" :class="{ active: isRouteActive('/backlinks-projects-list') }"
                  title="Backlinks Projects">
                  <router-link to="/backlinks-projects-list">
                    <span class="nav-text">Backlinks Projects</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li> -->

          <li :class="{ active: isRouteGroupActive(['/backlink-overview', '/backlinks-projects-list']) }"
            v-if="can('VIEW_BACKLINK_MODULE')">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#backlink-module"
              aria-expanded="false">
              <img class="nav-icon" src="https://serpplus.pluspromotions.co.uk/images/icon-sidebar4.svg"
                alt="Icon Backlink Module">
              <span class="nav-text">Backlink Module</span>
            </div>

            <!-- <div id="backlink-module" class="nav-sub-cat collapse show"> -->
            <div id="backlink-module" class="nav-sub-cat collapse">
              <ul class="btn-toggle-nav">
                <li v-if="can('VIEW_BACKLINK_OVERVIEW')" :class="{ active: isRouteActive('/backlink-overview') }"
                  title="Backlink Overview">
                  <router-link to="/backlink-overview">
                    <span class="nav-text">Backlink Overview</span>
                  </router-link>
                </li>
                <li v-if="can('VIEW_BACKLINK_PROJECTS')" :class="{ active: isRouteActive('/backlinks-projects-list') }"
                  title="Backlinks Projects">
                  <router-link to="/backlinks-projects-list">
                    <span class="nav-text">Backlinks Projects</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="can('VIEW_AUTHORIZATION_MODULE')"
            :class="{ active: isRouteGroupActive(['/user-list', '/role-list', '/permission-list']) }">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#authorization"
              aria-expanded="false">
              <img class="nav-icon" src="https://serpplus.pluspromotions.co.uk/images/Vector2.png" alt="Icon Nav">
              <span class="nav-text">Authorization</span>
            </div>
            <div id="authorization" class="nav-sub-cat collapse">
              <ul class="btn-toggle-nav">
                <li v-if="can('VIEW_USERS')" :class="{ active: isRouteActive('/user-list') }">
                  <router-link to="/user-list" title="User">
                    <span class="nav-text">User</span>
                  </router-link>
                </li>
                <li v-if="can('VIEW_ROLES')" :class="{ active: isRouteActive('/role-list') }">
                  <router-link to="/role-list" title="Roles">
                    <span class="nav-text">Roles</span>
                  </router-link>
                </li>
                <li v-if="can('VIEW_PERMISSIONS')" :class="{ active: isRouteActive('/permission-list') }">
                  <router-link to="/permission-list" title="Permissions">
                    <span class="nav-text">Permissions</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="can('VIEW_FAQ_MODULE')"
            :class="{ active: isRouteGroupActive(['/faq-category-list', '/faq-questions-list']) }">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#faq"
              aria-expanded="false">
              <img class="nav-icon" src="https://serpplus.pluspromotions.co.uk/images/Vector4.png" alt="Icon Nav">
              <span class="nav-text">Create FAQ</span>
            </div>
            <div id="faq" class="nav-sub-cat collapse">
              <ul class="btn-toggle-nav">
                <li v-if="can('VIEW_FAQ_CATEGORY')" :class="{ active: isRouteActive('/faq-category-list') }">
                  <router-link to="/faq-category-list" title="Category">
                    <span class="nav-text">FAQ Category</span>
                  </router-link>
                </li>
                <li v-if="can('VIEW_FAQ_QUESTIONS')" :class="{ active: isRouteActive('/faq-questions-list') }">
                  <router-link to="/faq-questions-list" title="Question">
                    <span class="nav-text">Question</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="can('VIEW_PLAN_SUBSCRIPTION_MODULE')"
            :class="{ active: isRouteGroupActive(['/pricing-page', '/purchase-list', '/credits-list']) }">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" :data-bs-target="'#plan-subscription'"
              :aria-expanded="isRouteGroupActive(['/pricing-page', '/purchase-list', '/credits-list']) ? 'true' : 'false'">
              <img class="nav-icon" src="https://serpplus.pluspromotions.co.uk/images/icon-sidebar5.svg"
                alt="Icon Plan Subscription">
              <span class="nav-text">Plan Subscription</span>
            </div>
            <div id="plan-subscription" class="nav-sub-cat collapse"
              :class="{ show: isRouteGroupActive(['/pricing-page', '/purchase-list', '/credits-list']) }">
              <ul class="btn-toggle-nav">
                <li v-if="can('VIEW_PRICING_PAGE')" :class="{ active: isRouteActive('/pricing-page') }">
                  <a href="/pricing-page" title="Plan">
                    <span class="nav-text">Subscription</span>
                  </a>
                </li>
                <li v-if="can('VIEW_PURCHASE_HISTORY')" :class="{ active: isRouteActive('/purchase-list') }">
                  <a href="/purchase-list" title="Plan">
                    <span class="nav-text">Purchase History</span>
                  </a>
                </li>
                <li v-if="can('VIEW_CREDITS_HISTORY')" :class="{ active: isRouteActive('/credits-list') }">
                  <a href="/credits-list" title="Credits History">
                    <span class="nav-text">Credits History</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="can('VIEW_SUPPORT_MODULE')"
            :class="{ active: isRouteGroupActive(['/manage-account', '/privacy-policy', '/faq-customer']) }">
            <div class="nav-main-cat btn btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#support"
              aria-expanded="false">
              <img class="nav-icon" src="https://serpplus.pluspromotions.co.uk/images/icon-sidebar6.svg"
                alt="Icon Support">
              <span class="nav-text">Support</span>
            </div>
            <div id="support" class="nav-sub-cat collapse">
              <ul class="btn-toggle-nav">
                <li v-if="can('VIEW_PRIVACY_POLICY')" :class="{ active: isRouteActive('/privacy-policy') }">
                  <a href="/privacy-policy" title="Privacy Policy">
                    <span class="nav-text">Privacy Policy</span>
                  </a>
                </li>
                <li v-if="can('VIEW_FAQ_CUSTOMER')" :class="{ active: isRouteActive('/faq-customer') }">
                  <a href="/faq-customer" title="FAQ">
                    <span class="nav-text">Help & FAQ</span>
                  </a>
                </li>
                <li v-if="can('SEND_SUPPORT_EMAIL')">
                  <a href="mailto:info@pluspromotions.co.uk" title="Mail us">
                    <span class="nav-text">Mail us</span>
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="can('VIEW_CUSTOMERS_MODULE')" :class="{ active: isRouteActive('/customer-list') }">
            <router-link to="/customer-list" >
              <img class="nav-icon" src="https://serpplus.pluspromotions.co.uk/images/Vector1.png"
                alt="Icon Nav">
              <span class="nav-text">Customers</span>
            </router-link>
          </li>

          <li v-if="can('VIEW_PLANS_MODULE')" :class="{ active: isRouteActive('/plan-list') }">
            <router-link to="/plan-list" >
              <img class="nav-icon" src="https://serpplus.pluspromotions.co.uk/images/Vector3.png"
                alt="Icon Nav">
              <span class="nav-text">Plans & Pricing</span>
            </router-link>
          </li>

          <li v-if="can('VIEW_AUTHORIZATION_MODULE')" :class="{ active: isRouteActive('/credit-settings') }">
            <router-link to="/credit-settings" >
              <img class="nav-icon" src="https://serpplus.pluspromotions.co.uk/images/credits.png"
                alt="Icon Nav">
              <span class="nav-text">Manage Credits</span>
            </router-link>
          </li>

        </ul>
      </nav>

      <div class="upgradeto-pro-box" v-if="userType === 1">
        <div class="box-cont">
          <h4 class="title-utp">UPGRADE TO PRO</h4>
          <div class="text-utp">
            <p>Get our all advanced tools, deeper insights, and more credits.</p>
          </div>
          <router-link to='/pricing-page' class="btn-gpn">Upgrade Now</router-link>
        </div>
      </div>

    </div>
  </aside>


</template>
<script>
import { useAbility } from '@casl/vue';
import axiosIns from '@/axios';
import EventBus from '@/EventBus';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Loader from '@/views/Loader.vue';
import { FRONT_APP_URL } from '@/Constants';

export default {
  components: {
    Loader,
  },
  data() {
    return {
      userType: null,
      plans: [],
      isMonthly: true,
      lineItems: [],
      loading: false,
      activeSub: {},
      remainingDays: null,
      prepareDataForUpgrade: {},
      successURL: `${FRONT_APP_URL}payment-done`,
      cancelURL: `${FRONT_APP_URL}payment-failed`,
    };
  },
  mounted() {
    EventBus.$on('loadCredits', data => {
      if (data) {
        this.loadUser()
      }
    })

    // Retrieve user data from localStorage
    const userData = JSON.parse(localStorage.getItem('userData'));
    this.email = userData?.email;
    // Do something with userData, for example, set it to a reactive property
    //this.userType = userData?.type;


  },
  methods: {
    async loadUser() {
      try {
        const data = await axiosIns.get('/user/show');
        localStorage.setItem('userData', JSON.stringify(data.data.userData));

        this.userType = data.data.userData.type;
      } catch (error) {
        console.error('Logout failed:', error);

      }
    },
    isRouteActive(route) {
      return this.$route.path === route;
    },
    isRouteGroupActive(routes) {
      return routes.some(route => this.$route.path.startsWith(route));
    },

    async fetchPlans() {
      try {
        const data = await axiosIns.get('plans');
        this.plans = data.data.plans;

        localStorage.setItem('userData', JSON.stringify(data.data.userData));
      } catch (error) {
        console.error('Error:', error.message);
      }
    },



  },
  computed: {
    can() {
      return useAbility().can;
    }
  }
};
</script>

<style scoped>
/* Style for unchecked switch */
.form-check-input {
  background-color: #007bff !important;
  /* Change to your desired color for unchecked state */
}

/* Style for checked switch */
.form-check-input:checked {
  background-color: #007bff !important;
  /* Change to your desired color for checked state */
}

.highlight {
  background-color: #e7f2ff;
  /* Light blue background color */
  border: 2px solid #45C9F4;
  /* Border color */
  border-radius: 5px;
  /* Optional: Adjust border radius for rounded corners */
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
  /* Optional: Add a shadow effect */
}


.btn-cancel {
  color: #dc3545;
  /* Red text color */
  background-color: transparent;
  /* Transparent background color */
  border-color: #dc3545;
  /* Red border color */
  transition: background-color 0.3s ease;
  /* Smooth transition */
}

.btn-cancel:hover {
  background-color: #dc3545;
  /* Red background color on hover */
  color: #fff;
  /* White text color on hover */
}

.badge {
  background-color: #007bff;
  /* Badge background color */
  color: #fff;
  /* Text color */
  border-radius: 10px;
  /* Border radius to round the badge */
  padding: 3px 6px;
  /* Padding around the text inside the badge */
  font-size: 12px;
  /* Font size of the text inside the badge */
  margin-left: 5px;
  /* Adjust this value to position the badge as desired */
}

.badge-red {
  background-color: red;
  /* Badge background color */
  color: #fff;
  /* Text color */
  border-radius: 10px;
  /* Border radius to round the badge */
  padding: 3px 6px;
  /* Padding around the text inside the badge */
  font-size: 12px;
  /* Font size of the text inside the badge */
  margin-left: 5px;
  /* Adjust this value to position the badge as desired */
}

.coming-soon-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  z-index: 10;
}

.nav-main-cat {
  position: relative;
}

/* Prevent clicking when the Coming Soon overlay is shown */
.nav-main-cat.disabled,
.nav-sub-cat.disabled {
  pointer-events: none;
}

/* Optional: Change the cursor to indicate disabled state */
.nav-main-cat.disabled,
.nav-sub-cat.disabled {
  cursor: not-allowed;
}
</style>