<template>
    <div class="common-inner-body faq-customer-page-cont p-6 flex justify-center">
        <div class="common-inner-cont bg-white shadow-lg rounded-lg p-6 w-full max-w-3xl">
            <h2 class="text-lg font-semibold mb-4 text-gray-800">Module Credit Allocation</h2>
            <table class="table-auto w-full border-collapse border border-gray-300 rounded-lg overflow-hidden">
                <thead>
                    <tr class="bg-gray-200 text-gray-700">
                        <th class="border border-gray-300 px-4 py-2 text-left" style="text-align: center;">Module</th>
                        <th class="border border-gray-300 px-4 py-2 text-left" style="text-align: center;">Limit, Keywords, Link</th>
                        <th class="border border-gray-300 px-4 py-2 text-left" style="text-align: center;">Credits</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, index) in rows" :key="index" class="hover:bg-gray-50">
                        <td class="border border-gray-300 px-4 py-2 font-medium" style="text-align: center;">{{ row.title }}</td>
                        <td class="border border-gray-300 px-4 py-2" style="text-align: center;">{{ row.keywords }}</td>
                        <td class="border border-gray-300 px-4 py-2" style="text-align: center;">
                            <input v-model.number="row.credits" type="number"
                                class="border rounded px-2 py-1 w-20 text-center" @input="onCreditChange" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>
import { reactive, onMounted } from 'vue';
import axiosIns from '@/axios';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

const rows = reactive([
    { title: 'Serp Module', keywords: '1', credits: 0 },
    { title: 'Domain Module', keywords: '1', credits: 0 },
    { title: 'Backlink Module', keywords: '1', credits: 0 },
]);

const onCreditChange = async () => {
    try {
        await axiosIns.post('/credits/manage', { data: rows });

        toast.success('Credits updated successfully!', {
            position: 'top-right',
            autoClose: 3000,  
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        });

        setTimeout(() => fetchCredits(), 1500); 
    } catch (error) {
        toast.error('Failed to update credits. Try again.', {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        });
    }
};

const fetchCredits = async () => {
    try {
        const response = await axiosIns.get('/credits');

        if (response.data.success && response.data.data) {
            const fetchedData = JSON.parse(response.data.data);

            fetchedData.forEach((item, index) => {
                if (rows[index]) {
                    rows[index].credits = item.credits;
                }
            });

            toast.info('Data refreshed!', {
                position: 'top-right',
                autoClose: 2500, 
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    } catch (error) {
        toast.error('Error fetching credits.', {
            position: 'top-right',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        });

        console.error('Error fetching credits:', error);
    }
};

onMounted(fetchCredits);
</script>

<style scoped>
input:focus {
    outline: none;
    border-color: #2563eb;
    box-shadow: 0 0 5px rgba(37, 99, 235, 0.5);
}
</style>
