<template>
  <div class="backlink-projects-page-cont">
    <div class="backlink-table-cont">
      <div class="backlink-table-box" v-if="userData?.type === 2">
        <div class="box-top box-top-with-filter-new justify-content-between" >
            <div class="table-top-left-info d-flex flex-wrap align-items-center row-gap-3">
                <router-link to="/create-rank-project" class="btn btn-light admin-common-btn me-4">
                    <img class="img-fluid"
                        src="https://serpplus.pluspromotions.co.uk/images/icon-check-serp.svg"
                        alt="Check Serp Icon" />
                    Check Serp Project
                </router-link>
             
            </div>
            <div class="table-top-bar">
                <div class="top-left">
                    <div class="form-group search-group">
                        <input class="table-search-input" placeholder="Search Here" v-model="searchQueryProjects" />
                    </div>
                </div>
              
            </div>
        </div>
        <div class="box-body">
          <div class="data-table-cont">
            <div class="table-responsive table-container table-normal-view table-backlink" @scroll="handleScroll">
              <table id="example3" class="data-table table table-competitor-view dataTable no-footer">
                <thead>
                  <tr>
                    <th class="no-wrap">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Project Name</span>
                          <!-- <img
                            class="img-fluid th-icon"
                            src="https://serpplus.pluspromotions.co.uk/images/icon-question-circle.svg"
                            alt="Question Icon"
                            title="Domain"
                          /> -->
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Domain</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Location</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Keywords</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Device</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Search Engine</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Date Created</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Last Run</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-center">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Run Time</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-center">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Open Project</span>
                        </div>
                      </div>
                    </th>
                    <th class="no-wrap align-center">
                      <div class="th-short-box">
                        <div class="txt-icon-cont">
                          <span class="th-txt">Remove</span>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="project in allData" :key="project.id">
                    <td class="align-left no-wrap">
                      {{ project.projectName || "N/A" }}
                    </td>
                    <td class="align-left no-wrap">
                      {{ project.domain || "N/A" }}
                    </td>
                    <td class="align-left no-wrap">
                      {{ project.location || "N/A" }}
                    </td>
                    <td class="align-left no-wrap">
                      {{ project.keywords ? project.keywords.length : 0 }}
                    </td>
                    <td class="align-left no-wrap">
                      {{
                        deviceType.find(
                          (item) => item.id === project.deviceType
                        )?.name || "Unknown Device"
                      }}
                    </td>
                    <td class="align-left no-wrap">
                      {{
                        searchEngine.find(
                          (item) => item.id === project.searchEngine
                        )?.name || "Unknown Search Engine"
                      }}
                    </td>
                    <td class="align-left no-wrap">
                      {{ project.date_created || "N/A" }}
                    </td>
                    <td class="align-left no-wrap">
                      {{ project.last_executed_at || "N/A" }}
                    </td>
                    <td class="align-center">
                      <a class="table-clock-icon-bordered" href="javascript:void(0);" data-bs-toggle="modal"
                        data-bs-target="#modal-run-time" @click="openRunTimeModal(project)">
                        <img class="img-fluid" src="https://serpplus.pluspromotions.co.uk/images/icon-clock-new.svg" alt="Clock Icon" />
                      </a>
                    </td>

                    <td class="align-center">
                      <p class="text-highlight">
                        <RouterLink :to="`/view-serp-project/${project.id}`" class="text-primary">
                          Open
                          <img class="img-fluid"
                            src="https://serpplus.pluspromotions.co.uk/images/icon-open-link.svg"
                            alt="Open Link Icon" title="Open" />
                        </RouterLink>
                      </p>
                    </td>
                    <td class="align-center">
                      <a class="table-action-btn-new bg-signal-red-light btn-remove" href="javascript:void(0);" title="Remove"
                        @click.prevent="confirmDelete(project.id)">
                        <img class="img-fluid"
                          src="https://serpplus.pluspromotions.co.uk/images/icon-bin.svg"
                          alt="Remove Icon" />
                      </a>

                    </td>
                  </tr>

                  <tr v-if="isLoading">
                    <td colspan="10" class="text-center">
                      <p style="font-size: 1.2em">⏳ Loading...</p>
                    </td>
                  </tr>

                  <!-- No Data State -->
                  <tr v-else-if="!allData.length">
                    <td colspan="10" class="text-center">
                      <p style="font-size: 1.2em">🧐 No Data Available</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="locked-feature-container">
                <h2 class="locked-title">🔒 This Feature is Locked!</h2>
                <p class="locked-text">
                    Take your SEO game to the next level! 🚀 With our premium SERP Projects module, you can:
                </p>
                <ul class="locked-benefits">
                    <li>🔍 Track up to <strong>100 keywords</strong> per project.</li>
                    <li>📈 Get <strong>automated daily ranking updates</strong>.</li>
                    <li>⚡ Compare your rankings with <strong>top competitors</strong>.</li>
                    <li>📊 Analyze search volumes, keyword difficulty, and export data.</li>
                    <li>🔥 Gain a competitive edge with advanced SEO insights.</li>
                </ul>
                <p class="locked-text">
                    Upgrade today and supercharge your SEO strategy! 💡<br>
                    Thanks, <strong>Serp Plus Team</strong> 🙌<br>
                    For more details, visit our <router-link to="/landing-page" class="locked-link">Landing
                        Page</router-link>.
                </p>
                <div class="upgrade-btn-cont">
                    <router-link to="/pricing-page" class="upgrade-link">🚀 Upgrade Now & Unlock Full
                        Access!</router-link>
                </div>
      </div>
    </div>
  </div>

  
  <div id="modal-run-time" class="common-modal-cont modal fade modal-run-time" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-490 modal-dialog-centered">
      <div class="modal-content" v-if="selectedProject">
        <form @submit.prevent="updateRunTime(selectedProject.id)">
          <div class="modal-header">
            <h5 class="modal-title mx-auto">
              <span class="mod-title">Run Time for {{ selectedProject?.projectName || 'N/A' }}</span>
            </h5>
            <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <img src="https://serpplus.pluspromotions.co.uk/images/icon-popup-close.svg"
              alt="Close" />
            </a>
          </div>
          <div class="modal-body">
            <div class="search-fld-cont project-run-time">
              <div class="input-fld-cont">
                <div class="form-check form-check-big d-flex align-items-center">
                  <label class="form-check-label" for="dailyCheck">
                    Run Once a Day <span class="text-primary-new">(Recommended)</span>
                  </label>
                  <input id="dailyCheck" class="form-check-input" type="checkbox"
                  v-model="selectedProject.isDailyChecked" />
                </div>
              </div>
            </div>
            <div class="search-fld-cont project-run-time w-100">
              <div class="input-fld-cont">
                <select class="form-control" v-model="selectedProject.duration">
                  <option v-for="(duration, index) in durationOptions" :key="index" :value="duration.value">
                    {{ duration.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="admin-common-btn btn btn-primary mr-0" type="submit"
            @click="updateRunTime(selectedProject.id)" data-bs-dismiss="modal" aria-label="Close">
            Update
          </button>
          <div class="mobile-close-modal">
            <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <img src="images/icon-popup-close.svg" alt="Close" />
            </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <copyRight></copyRight>
  
</template>

<script setup>
import { ref, onMounted } from "vue";
import copyRight from '../../copyRight';
import axiosIns from "@/axios";
import { toast } from 'vue3-toastify';
import SerpService from '@/service/SerpService';

const searchQueryProjects = ref(null);
const isLoading = ref(false);
const currentPage = ref(1);
const lastPage = ref(1);
const selectedProject = ref(null); 
const serpService = new SerpService();
const userData = ref(null)

const searchEngine = ref([
  { id: 1, name: "Google" },
  { id: 2, name: "Yahoo" },
  { id: 3, name: "Bing" },
]);

const deviceType = ref([
  { id: 1, name: "Desktop" },
  { id: 2, name: "Tablet" },
  { id: 3, name: "Mobile" },
]);

const durationOptions = [
  { value: 'onceInWeek', name: 'Once a Week' },
  { value: 'onceInTwoWeek', name: 'Once Bi-Weekly' },
  { value: 'onceInMonth', name: 'Once a Month' },
  { value: 'never', name: 'Never' },
];

const allData = ref([]);

const openRunTimeModal = (project) => {
  selectedProject.value = { ...project };
  selectedProject.value.isDailyChecked = project.isDailyChecked === 1;
};



const updateRunTime = async () => {
  try {
    const formData = {
      isRuntime: true,
      duration: selectedProject.value.duration,
      isDailyChecked: selectedProject.value.isDailyChecked,
    };

    if (!selectedProject.value) {
      throw new Error("No project selected");
    }

    const projectId = selectedProject.value.id;

    await serpService.UpdateProject(projectId, formData);

    toast.success(
      `Runtime settings updated successfully for project: ${selectedProject.value.projectName || "Unnamed Project"}`,
      {
        position: toast.POSITION.TOP_RIGHT,
        timeout: 6000,
      }
    );

    setTimeout(()=>{
      window.location.reload();
    },6000)

    selectedProject.value = null;
    form.value = { isDailyChecked: false, duration: "onceInWeek" };
    const modal = document.getElementById("modal-run-time");
    if (modal) modal.classList.remove("show");
  } catch (error) {
    toast.error(
      `Failed to update runtime settings: ${error.message}`,
      {
        position: toast.POSITION.TOP_RIGHT,
        timeout: 6000,
      }
    );
    console.error("Error updating runtime:", error.message);
  }
};

const handleScroll = (event) => {
  const container = event.target;

  if (container.scrollHeight > container.clientHeight) {
    if (
      container.scrollTop + container.clientHeight >=
      container.scrollHeight
    ) {
      loadMore();
    }
  }
};

const loadMore = () => {
  if (currentPage.value >= lastPage.value) {
    console.log("No more data to load.");
    return;
  }

  setTimeout(() => {
    currentPage.value++;
    fetchData(currentPage.value);
  }, 1000);
};

const fetchData = async (page) => {
  isLoading.value = true;
  axiosIns
    .get(`projects?page=${page}`)
    .then((response) => {
      isLoading.value = false;
      allData.value.push(...response.data.data.projects);
      lastPage.value = response.data.data.pagination.last_page;
      currentPage.value = response.data.data.pagination.current_page;
      return response.data;
    })
    .catch((error) => {
      isLoading.value = false;
      console.error(`Error listing projects:`, error);
      throw error;
    });
};

const confirmDelete = async (projectId) => {
  if (confirm("Are you sure you want to delete this project?")) {
    try {
      await axiosIns.delete(`/projects/${projectId}`);
      allData.value = allData.value.filter((p) => p.id !== projectId);
      toast.success("Project Deleted", {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000,
      });
    } catch (error) {
      toast.error("Error Deleting Project", {
        position: toast.POSITION.TOP_RIGHT,
        duration: 6000,
      });
      console.error("Error Deleting Project:", error);
    }
  }
};

onMounted(() => {
  fetchData(1);

  const data = localStorage.getItem('userData');
    userData.value = JSON.parse(data);

});

</script>

<style>
.table-container {
  max-height: 1500px;
  overflow-y: auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
