<template>
    <div class="common-inner-body drc-page-cont">
        <div class="common-inner-cont">

            <!-- Conditional Upgrade Message -->
            <!-- <div v-if="showUpgradeMessage || userType === 1" class="upgrade-message-container"> -->
            <!-- <div class="upgrade-message-container" v-if="userData?.type === 1">
                <h2 class="upgrade-title">🚀 Unlock Premium Features!</h2>
    <p class="upgrade-text">Get exclusive tools, faster results, and a seamless experience. Upgrade now! 🔥</p>
    <div class="upgrade-btn-cont">
        <router-link to='/pricing-page' class="upgrade-link">💎 Upgrade & Elevate! 💎</router-link>
    </div>
            </div> -->

            <div class="search-page-top create-project-top" v-if="userData?.type === 2">
                <h2 class="top-title">Create Serp Project</h2>
                <h2 class="top-subtitle">
                    Enter Your Details Below to Create a New Serp Project
                    <strong>And Effortlessly Track Domain Performance</strong>
                </h2>

                <div class="top-form-cont mt-30">
                    <div class="multistep-form">
                        <!-- Step Number Navigation with Lines -->
                        <div class="steps">
                            <div v-for="(step, index) in steps" :key="index" class="step-container"
                                :class="{ 'disabled-section': isSubmitDisabled }">
                                <!-- Line (before step, except the first) -->
                                <!-- <div v-if="index > 0" class="line" :class="{ active: currentStep >= index }"></div> -->

                                <!-- Step Number -->
                                <div class="step"
                                    :class="{ active: currentStep === index, completed: currentStep > index }"
                                    @click="goToStep(index)">
                                    <p class="step-number">{{ index + 1 }}</p>
                                    <p class="step-title for-desktop">Project Info</p>
                                </div>

                                <!-- Line (after step, except the last) -->
                                <div v-if="index < steps.length - 1" class="line"
                                    :class="{ active: currentStep >= index + 1 }"></div>
                            </div>
                        </div>

                        <!-- Form Steps -->
                        <div class="form-content">
                            <div class="form-box box-one" v-show="currentStep === 0">
                                <!-- <h2>Step 1</h2>
                                <p>Enter details for step 1.</p>
                                <input type="text" placeholder="Step 1 Input" v-model="formData.step1" /> -->
                                <div class="form-fld-cont flex-column">
                                    <div
                                        class="top-flds d-flex flex-wrap justify-content-center col-gap-20 w-100 max-w-100">
                                        <div class="search-fld-cont your-project-name">
                                            <div class="input-fld-cont">
                                                <input class="form-control" type="text" placeholder="Enter Project Name"
                                                    v-model="formData.projectName" @input="vali" />
                                            </div>
                                            <span v-if="projectNameValidationError" class="error-message">
                                                Please enter a project name.
                                            </span>
                                        </div>
                                        <div class="search-fld-cont search-enging">
                                            <div class="input-fld-cont">
                                                <select class="form-control" v-model="formData.searchEngine"
                                                    :disabled="userData?.type == 1" @change="validateSearchEngine">
                                                    <option value="">Select Search Engine</option>
                                                    <option v-for="engine in searchEngine" :key="engine.id"
                                                        :value="engine.id">
                                                        {{ engine.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <span class="required-alert" v-show="userData?.type == 1">
                                                Upgrade to view more options
                                            </span>
                                        </div>
                                        <div class="search-fld-cont search-device">
                                            <div class="input-fld-cont">
                                                <select class="form-control" v-model="formData.deviceType"
                                                    :disabled="userData?.type == 1" @change="validateDeviceType">
                                                    <option value="">Select Device</option>
                                                    <option v-for="device in deviceType" :key="device.id"
                                                        :value="device.id">
                                                        {{ device.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <span class="required-alert" v-show="userData?.type == 1">
                                                Upgrade to view more options
                                            </span>
                                        </div>
                                        <div class="search-fld-cont search-language">
                                            <div class="input-fld-cont">
                                                <select class="form-control" v-model="formData.language"
                                                    :disabled="userData?.type == 1">
                                                    <option value="">Select Language</option>
                                                    <option v-for="language in languages" :key="language.code"
                                                        :value="language.code">
                                                        {{ language.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <span class="required-alert" v-show="userData?.type == 1">
                                                Upgrade to view more options
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-box box-two" v-show="currentStep === 1">
                                <!-- <h2>Step 2</h2>
                                <p>Enter details for step 2.</p>
                                <input type="text" placeholder="Step 2 Input" v-model="formData.step2" /> -->
                                <div class="form-fld-cont flex-column">
                                    <div
                                        class="top-flds d-flex flex-wrap justify-content-center col-gap-20 w-100 max-w-100">
                                        <div class="search-fld-cont your-domain">
                                            <div class="input-fld-cont">
                                                <input class="form-control" type="text" placeholder="Your Domain"
                                                    @input="validateDomain" v-model="formData.domainUrl" />
                                                <span v-if="domainValidationError" class="error-message">
                                                    Please enter a valid domain. Example: 'google.com', 'amazon.com', or
                                                    'example.co.uk'.".
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="bottom-flds d-flex flex-wrap justify-content-center col-gap-20 row-gap-20 w-100 max-w-100">
                                        <!-- Keywords Section -->
                                        <div class="search-fld-cont search-tag-box">
                                            <label>Keywords</label>
                                            <div
                                                class="input-fld-cont d-flex justify-content-start align-items-center col-gap-20 row-gap-20">
                                                <input v-model="newKeyword" class="form-control" type="text"
                                                    placeholder="Enter your keywords for search" />
                                                <a class="fld-add-icon" href="#" @click.prevent="addKeyword">
                                                    <img class="img-fluid"
                                                        src="https://serpplus.pluspromotions.co.uk/images/icon-search-add.svg"
                                                        alt="Icon Reset" />
                                                </a>
                                            </div>
                                            <span v-if="showKeywordError || formData.keyword.length === 0"
                                                class="error-message">Keywords cannot be
                                                empty.</span>

                                            <div class="input-fld-cont search-tag-cont">
                                                <ul
                                                    class="d-flex flex-wrap justify-content-start col-gap-10 row-gap-10">
                                                    <li v-for="(keyword, index) in formData.keyword" :key="index"
                                                        class="competitor-item d-flex align-items-center">
                                                        <span class="competitor-name">{{ keyword }}</span>
                                                        <button @click="removeKeyword(index)" class="remove-btn"
                                                            aria-label="Remove competitor">
                                                            ✕
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- Competitors Section -->
                                        <div class="search-fld-cont search-tag-box"
                                            :class="{ 'disabled-section': userData?.type == 1 }">
                                            <label>Competitors</label>
                                            <div
                                                class="input-fld-cont d-flex justify-content-start align-items-center col-gap-20 row-gap-20">
                                                <input v-model="newCompetitor" class="form-control" type="text"
                                                    placeholder="Enter domains, Max 3 domains"
                                                    :disabled="userData?.type == 1" />
                                                <a class="fld-add-icon" href="#" @click.prevent="addCompetitor">
                                                    <img class="img-fluid"
                                                        src="https://serpplus.pluspromotions.co.uk/images/icon-search-add.svg"
                                                        alt="Icon Reset" />
                                                </a>
                                            </div>
                                            <span v-if="competitorError" class="error-message">
                                                {{ competitorErrorMessage }}
                                            </span>
                                            <span class="required-alert" v-show="userData?.type == 1">
                                                Upgrade to unlocked this feature to add competitors.
                                            </span>
                                            <div class="input-fld-cont search-tag-cont">
                                                <ul
                                                    class="d-flex flex-wrap justify-content-start col-gap-10 row-gap-10">
                                                    <li v-for="(competitor, index) in formData.competitor" :key="index"
                                                        class="competitor-item d-flex align-items-center">
                                                        <span class="competitor-name">{{ competitor }}</span>
                                                        <button @click="userData?.type != 1 && removeCompetitor(index)"
                                                            class="remove-btn" aria-label="Remove competitor">
                                                            ✕
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-box box-three" v-show="currentStep === 2">
                                <!-- <h2>Step 3</h2>
                                <p>Enter details for step 3.</p>
                                <input type="text" placeholder="Step 3 Input" v-model="formData.step3" /> -->
                                <div class="form-fld-cont flex-column">
                                    <div
                                        class="top-flds d-flex flex-wrap justify-content-center col-gap-20 w-100 max-w-100">
                                        <div class="search-fld-cont search-country">
                                            <div class="input-fld-cont">
                                                <select class="form-control" v-model="formData.country"
                                                    @change="onCountryChange">
                                                    <option value="">Select a Country</option>
                                                    <option v-for="country in regionList"
                                                        :key="country.country_iso_code" :value="country">
                                                        {{ country.location_name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <span v-if="countryError" class="error-message">
                                                Plase select a country.
                                            </span>
                                        </div>

                                        <div class="search-fld-cont search-state-city">
                                            <div class="input-fld-cont">
                                                <input class="form-control" type="text" v-model="searchQuery"
                                                    @input="onChange" :disabled="formData.country === null"
                                                    placeholder="Enter State and City optional" />
                                                <ul v-show="showResults" class="autocomplete-results">
                                                    <li v-for="(result, index) in searchResults" :key="index"
                                                        class="autocomplete-result" @click="selectResult(result)">
                                                        {{ result.location_name }}
                                                    </li>
                                                    <li v-if="searchResults?.length === 0 && searchQuery.trim() !== ''"
                                                        class="autocomplete-no-results">
                                                        No results found
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-box box-four" v-show="currentStep === 3"
                                :class="{ 'disabled-section': userData?.type == 1 }">
                                <!-- Upgrade Message -->
                                <div v-if="userData?.type == 1" class="upgrade-message text-center">
                                    <p>
                                        <i class="fas fa-exclamation-circle text-highlight"></i>
                                        <strong class="highlighted-text">Upgrade to Unlock Premium Features:</strong>
                                    </p>
                                    <div class="features-list">
                                        <div class="feature-card">
                                            <i class="fas fa-clock feature-icon"></i>
                                            <h4>Daily Run Scheduling</h4>
                                            <p>Automate processes and run tasks once every day without manual effort.
                                            </p>
                                        </div>
                                        <div class="feature-card">
                                            <i class="fas fa-chart-line feature-icon"></i>
                                            <h4>Advanced Analytics</h4>
                                            <p>Access detailed performance metrics to optimize your workflows.</p>
                                        </div>

                                    </div>
                                    <a href="/upgrade" class="upgrade-button">Upgrade Now</a>
                                </div>

                                <div class="form-fld-cont flex-column" v-else>
                                    <div
                                        class="top-flds d-flex flex-wrap justify-content-center col-gap-20 w-100 max-w-100">
                                        <div class="search-fld-cont project-run-time">
                                            <div class="input-fld-cont">
                                                <div class="form-check form-check-big d-flex align-items-center">
                                                    <label class="form-check-label" for="exampleCheck1">Run Once a Day
                                                        <span class="text-primary-new">(Recommended)</span></label>
                                                    <input id="exampleCheck1" v-model="formData.isDailyChecked"
                                                        class="form-check-input" type="checkbox" checked>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="search-fld-cont project-run-time w-100">
                                            <div class="input-fld-cont">
                                                <select class="form-control" v-model="formData.duration"
                                                    @change="falseIsDailyChecked" :disabled="userData?.type == 1">
                                                    <option v-for="(duration, index) in durationOptions" :key="index"
                                                        :value="duration.value">
                                                        {{ duration.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>

                        <!-- Navigation Buttons -->
                        <div class="form-navigation">
                            <button class="form-btn btn-reset" :disabled="currentStep === 0" @click="prevStep"><img
                                    class="icon-img"
                                    src="https://serpplus.pluspromotions.co.uk/images/icon-reset-multistep-form.svg"
                                    alt="Icon Reset" /> Reset</button>
                            <button class="form-btn btn-next admin-common-btn btn-ranking"
                                v-if="currentStep < steps.length - 1" :disabled="isSubmitDisabled"
                                @click="nextStep"><img class="icon-img"
                                    src="https://serpplus.pluspromotions.co.uk/images/icon-next-multistep-form.svg"
                                    alt="Icon Reset" /> Next Step</button>
                            <button class="form-btn btn-submit" v-else @click="submitForm">Submit</button>
                        </div>
                    </div>
                </div>

                <h2 class="bottom-title">Effortlessly Manage Keywords and Track<br>Monthly Rankings to Enhance Your SEO
                    Strategy</h2>
                <h2 class="bottom-subtitle">Take control of your SEO with Serp Tracker! Set up keyword details for your
                    domain, monitor rankings monthly, and gain actionable insights. Our user-friendly dashboard makes it
                    easy to analyze trends and refine your strategy for consistent SEO growth.</h2>

                <div class="search-page-img-cont">
                    <img class="img-fluid"
                        src="https://serpplus.pluspromotions.co.uk/images/create-serp-project-page-bg.png"
                        alt="Photo" />
                </div>
            </div>
            <div v-else class="locked-feature-container">
                <h2 class="locked-title">🔒 This Feature is Locked!</h2>
                <p class="locked-text">
                    Take your SEO game to the next level! 🚀 With our premium SERP Projects module, you can:
                </p>
                <ul class="locked-benefits">
                    <li>🔍 Track up to <strong>100 keywords</strong> per project.</li>
                    <li>📈 Get <strong>automated daily ranking updates</strong>.</li>
                    <li>⚡ Compare your rankings with <strong>top competitors</strong>.</li>
                    <li>📊 Analyze search volumes, keyword difficulty, and export data.</li>
                    <li>🔥 Gain a competitive edge with advanced SEO insights.</li>
                </ul>
                <p class="locked-text">
                    Upgrade today and supercharge your SEO strategy! 💡<br>
                    Thanks, <strong>Serp Plus Team</strong> 🙌<br>
                    For more details, visit our <router-link to="/landing-page" class="locked-link">Landing
                        Page</router-link>.
                </p>
                <div class="upgrade-btn-cont">
                    <router-link to="/pricing-page" class="upgrade-link">🚀 Upgrade Now & Unlock Full
                        Access!</router-link>
                </div>
            </div>


        </div>
    </div>

    <div class="copyright-cont">
        <p class="copyright-text">
            &copy; {{ currentYear }} <!--2024--> Plus Promotions UK LTD | Registered in UK, Company Number
            13871077, VAT Number 438499640
        </p>
    </div>

</template>

<script setup>
import { ref, onMounted, computed, watch, reactive } from 'vue';
import CountryService from '@/service/CountryService';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import EventBus from '@/EventBus';
import { useRouter } from 'vue-router';
import SerpService from '@/service/SerpService';

const router = useRouter();
const countryService = new CountryService();
const serpService = new SerpService();

const currentYear = new Date().getFullYear();
const currentStep = ref(0);
const steps = [1, 2, 3, 4];
const pageSize = 20;
const currentPage = ref(1);
const searchQuery = ref('');
const showResults = ref(false);
const cityStateData = ref([]);
const newKeyword = ref("");
const newCompetitor = ref("");
const showKeywordError = ref(false);
const competitorError = ref(false);
const countryError = ref(false);
const competitorErrorMessage = ref("");
const userData = ref(null)
const regionList = ref(null);
const stateData = reactive({})
const domainValidationError = ref(false);
const projectNameValidationError = ref(false);

const generateRandomId = () => {
    const randomString = Math.random().toString(36).substring(2, 8);

    const randomNumber1 = Math.floor(Math.random() * 10);
    const randomNumber2 = Math.floor(Math.random() * 10);

    const randomId = randomString + randomNumber1 + randomNumber2;

    return randomId;
};

const formData = reactive({
    random_id: generateRandomId(),
    projectName: null,
    deviceType: 1,
    searchEngine: 1,
    language: 'en',
    domainUrl: null,
    keyword: [],
    competitor: [],
    country: null,
    state_city: null,
    location_name: null,
    option_location: null,
    duration: 'onceInDay',
    isDailyChecked: true,
});


const falseIsDailyChecked = () => {
    formData.isDailyChecked = false
}

const validateDomain = () => {
    const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
    domainValidationError.value = !domainPattern.test(formData.domainUrl.trim());
};

const validateCompetitor = (domain) => {
    const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

    const trimmedDomain = domain.trim();

    if (!domainPattern.test(trimmedDomain)) {
        competitorError.value = true;
        competitorErrorMessage.value = "Please enter a valid domain. Example: 'google.com', 'amazon.com', or 'example.co.uk'.";
        return false;
    }

    if (formData.competitor.some((existingDomain) => existingDomain.toLowerCase() === trimmedDomain.toLowerCase())) {
        competitorError.value = true;
        competitorErrorMessage.value = "This competitor is already added.";
        return false;
    }

    competitorError.value = false;
    competitorErrorMessage.value = "";
    return true;
};

const searchResults = computed(() => {
    if (!searchQuery.value.trim()) {
        return [];
    } else {
        return filteredLocations.value.filter(location =>
            location.location_name.toLowerCase().includes(searchQuery.value.trim().toLowerCase())
        );
    }
});

const onChange = () => {
    showResults.value = true;
};

const selectResult = (result) => {
    searchQuery.value = result.location_name;
    showResults.value = false;
    Object.assign(stateData, result);
};

const onCountryChange = async () => {
    currentPage.value = 1;
    await fetchCityStateData();
};

const fetchCityStateData = async () => {
    try {
        const response = await countryService.getCityAndState(formData.country.country_iso_code, currentPage.value, pageSize);
        if (currentPage.value === 1) {
            cityStateData.value = response;
        } else {
            cityStateData.value = [...cityStateData.value, ...response];
        }
    } catch (error) {
        console.error('Error fetching city data:', error);
    }
};

const visibleLocations = computed(() => {
    if (cityStateData.value) {
        return cityStateData.value.slice(0, currentPage.value * pageSize);
    } else {
        return [];
    }
});

const filteredLocations = computed(() => {
    if (!searchQuery.value) {
        return visibleLocations.value;
    } else {
        const query = searchQuery.value.toLowerCase();
        return cityStateData.value.filter(location => {
            return location.location_name.toLowerCase().includes(query);
        });
    }
});

const searchEngine = ref([
    { id: 1, name: 'Google' },
    { id: 2, name: 'Yahoo' },
    { id: 3, name: 'Bing' }
]);

const deviceType = ref([
    { id: 1, name: 'Desktop' },
    { id: 2, name: 'Tablet' },
    { id: 3, name: 'Mobile' }
]);

const durationOptions = [
    { value: 'onceInWeek', name: 'Once a Week' },
    { value: 'onceInTwoWeek', name: 'Once Bi-Weekly' },
    { value: 'onceInMonth', name: 'Once a Month' },
    { value: 'never', name: 'Never' },
];

const languages = [
    { code: 'af', name: 'Afrikaans' },
    { code: 'sq', name: 'Albanian' },
    { code: 'am', name: 'Amharic' },
    { code: 'ar', name: 'Arabic' },
    { code: 'hy', name: 'Armenian' },
    { code: 'az', name: 'Azerbaijani' },
    { code: 'eu', name: 'Basque' },
    { code: 'be', name: 'Belarusian' },
    { code: 'bn', name: 'Bengali' },
    { code: 'bs', name: 'Bosnian' },
    { code: 'bg', name: 'Bulgarian' },
    { code: 'ca', name: 'Catalan' },
    { code: 'ceb', name: 'Cebuano' },
    { code: 'ny', name: 'Chichewa' },
    { code: 'zh', name: 'Chinese (Simplified)' },
    { code: 'zh-TW', name: 'Chinese (Traditional)' },
    { code: 'co', name: 'Corsican' },
    { code: 'hr', name: 'Croatian' },
    { code: 'cs', name: 'Czech' },
    { code: 'da', name: 'Danish' },
    { code: 'nl', name: 'Dutch' },
    { code: 'en', name: 'English' },
    { code: 'eo', name: 'Esperanto' },
    { code: 'et', name: 'Estonian' },
    { code: 'tl', name: 'Filipino' },
    { code: 'fi', name: 'Finnish' },
    { code: 'fr', name: 'French' },
    { code: 'fy', name: 'Frisian' },
    { code: 'gl', name: 'Galician' },
    { code: 'ka', name: 'Georgian' },
    { code: 'de', name: 'German' },
    { code: 'el', name: 'Greek' },
    { code: 'gu', name: 'Gujarati' },
    { code: 'ht', name: 'Haitian Creole' },
    { code: 'ha', name: 'Hausa' },
    { code: 'haw', name: 'Hawaiian' },
    { code: 'iw', name: 'Hebrew' },
    { code: 'hi', name: 'Hindi' },
    { code: 'hmn', name: 'Hmong' },
    { code: 'hu', name: 'Hungarian' },
    { code: 'is', name: 'Icelandic' },
    { code: 'ig', name: 'Igbo' },
    { code: 'id', name: 'Indonesian' },
    { code: 'ga', name: 'Irish' },
    { code: 'it', name: 'Italian' },
    { code: 'ja', name: 'Japanese' },
    { code: 'jw', name: 'Javanese' },
    { code: 'kn', name: 'Kannada' },
    { code: 'kk', name: 'Kazakh' },
    { code: 'km', name: 'Khmer' },
    { code: 'rw', name: 'Kinyarwanda' },
    { code: 'ko', name: 'Korean' },
    { code: 'ku', name: 'Kurdish (Kurmanji)' },
    { code: 'ky', name: 'Kyrgyz' },
    { code: 'lo', name: 'Lao' },
    { code: 'la', name: 'Latin' },
    { code: 'lv', name: 'Latvian' },
    { code: 'lt', name: 'Lithuanian' },
    { code: 'lb', name: 'Luxembourgish' },
    { code: 'mk', name: 'Macedonian' },
    { code: 'mg', name: 'Malagasy' },
    { code: 'ms', name: 'Malay' },
    { code: 'ml', name: 'Malayalam' },
    { code: 'mt', name: 'Maltese' },
    { code: 'mi', name: 'Maori' },
    { code: 'mr', name: 'Marathi' },
    { code: 'mn', name: 'Mongolian' },
    { code: 'my', name: 'Myanmar (Burmese)' },
    { code: 'ne', name: 'Nepali' },
    { code: 'no', name: 'Norwegian' },
    { code: 'or', name: 'Odia (Oriya)' },
    { code: 'ps', name: 'Pashto' },
    { code: 'fa', name: 'Persian' },
    { code: 'pl', name: 'Polish' },
    { code: 'pt', name: 'Portuguese' },
    { code: 'pa', name: 'Punjabi' },
    { code: 'ro', name: 'Romanian' },
    { code: 'ru', name: 'Russian' },
    { code: 'sm', name: 'Samoan' },
    { code: 'gd', name: 'Scots Gaelic' },
    { code: 'sr', name: 'Serbian' },
    { code: 'st', name: 'Sesotho' },
    { code: 'sn', name: 'Shona' },
    { code: 'sd', name: 'Sindhi' },
    { code: 'si', name: 'Sinhala' },
    { code: 'sk', name: 'Slovak' },
    { code: 'sl', name: 'Slovenian' },
    { code: 'so', name: 'Somali' },
    { code: 'es', name: 'Spanish' },
    { code: 'su', name: 'Sundanese' },
    { code: 'sw', name: 'Swahili' },
    { code: 'sv', name: 'Swedish' },
    { code: 'tg', name: 'Tajik' },
    { code: 'ta', name: 'Tamil' },
    { code: 'te', name: 'Telugu' },
    { code: 'th', name: 'Thai' },
    { code: 'tr', name: 'Turkish' },
    { code: 'uk', name: 'Ukrainian' },
    { code: 'ur', name: 'Urdu' },
    { code: 'ug', name: 'Uyghur' },
    { code: 'uz', name: 'Uzbek' },
    { code: 'vi', name: 'Vietnamese' },
    { code: 'cy', name: 'Welsh' },
    { code: 'xh', name: 'Xhosa' },
    { code: 'yi', name: 'Yiddish' },
    { code: 'yo', name: 'Yoruba' },
    { code: 'zu', name: 'Zulu' }
];

const nextStep = () => {
    if (currentStep.value < steps.length - 1) {
        currentStep.value++;
    }
};

const prevStep = () => {
    if (currentStep.value > 0) {
        currentStep.value--;
    }
};

const goToStep = (step) => {
    currentStep.value = step;
};

const submitForm = () => {
    if (formData.keyword.length > 100) {
        toast.error(
            'Serpplus currently supports up to 100 keywords per query. Please reduce the number of keywords to proceed.',
            {
                position: toast.POSITION.TOP_RIGHT,
                duration: 10000,
            }
        );
        isLoading.value = false;
        return;
    }

    if (userData.value.type == 1 && formData.keyword.length > 20) {
        toast.error(
            'Trial Plan Limit: As a trial user, you can add up to 20 keywords per query. Upgrade your plan to unlock higher limits.',
            {
                position: toast.POSITION.TOP_RIGHT,
                duration: 10000,
            }
        );
        isLoading.value = false;
        return;
    }

    Object.assign(formData, {
        country: formData.country?.location_code ?? null,
        state_city: stateData?.location_code ?? null,
        location_name: stateData?.location_name ?? null,
        option_location: stateData?.location_name ?? null,
        duration: formData.isDailyChecked == true ? 'onceInDay' : formData.duration
    });


    serpService.addProject(formData)
        .then(() => {
            setTimeout(() => {
                EventBus.$emit('loadSerps', true);
                router.push(`/list-serp-project`);
            }, 40000);
            toast.success('Your project is in queue! It will take around 2 to 5 minutes to complete, depending on traffic. Please be patient.', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 40000,
            });

        })
        .catch((error) => {
            let errorMessage = '⚠️ An error occurred while creating the project. Please try again later or contact support.';
            if (error.response && error.response.status === 404) {
                errorMessage = '🚫 Insufficient credits: You need more credits to create this project. Please add credits to continue.';
            } else if (error.response && error.response.status === 403) {
                errorMessage = '🔒 Project limit reached: You’ve reached the maximum number of allowed projects for your plan. Upgrade your plan to add more projects.';
            }
            toast.error(errorMessage, {
                position: toast.POSITION.TOP_RIGHT,
                duration: 6000,
            });
            console.error('Error creating SERP:', error);
        });

};

const addKeyword = () => {
    if (newKeyword.value.trim()) {
        formData.keyword.push(newKeyword.value.trim());
        newKeyword.value = "";
        showKeywordError.value = false;
    } else {
        showKeywordError.value = true;
    }
};

const addCompetitor = () => {
    if (formData.competitor.length >= 3) {
        competitorError.value = true;
        competitorErrorMessage.value = "You can add up to 3 competitors only.";
        return;
    }

    if (!validateCompetitor(newCompetitor.value)) {
        competitorError.value = true;
        competitorErrorMessage.value = "Please enter a valid domain.";
        return;
    }

    formData.competitor.push(newCompetitor.value.trim());
    newCompetitor.value = "";
    competitorError.value = false;
};

const removeKeyword = (index) => {
    formData.keyword.splice(index, 1);
};

const removeCompetitor = (index) => {
    formData.competitor.splice(index, 1);
};


const isSubmitDisabled = computed(() => {
    if (currentStep.value === 0) {
        return projectNameValidationError.value;
    } else if (currentStep.value === 1) {
        return (
            !formData.domainUrl ||
            formData.keyword.length === 0 ||
            domainValidationError.value
        );
    } else if (currentStep.value === 2) {
        return (formData.country === "" || formData.country === null);
    }
    return false;
});

watch(
    () => formData.domainUrl,
    (newValue) => {
        if (newValue === "") {
            domainValidationError.value = false;
        } else {
            validateDomain();
        }
    }
);

watch(
    () => formData.projectName,
    (newValue) => {
        projectNameValidationError.value = !newValue?.trim();
    }
);

onMounted(() => {
    if (formData.projectName == null) {
        projectNameValidationError.value = true;
    }
    if (formData.domainUrl == null) {
        domainValidationError.value = true;
    }
    if (formData.country == null) {
        countryError.value = true;
    }
    countryService.getCountries().then((data) => {
        regionList.value = data.sort((a, b) => a.location_name.localeCompare(b.location_name));
    });
    const data = localStorage.getItem('userData');
    userData.value = JSON.parse(data);

});
</script>

<style>
.competitor-item {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 20px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    color: #495057;
}

.competitor-item .competitor-name {
    margin-right: 5px;
}

.remove-btn {
    background-color: transparent;
    border: none;
    color: #dc3545;
    font-size: 16px;
    cursor: pointer;
    transition: color 0.3s;
}

.remove-btn:hover {
    color: #b52b3a;
}

.disabled-section {
    pointer-events: none;
    opacity: 0.6;
}

.upgrade-message {
    background: linear-gradient(90deg, #68D2F5, #1E90FF);
    color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    animation: pulse 1.5s infinite;
}

.text-highlight {
    color: #FFD700;
    /* Golden for the icon */
    font-size: 22px;
    margin-right: 8px;
}

.highlighted-text {
    color: #ffffff;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
}

.features-list {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 15px;
    flex-wrap: wrap;
}

.feature-card {
    background: #ffffff;
    color: #333333;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.feature-icon {
    font-size: 30px;
    color: #68D2F5;
    margin-bottom: 10px;
}

.feature-card h4 {
    font-size: 16px;
    color: #333333;
    margin-bottom: 8px;
}

.feature-card p {
    font-size: 14px;
    color: #555555;
    line-height: 1.5;
}

.upgrade-button {
    display: inline-block;
    background: #FFD700;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 20px;
    transition: background 0.3s ease, transform 0.3s ease;
}

.upgrade-button:hover {
    background: #FFEC8B;
    transform: scale(1.05);
}

/* Pulse Animation */
@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.03);
    }

    100% {
        transform: scale(1);
    }
}

.locked-feature-container {
    text-align: center;
    background: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 20px auto;
}

.locked-title {
    font-size: 22px;
    color: #ff4d4f;
    font-weight: bold;
}

.locked-text {
    font-size: 16px;
    color: #333;
    margin: 10px 0;
}

.locked-benefits {
    list-style: none;
    padding: 0;
    margin: 15px 0;
}

.locked-benefits li {
    font-size: 15px;
    color: #555;
    padding: 5px 0;
}

.locked-link {
    color: #007bff;
    font-weight: bold;
    text-decoration: none;
}

.locked-link:hover {
    text-decoration: underline;
}

.upgrade-btn-cont {
    margin-top: 15px;
}

.upgrade-link {
    display: inline-block;
    background: #ff4d4f;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background 0.3s ease;
}

.upgrade-link:hover {
    background: #d9363e;
}
</style>