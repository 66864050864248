<template>
   
    <div class="common-inner-body pricing-plan-page-cont">
        <div class="common-inner-cont">
            <div class="common-top-search text-center">
                <h2 class="top-title">Upgrade Your SEO Game Today!</h2>
                <h2 class="top-subtitle">
                    Choose The Perfect Plan For Your Business And Unlock
                    <br>
                    <strong>Powerful Tools To Boost Your Website Rankings</strong>
                </h2>
                <form class="pricing-plan-form-cont">
                    <div class="form-group mb-0">
                        <div class="common-toggle-switch-cont">
                            <div class="form-check form-check-inline toggle-switch">
                                <input class="form-check-input" type="radio" v-model="isMonthly" name="billingOptions"
                                    id="billMonthly2" :value="true">
                                <label class="form-check-label" for="billMonthly2">Monthly</label>
                            </div>
                            <div class="form-check form-check-inline toggle-switch">
                                <input class="form-check-input" type="radio" v-model="isMonthly" name="billingOptions"
                                    id="billAnnually2" :value="false">
                                <label class="form-check-label" for="billAnnually2">Annually</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-40">
                        <div class="choose-plan-checkbox-cont d-inline-block">
                            <div class="form-check form-check-big d-flex justify-content-center align-items-center">
                                <label class="form-check-label" for="exampleCheck1">Choose Your Plan <span
                                        class="text-primary-new">(Recommended)</span></label>
                                <input id="exampleCheck1" class="form-check-input" type="checkbox"
                                    v-model="isRecommended">
                            </div>
                            <div class="form-check form-check-big d-flex justify-content-center align-items-center">
                                <label class="form-check-label" for="exampleCheck1">You Can Upgrade, Downgrade, Or Cancel Your Plan Anytime.</label>
                            </div>
                        </div>
                    </div>
                    <div class="pricing-plan-cont max-w-100 mx-auto">
                        <div class="row pricing-plan-row justify-content-center align-items-end row-gap-30">
                            <div v-if="loading" class="spinner-container">
                                <div class="spinner"></div>
                            </div>
                            <div v-else v-for="(plan, index) in plans" :key="plan.id"
                                class="col-12 col-lg-6 col-xl-4 pricing-plan-col">
                                <!-- <div :class="['pricing-plan-box', { active: isRecommended && index === 1 }, 'text-center']"> -->
                                <div :class="['pricing-plan-box', { active: (isRecommended && index === 1) || (activeSub && activeSub.name === plan.name && activeSub.subscription.is_cancelled !== 1) }, 'text-center']">
                                    <h4 class="plan-title mb-10">{{ plan.name }}</h4>
                                    <h2
                                        class="plan-price d-flex justify-content-center align-items-center col-gap-5 mb-10">
                                        <span class="price d-inline-block">£{{ getPrice(plan) }} </span>
                                        <span class="currency-period">
                                            <b class="currency d-block text-start">euro</b>
                                            <b class="period d-block text-start">/{{billingType}}.</b>
                                        </span>
                                    </h2>
                                    <div class="top-heighlight">
                                        <span class="platform-fee d-inline-block mb-20">0% platform fee</span>
                                    </div>
                                    <div
                                        class="credits d-flex justify-content-center align-items-center col-gap-5 mb-5">
                                        <img class="credits-icon"
                                            src="https://serpplus.pluspromotions.co.uk/images/icon-credits.svg"
                                            alt="Credit Icon" />
                                        <span class="credits-text">{{ getCredits(plan) }} Credits</span>
                                    </div>
                                   
                                    <p class="whats-included mb-20">What's included</p>
                                    <ul class="plan-features d-inline-block ps-0 mb-60">
                                        <li><strong>SERP Checker:</strong> Track and analyze keyword rankings
                                            effortlessly.</li>
                                        <li><strong>SERP Projects:</strong> Manage multiple projects with ease.</li>
                                        <li><strong>Domain Checker:</strong> Evaluate domain authority and performance.
                                        </li>
                                        <li><strong>Backlink Module:</strong> Monitor and improve backlink strategies.
                                        </li>
                                    </ul>

                                   
                                    <div class="bottom-cont">
                                        <div class="admin-box-btn-cont">
                                            <!-- Show details if the plan is canceled -->
                                            <div
                                                v-if="activeSub && activeSub.name === plan.name && activeSub.subscription.is_cancelled === 1">
                                                <!-- Display expiration information -->
                                                <p class="text-danger">
                                                    {{ isPlanExpired(activeSub.subscription.expiry_date)
                                                        ? 'This plan has expired.'
                                                        : `Plan is canceled. Remaining days: ${getRemainingDays(activeSub.subscription.expiry_date)}` }}
                                                </p>
                                                <!-- Show Select Plan Button -->
                                                <!-- <a class="admin-common-btn btn-select" data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                    @click="handleGetStarted(plan, getPrice(plan), plan.id, false)">
                                                    SELECT
                                                </a> -->
                                            </div>

                                            <!-- Show Upgrade/Downgrade Plan Button -->
                                            <!-- <a v-else-if="activeSub && activeSub.name === plan.name && activeSub.subscription.is_cancelled !== 1"
                                                class="admin-common-btn btn-up-down-grade"
                                                data-bs-dismiss="modal" aria-label="Close"
                                                @click="handleGetStarted(plan, getPrice(plan), plan.id, true)">
                                                {{ activeSub.interval == 'month' ? 'Upgrade To Yearly Plan' : 'Downgrade To Monthly Plan' }}
                                            </a> -->
                                            <a v-else-if="activeSub && activeSub.name === plan.name && activeSub.subscription.is_cancelled !== 1"
                                                class="admin-common-btn btn-up-down-grade"
                                                data-bs-dismiss="modal" aria-label="Close"
                                                @click="handleGetStarted(plan, getPrice(plan), plan.id, true)">
                                                {{ activeSub.interval == 'month' ? 'Upgrade' : 'Downgrade' }}
                                            </a>

                                            <p class="text-until-plan-expires my-10" v-if="activeSub && activeSub.name === plan.name && activeSub.subscription.is_cancelled !== 1">You cna Use remaining credits and projects until plan expires.</p>

                                            <!-- Cancel Subscription Button -->
                                            <a v-if="activeSub && activeSub.name === plan.name && activeSub.subscription.is_cancelled !== 1"
                                                class="admin-common-btn btn-cancel"
                                                aria-label="Close" @click="handleCancel()">Cancel Plan</a>

                                            <!-- Select Plan Button for Non-Active Subscriptions -->
                                            <a v-else class="admin-common-btn btn-select" data-bs-dismiss="modal"
                                                aria-label="Close"
                                                @click="handleGetStarted(plan, getPrice(plan), plan.id, false)">
                                                SELECT
                                            </a>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <div id="modal-plan-change" class="common-modal-cont modal fade" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-690 modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">
                                <span class="mod-title">Change Plan</span>
                            </h5>
                            <a class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                <img src="https://serpplus.pluspromotions.co.uk/images/icon-popup-close.svg"
                                    alt="Close">
                            </a>
                        </div>
                        <div class="modal-body">
                            <div class="modal-form-cont change-plan-form-cont mb-4">
                                <h6>Would you like to start the upgrade subscription now? If yes, press 'Now' to
                                    start immediately. If you prefer to upgrade after the current period ends, press
                                    'After'. Otherwise, press
                                    'Cancel'.</h6>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <Button class="admin-common-btn btn-bordered btn btn-after" type="button"
                                @click="upgradeAfter">After</Button>
                            <Button class="admin-common-btn btn btn-primary btn-cancel" type="button"
                                data-bs-dismiss="modal" aria-label="Close">Cancel</Button>
                            <Button class="admin-common-btn btn-bordered btn btn-now" type="button"
                                @click="upgradeNow">Now</Button>
                        </div>
                    </div>
                </div>
            </div>


            <div class="common-top-search more-credit-cont" v-show="userType === 2">
                <h2 class="top-title">Add More Credits Instantly!</h2>
                <h2 class="top-subtitle">
                    Choose the Perfect Pack for Your Needs and Unlock Access to Advanced Tools to
                    <br>
                    <strong>Supercharge Your SEO Efforts.</strong>
                </h2>
                <div class="add-ons-credit-cont mt-60">
                    <div class="row align-items-center">
                        <div class="col-12 col-xl-8 col-xxl-6 text-center">
                            <div class="left-add-ons-cont text-start mx-auto">
                                <h2 class="title-add-ons-credit mb-20">Boost Your Plan with<p>Add Ons Credits</p>
                                </h2>
                                <h4 class="text-add-ons-credit mb-60">Low on credits? Instantly top up to keep tracking
                                    keywords and analyzing data without any interruptions.</h4>
                                <div class="auto-recharge-form-cont">
                                    <div class="form-group mb-20">
                                        <div class="choose-plan-checkbox-cont d-inline-block">
                                            <div class="form-check form-check-big d-flex align-items-center">
                                                <label class="form-check-label" for="exampleCheck1">Auto Recharge <span
                                                        class="text-primary-new">(Recommended)</span></label>
                                                <input id="exampleCheck1" class="form-check-input" type="checkbox"
                                                    checked>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row flex-wrap">
                                        <div class="col-12 col-lg-6">
                                            <div class="form-group">
                                                <select class="form-control form-control-new select-credit"  v-model="selectedAmount"
                                                @change="updateSelectedCredits">
                                                    <option v-for="(option, index) in creditOptions" :key="index" :value="option.value">
                                                    {{ option.label }}
                                                </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-6 col-lg-3" v-if="selectedCredits != null">
                                            <div class="form-group">
                                                <input class="form-control form-control-new input-currency" type="text" v-model="selectedCredits">
                                            </div>
                                        </div>
                                        <div class="col-6 col-lg-3">
                                            <div class="form-group position-relative w-auto">
                                                <img class="img-fluid direction-arrow position-absolute"
                                                    src="https://serpplus.pluspromotions.co.uk/images/arrow-direction.svg"
                                                    alt="Arrow Right">
                                                <input
                                                    class="admin-common-btn admin-common-btn-new-big btn-purchase mobile-w-100 tablet-w-100"
                                                    type="submit" value="Purchase"  @click="buyNow()">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-xl-4 col-xxl-6 text-center">
                            <img class="img-fluid credits-icon"
                                src="https://serpplus.pluspromotions.co.uk/images/gfx.png"
                                alt="Image gfx">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button ref="changePlanButton" data-bs-toggle="modal" data-bs-target="#modal-plan-change"
        style="display: none;"></button>

    <StripeCheckout ref="checkoutRef" mode="subscription" :pk="publishableKey" :line-items="lineItems"
        :customer-email="email" :success-url="successURL" :cancel-url="cancelURL" @loading="v => loading = v" />

    <CopyRight></CopyRight>

</template>

<script>
import axiosIns from '@/axios';
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import Loader from '@/views/Loader.vue';
import { FRONT_APP_URL } from '@/Constants';
import CopyRight from '../copyRight.vue';

export default {
    components: {
        StripeCheckout,
        Loader,
        CopyRight
    },
    data() {
        return {
            creditOptions: [],
            selectedCredits: '',
            selectedAmount: '',
            userType: null,
            plans: [],
            isRecommended: true,
            isMonthly: true,
            publishableKey: 'pk_test_51NdYtiDr3ZwvUpBYMUOjXDbTCDEHwl3rorYKKGOnDV2C2ZnFoddnVzImFcbTGiYe0fYPUwY1AbMDaVGWBEKvdujJ00mjFZPleR',
            lineItems: [],
            loading: false,
            activeSub: {},
            remainingDays: null,
            prepareDataForUpgrade: {},
            successURL: `${FRONT_APP_URL}payment-done`,
            cancelURL: `${FRONT_APP_URL}payment-failed`,
        };
    },
    mounted() {
        this.fetchPlans();
        this.loadUser();
        const userData = JSON.parse(localStorage.getItem('userData'));
        this.email = userData?.email;
    },
    beforeMount() {
        this.loadAmmount();
    },
 
    methods: {
        buyNow() {
            if (!this.selectedAmount) {
                alert('Please select an amount.');
                return;
            }
            this.generatePaymentIntent();
        },
        async updateSelectedCredits(event) {
            const selectedValue = event.target.value;
            const selectedOption = this.creditOptions.find(option => option.value === parseInt(selectedValue));
            this.selectedCredits = selectedOption ?selectedOption.credits : null;
        },
        async generatePaymentIntent() {
            this.loading = true;
            try {
                const response = await axiosIns.post('/get-checkout-url', { credits: this.selectedAmount * 100, title: this.creditsShow });
                const checkoutUrl = response.data.url;
                this.loading = false;
                window.open(checkoutUrl, '_blank');
            } catch (error) {
                console.error('Error generating payment intent:', error);
            }
        },
        async loadAmmount() {
    try {
        const response = await axiosIns.get('/plans/get-amount');
        this.creditOptions = response.data?.data ? response.data.data : []; // Default to empty array if null or undefined
        this.creditOptions.length ? this.creditOptions.sort((a, b) => a.value - b.value) : console.error('No credit options available');
    } catch (error) {
        console.error('Error generating payment intent:', error);
    }
},

        isPlanExpired(expiryDate) {
            const today = new Date();
            const expiry = new Date(expiryDate);
            return expiry < today;
        },
        getRemainingDays(expiryDate) {
            const today = new Date();
            const expiry = new Date(expiryDate);
            const diffTime = expiry - today;
            return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        },
        async loadUser() {
    try {
        const { data } = await axiosIns.get('/user/show');
        const userData = data?.userData ?? null; // Use nullish coalescing to handle null or undefined
        if (userData) {
            localStorage.setItem('userData', JSON.stringify(userData));

          
            this.userType = userData.type ?? 'Guest'; // Default to 'Guest' if not available

            const plan = data?.plan ?? null;
            if (plan && Object.keys(plan).length > 0) {
                this.activeSub = plan;
                const subscription = plan?.subscription ?? null;
                this.isRecommended = false;

                if (subscription && subscription.expiry_date && subscription.purchase_date) {
                    const expiryDate = new Date(subscription.expiry_date);
                    const purchaseDate = new Date(subscription.purchase_date);

                    if (!isNaN(expiryDate) && !isNaN(purchaseDate)) {
                        const diffInMonths = (expiryDate.getFullYear() - purchaseDate.getFullYear()) * 12 +
                            (expiryDate.getMonth() - purchaseDate.getMonth());

                        this.activeSub.interval = diffInMonths > 1 ? 'year' : 'month';
                        this.activeSub.sub_id = subscription.sub_id;
                        this.isMonthly = this.activeSub.interval === 'month';
                    }
                }
            }
        } else {
            console.error('User data is missing');
        }
    } catch (error) {
        console.error('Failed to load user data:', error);
    }
},

        isRouteActive(route) {
            return this.$route.path === route;
        },
        isRouteGroupActive(routes) {
            return routes.some(route => this.$route.path.startsWith(route));
        },

        async fetchPlans() {
    this.loading = true;
    try {
        const data = await axiosIns.get('plans');
        const plans = data?.data?.plans ?? []; // Default to empty array if plans are null or undefined
        if (plans.length) {
            this.plans = plans;
            localStorage.setItem('userData', JSON.stringify(data.data?.userData ?? {}));
        } else {
            console.error('Plans data is missing');
        }
        this.loading = false;
    } catch (error) {
        this.loading = false;
        console.error('Error:', error.message);
    }
},
        getPrice(plan) {
            return this.isMonthly ? plan.monthly_price : plan.yearly_price;
        },
        getCredits(plan) {
            return this.isMonthly ? plan.monthly_credits : plan.yearly_credits;
        },
        async handleCancel() {
            if (!confirm("Are you sure you want to cancel this subscription?")) {
                return;
            }
            this.loading = true
            try {
                const data = await axiosIns.post(`/plans/cancel-subscription/${this.activeSub.sub_id}`);
                localStorage.setItem('userData', JSON.stringify(data.data.userData));
                this.loading = false
                toast.success("Subscription cancelled successfully. Please check your email for further instructions.", {
                    position: toast.POSITION.TOP_RIGHT,
                    duration: 6000
                });
                window.location.reload();
            } catch (error) {
                this.loading = false

                console.error('Error cancelling subscription:', error);
                toast.error("An error occurred while cancelling the subscription. Please try again later.");
            }
        },
        async handleGetStarted(data, price, planId, samePlanChange) {
            const { plans_stripe: plans } = data;
            var filteredPlans = this.isMonthly
                ? plans.filter(plan => plan.interval === 'month')
                : plans.filter(plan => plan.interval === 'year');

            if (samePlanChange) {
                if (this.activeSub.interval == 'month') {
                    filteredPlans = plans.filter(plan => plan.interval === 'year');
                } else {
                    filteredPlans = plans.filter(plan => plan.interval === 'month');
                }

                this.prepareDataForUpgrade = {
                    subscriptionId: this.activeSub.sub_id,
                    priceId: filteredPlans[0].price,
                    planId: planId,
                    prorationBehavior: false,
                    isJob: false,
                    update_on: this.activeSub.expire_date,
                }

                const button = this.$refs.changePlanButton;
                button.click();

                return false
            }

            if (filteredPlans.length > 0) {
                const selectedPlan = filteredPlans[0];

                const currentPrice = parseFloat(this.activeSub.price);
                if (parseFloat(price) < currentPrice) {
                    if (confirm('Are you sure you want to downgrade your plan?')) {
                        this.loading = true
                        try {
                            const data = await axiosIns.post(`plans/upgrade-downgrade-subscription`, {
                                subscriptionId: this.activeSub.sub_id,
                                priceId: selectedPlan.price,
                                planId: planId,
                                prorationBehavior: true,
                                isJob: false,
                                update_on: this.activeSub.expire_date,
                            }, {
                                timeout: 15000
                            });
                            this.loading = false
                            toast.success("Plan upgraded successfully. Your subscription will be updated at the end of the current plan period.", {
                                position: toast.POSITION.TOP_RIGHT,
                                duration: 6000
                            });
                            setTimeout(() => {
                                window.location.href = '/check-serp';
                            }, 6000);
                        } catch (error) {
                            this.loading = false
                            toast.success("Plan downgraded successfully. Your subscription will be updated at the end of the current plan period.", {
                                position: toast.POSITION.TOP_RIGHT,
                                duration: 6000
                            });
                            setTimeout(() => {
                                window.location.href = '/check-serp';
                            }, 6000);
                            console.error(error);
                        }
                    }
                } else if (parseFloat(price) > currentPrice) {

                    this.prepareDataForUpgrade = {
                        subscriptionId: this.activeSub.sub_id,
                        priceId: selectedPlan.price,
                        planId: planId,
                        prorationBehavior: false,
                        isJob: false,
                        update_on: this.activeSub.expire_date,
                    }

                    const button = this.$refs.changePlanButton;
                    button.click();
                }
                else {
                    const confirmation = confirm("You will now be redirected to our payment gateway to complete your transaction?");

                    if (confirmation) {
                        this.lineItems = [{ price: selectedPlan.price, quantity: 1 }];
                        this.$refs.checkoutRef.redirectToCheckout();
                    } else {
                        console.log("Checkout process canceled by user.");
                    }
                }
            } else {
                console.error(this.isMonthly ? 'No monthly plans found.' : 'No yearly plans found.');
            }
            return false;
        },
        async upgradeNow() {
            this.loading = true;
            try {
                await axiosIns.post(`plans/upgrade-downgrade-subscription`, this.prepareDataForUpgrade, {
                    timeout: 15000
                });
                toast.success("Plan upgraded successfully. Changes take effect immediately.", {
                    position: toast.POSITION.TOP_RIGHT,
                    duration: 6000
                });
                setTimeout(() => {
                    window.location.href = '/check-serp';
                }, 6000);
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        async upgradeAfter() {
            this.loading = true;
            this.prepareDataForUpgrade['isJob'] = false;
            try {
                await axiosIns.post(`plans/upgrade-downgrade-subscription`, this.prepareDataForUpgrade, {
                    timeout: 15000
                });
                this.loading = false;

                toast.success("Plan upgrade scheduled successfully. Your subscription will be updated at the end of the current plan period.", {
                    position: toast.POSITION.TOP_RIGHT,
                    duration: 6000
                });
                setTimeout(() => {
                    window.location.href = '/check-serp';
                }, 6000);
            } catch (error) {
                this.loading = false;
            }

        }

    },
    computed: {
        billingType() {
            return this.isMonthly ? 'Monthly' : 'Yearly';
        }
    }
};
</script>

<style scoped>
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px; /* Adjust height if needed */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.2); /* Light gray background border */
  border-top: 4px solid #2E3771; /* Spinner color updated to #2E3771 */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


</style>