<template>
    <main>
        <div class="fullscreen-page-cont">
            <div class="fullscreen-cont">
                <div class="page-cont-box">
                    <img class="payment-msg-banner banner-success img-fluid" src="images/payment-success-img.png"
                        alt="Payment Successful">
                    <h1 class="page-title">Your Payment is Successful</h1>
                    <p class="page-txt">Thank you for your payment! A payment receipt<br> will be sent to your
                        registered email address.</p>
                    <!-- <p class="page-txt">Thank you for your payment. An automated payment receipt will be sent to your registered email.</p> -->
                    <a class="admin-common-btn btn-back-to-home"
                        @click="closePage">Back To Home</a>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

import { FRONT_APP_URL } from '@/Constants';

export default {
    data() {
        return {
            url: FRONT_APP_URL,
        };
    },
    methods: {
    closePage() {
        window.open(`${this.url}`, '_blank'); // Open new page in a new tab
        window.close(); // Close the current window
    }
}

}
</script>
